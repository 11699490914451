export default [{
  id: "847b3a0e-a0db-4179-ba51-9ee601434db8",
  slug: "bocanegra-botella",
  shop: {
    id: "acfa0595-3e11-4afc-a3e4-c59ddafe5ea5",
    slug: "scarlett-beauty",
    user: {
      id: "15e04e05-4446-4a3f-954f-4995ee9cd706",
      email: "Valentine10@gmail.com",
      phone: "(644) 648-8515 x03713",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1203.jpg",
      password: "tCcmXOtawl_2QD3",
      dateOfBirth: "1976-08-28T14:44:08.160Z",
      verified: true,
      name: {
        firstName: "Emelie",
        lastName: "Rogahn"
      }
    },
    email: "Woodrow.Dietrich51@yahoo.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/cycle.png",
    profilePicture: "/assets/images/faces/propic.png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "bocanegra-botella",
  brand: "Modelo",
  price: 200,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/bocanegra/bocanegra.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/bocanegra/bocanegra.webp", "https://corona-atizapan.com/img/categories/beer/bocanegra/bocanegra.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 4,
  unit: "kg"
}, {
  id: "4e2474bb-726b-4489-a20f-6ccde6e4da43",
  slug: "bocanegra-pilsner-botella",
  shop: {
    id: "9eecf921-25ca-4d1b-a1c4-f232371a176c",
    slug: "bocanegra-pilsner-botella",
    user: {
      id: "2cc76cbe-8927-48fe-bef4-81f21a4caac3",
      email: "Kristofer_Labadie@hotmail.com",
      phone: "278-749-4568",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/887.jpg",
      password: "w3_IqNOBqwEQdy2",
      dateOfBirth: "1981-06-19T12:09:17.219Z",
      verified: true,
      name: {
        firstName: "Kayli",
        lastName: "Kunde"
      }
    },
    email: "Margaretta_Gibson@hotmail.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Bocanegra Pilsner Botella",
  brand: "Modelo",
  price: 239,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/bocanegra/bocanegra-pilsner.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/bocanegra/bocanegra-pilsner.webp", "https://corona-atizapan.com/img/categories/beer/bocanegra/bocanegra-pilsner.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 2,
  unit: null
}, {
  id: "267f027d-c356-4ed0-a247-dc804e79f098",
  slug: "budlight-botella",
  shop: {
    id: "acfa0595-3e11-4afc-a3e4-c59ddafe5ea5",
    slug: "budlight-botella",
    user: {
      id: "15e04e05-4446-4a3f-954f-4995ee9cd706",
      email: "Valentine10@gmail.com",
      phone: "(644) 648-8515 x03713",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1203.jpg",
      password: "tCcmXOtawl_2QD3",
      dateOfBirth: "1976-08-28T14:44:08.160Z",
      verified: true,
      name: {
        firstName: "Emelie",
        lastName: "Rogahn"
      }
    },
    email: "Woodrow.Dietrich51@yahoo.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/cycle.png",
    profilePicture: "/assets/images/faces/propic.png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Budlight Botella",
  brand: "Modelo",
  price: 147,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/budlight/budlight.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/budlight/budlight.webp", "https://corona-atizapan.com/img/categories/beer/budlight/budlight.webp"],
  categories: ["especiales"],
  status: null,
  reviews: [],
  rating: 3,
  unit: null
}, {
  id: "44529b0e-6313-46af-a5b0-130d6c983119",
  slug: "bocanegra-laton",
  shop: {
    id: "641df648-9632-467c-af6f-913f974eb801",
    slug: "bocanegra-laton",
    user: {
      id: "bdfc07fa-3ec2-4b63-a221-21cf65d9968e",
      email: "Eileen.Langosh99@yahoo.com",
      phone: "980.409.1017 x6833",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/809.jpg",
      password: "shYo3rkQqRkg9VM",
      dateOfBirth: "1972-03-29T14:31:41.272Z",
      verified: true,
      name: {
        firstName: "Lucinda",
        lastName: "Smith"
      }
    },
    email: "Edna70@hotmail.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Bocanegra Laton",
  brand: "Modelo",
  price: 236,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/bocanegra/bocanegra-pilsner.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/bocanegra/bocanegra-pilsner.webp", "https://corona-atizapan.com/img/categories/beer/bocanegra/bocanegra-pilsner.webp"],
  categories: ["especiales"],
  status: null,
  reviews: [],
  rating: 0,
  unit: null
}, {
  id: "2ca15d13-7004-43d7-9a27-d709f013a079",
  slug: "vicky-chamoy",
  shop: {
    id: "32040dd2-ea90-4bc0-94d5-2291b9b11208",
    slug: "vicky-chamoy",
    user: {
      id: "9648e2d4-a7e4-4af7-9196-bd6aa788954b",
      email: "Jerald.Nicolas@hotmail.com",
      phone: "1-470-587-7278 x1051",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/870.jpg",
      password: "FhzPBHFvzhQEymY",
      dateOfBirth: "1997-07-23T21:35:22.816Z",
      verified: true,
      name: {
        firstName: "Kaia",
        lastName: "Wyman"
      }
    },
    email: "Brown_Mitchell@yahoo.com",
    name: "Keyboard Kiosk",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-5.png",
    profilePicture: "/assets/images/faces/propic(4).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Vicky Chamoy",
  brand: "Modelo",
  price: 216,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/victoria/vicky-chamoy.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/victoria/vicky-chamoy.webp", "https://corona-atizapan.com/img/categories/beer/victoria/vicky-chamoy.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 3,
  unit: null
}, {
  id: "f61fdbd5-c5be-4a37-b71d-5c8b4747ebfe",
  slug: "vicky-chamoy-botella",
  shop: {
    id: "43196d35-a8d0-4f7d-9c05-b10dc4f145af",
    slug: "vicky-chamoy-botella",
    user: {
      id: "bdfc07fa-3ec2-4b63-a221-21cf65d9968e",
      email: "Eileen.Langosh99@yahoo.com",
      phone: "980.409.1017 x6833",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/809.jpg",
      password: "shYo3rkQqRkg9VM",
      dateOfBirth: "1972-03-29T14:31:41.272Z",
      verified: true,
      name: {
        firstName: "Lucinda",
        lastName: "Smith"
      }
    },
    email: "Sonia69@yahoo.com",
    name: "Constant Shoppers",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-4.png",
    profilePicture: "/assets/images/faces/propic(3).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Vicky Chamoy Botella",
  brand: "Modelo",
  price: 63,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/victoria/victoria.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/victoria/victoria.webp", "https://corona-atizapan.com/img/categories/beer/victoria/victoria.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 3,
  unit: null
}, {
  id: "60df04f9-2761-400c-b326-a73e5a679c98",
  slug: "vicky-chamoy-laton-355",
  shop: {
    id: "9b2e19f3-f3e0-46a3-9529-2aaa504a35b1",
    slug: "vicky-chamoy-laton-355",
    user: {
      id: "d015dc20-2c0b-4fc8-82f9-0d41aa782894",
      email: "Paris_Heller61@yahoo.com",
      phone: "1-664-586-1906 x2392",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/139.jpg",
      password: "RD9XWNQDkmvBnh_",
      dateOfBirth: "1961-04-27T14:58:24.959Z",
      verified: true,
      name: {
        firstName: "Jacey",
        lastName: "Mitchell"
      }
    },
    email: "Timmothy79@hotmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Vicky Chamoy Laton 355",
  brand: "Modelo",
  price: 41,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/victoria/victoria-355.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/victoria/victoria-355.webp", "https://corona-atizapan.com/img/categories/beer/victoria/victoria-355.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 3,
  unit: null
}, {
  id: "ce28ff63-314f-43a3-93b9-ac3411b00987",
  slug: "vicky-chamoy-laton-710",
  shop: {
    id: "32040dd2-ea90-4bc0-94d5-2291b9b11208",
    slug: "vicky-chamoy-laton-710",
    user: {
      id: "9648e2d4-a7e4-4af7-9196-bd6aa788954b",
      email: "Jerald.Nicolas@hotmail.com",
      phone: "1-470-587-7278 x1051",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/870.jpg",
      password: "FhzPBHFvzhQEymY",
      dateOfBirth: "1997-07-23T21:35:22.816Z",
      verified: true,
      name: {
        firstName: "Kaia",
        lastName: "Wyman"
      }
    },
    email: "Brown_Mitchell@yahoo.com",
    name: "Keyboard Kiosk",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-5.png",
    profilePicture: "/assets/images/faces/propic(4).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Vicky Chamoy Laton 710",
  brand: "Modelo",
  price: 222,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/victoria/victoria-710.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/victoria/victoria-710.webp", "https://corona-atizapan.com/img/categories/beer/victoria/victoria-710.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 0,
  unit: null
}, {
  id: "1acb11b4-6d69-49b8-a703-3f21916d68fa",
  slug: "victoria-chingones-laton-355",
  shop: {
    id: "9eecf921-25ca-4d1b-a1c4-f232371a176c",
    slug: "victoria-chingones-laton-355",
    user: {
      id: "2cc76cbe-8927-48fe-bef4-81f21a4caac3",
      email: "Kristofer_Labadie@hotmail.com",
      phone: "278-749-4568",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/887.jpg",
      password: "w3_IqNOBqwEQdy2",
      dateOfBirth: "1981-06-19T12:09:17.219Z",
      verified: true,
      name: {
        firstName: "Kayli",
        lastName: "Kunde"
      }
    },
    email: "Margaretta_Gibson@hotmail.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Victoria Chingones Laton 355",
  brand: "Modelo",
  price: 126,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/victoria/victoria-chingones-355.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/victoria/victoria-chingones-355.webp", "https://corona-atizapan.com/img/categories/beer/victoria/victoria-chingones-355.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 0,
  unit: null
}, {
  id: "b532e88b-5d91-4939-adfb-2b753758c5a9",
  slug: "victoria-chingones-laton-473",
  shop: {
    id: "3d1727f9-f0fd-463b-87fc-81c4df47e992",
    slug: "victoria-chingones-laton-473",
    user: {
      id: "387d1ffa-1d77-4369-bc4c-8f92a3ed5410",
      email: "Elroy_Kuphal65@yahoo.com",
      phone: "623.810.9356",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/754.jpg",
      password: "ai2OLRvDniIGMkR",
      dateOfBirth: "2000-07-08T03:36:38.091Z",
      verified: true,
      name: {
        firstName: "Dean",
        lastName: "Schmidt"
      }
    },
    email: "Dax.Herzog@yahoo.com",
    name: "Scroll Through",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner.png",
    profilePicture: "/assets/images/faces/propic(1).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Victoria Chingones Laton 473",
  brand: "Modelo",
  price: 105,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/victoria/victoria-chingones-473.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/victoria/victoria-chingones-473.webp", "https://corona-atizapan.com/img/categories/beer/victoria/victoria-chingones-473.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 4,
  unit: null
}, {
  id: "ee575fbc-130e-438e-84db-e4bb387026d3",
  slug: "victoria-chingones-mega-botella",
  shop: {
    id: "98a35da7-4c3f-451b-a3eb-5e93a87f630a",
    slug: "victoria-chingones-mega-botella",
    user: {
      id: "387d1ffa-1d77-4369-bc4c-8f92a3ed5410",
      email: "Elroy_Kuphal65@yahoo.com",
      phone: "623.810.9356",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/754.jpg",
      password: "ai2OLRvDniIGMkR",
      dateOfBirth: "2000-07-08T03:36:38.091Z",
      verified: true,
      name: {
        firstName: "Dean",
        lastName: "Schmidt"
      }
    },
    email: "Jorge_Klein@yahoo.com",
    name: "Coveted Clicks",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-3.png",
    profilePicture: "/assets/images/faces/propic(2).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Victoria Chingones Mega Botella",
  brand: "Modelo",
  price: 198,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/victoria/victoria-chingones-mega.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/victoria/victoria-chingones-mega.webp", "https://corona-atizapan.com/img/categories/beer/victoria/victoria-chingones-mega.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 4,
  unit: null
}, {
  id: "364b8492-eea9-44bb-a17c-3a778f7d591f",
  slug: "victoria-cuartito-botella",
  shop: {
    id: "9eecf921-25ca-4d1b-a1c4-f232371a176c",
    slug: "victoria-cuartito-botella",
    user: {
      id: "2cc76cbe-8927-48fe-bef4-81f21a4caac3",
      email: "Kristofer_Labadie@hotmail.com",
      phone: "278-749-4568",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/887.jpg",
      password: "w3_IqNOBqwEQdy2",
      dateOfBirth: "1981-06-19T12:09:17.219Z",
      verified: true,
      name: {
        firstName: "Kayli",
        lastName: "Kunde"
      }
    },
    email: "Margaretta_Gibson@hotmail.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Victoria Cuartito Botella",
  brand: "Modelo",
  price: 137,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/victoria/victoria-cuartito.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/victoria/victoria-cuartito.webp", "https://corona-atizapan.com/img/categories/beer/victoria/victoria-cuartito.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 3,
  unit: null
}, {
  id: "237be68e-d0fe-419a-bc20-5b5299313740",
  slug: "victoria-familiar-botella",
  shop: {
    id: "9eecf921-25ca-4d1b-a1c4-f232371a176c",
    slug: "victoria-familiar-botella",
    user: {
      id: "2cc76cbe-8927-48fe-bef4-81f21a4caac3",
      email: "Kristofer_Labadie@hotmail.com",
      phone: "278-749-4568",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/887.jpg",
      password: "w3_IqNOBqwEQdy2",
      dateOfBirth: "1981-06-19T12:09:17.219Z",
      verified: true,
      name: {
        firstName: "Kayli",
        lastName: "Kunde"
      }
    },
    email: "Margaretta_Gibson@hotmail.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Victoria Familiar Botella",
  brand: "Modelo",
  price: 84,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/victoria/victoria-familiar.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/victoria/victoria-familiar.webp", "https://corona-atizapan.com/img/categories/beer/victoria/victoria-familiar.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 1,
  unit: null
}, {
  id: "623cf2da-2c28-4597-80b2-15818432c77f",
  slug: "victoria-vickychelada-laton",
  shop: {
    id: "9b2e19f3-f3e0-46a3-9529-2aaa504a35b1",
    slug: "victoria-vickychelada-laton",
    user: {
      id: "d015dc20-2c0b-4fc8-82f9-0d41aa782894",
      email: "Paris_Heller61@yahoo.com",
      phone: "1-664-586-1906 x2392",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/139.jpg",
      password: "RD9XWNQDkmvBnh_",
      dateOfBirth: "1961-04-27T14:58:24.959Z",
      verified: true,
      name: {
        firstName: "Jacey",
        lastName: "Mitchell"
      }
    },
    email: "Timmothy79@hotmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Victoria Vickychelada Laton",
  brand: "Modelo",
  price: 184,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/victoria/victoria-vickychelada.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/victoria/victoria-vickychelada.webp", "https://corona-atizapan.com/img/categories/beer/victoria/victoria-vickychelada.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 0,
  unit: null
}, {
  id: "1dac55f6-6672-4237-a3a0-4ab208fbf480",
  slug: "victoria-vickychelada-laton",
  shop: {
    id: "9b2e19f3-f3e0-46a3-9529-2aaa504a35b1",
    slug: "victoria-vickychelada-laton",
    user: {
      id: "d015dc20-2c0b-4fc8-82f9-0d41aa782894",
      email: "Paris_Heller61@yahoo.com",
      phone: "1-664-586-1906 x2392",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/139.jpg",
      password: "RD9XWNQDkmvBnh_",
      dateOfBirth: "1961-04-27T14:58:24.959Z",
      verified: true,
      name: {
        firstName: "Jacey",
        lastName: "Mitchell"
      }
    },
    email: "Timmothy79@hotmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Victoria Vickychelada Laton",
  brand: "Modelo",
  price: 231,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/victoria/victoria-vickychelada.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/victoria/victoria-vickychelada.webp", "https://corona-atizapan.com/img/categories/beer/victoria/victoria-vickychelada.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 2,
  unit: null
}, {
  id: "c12319a5-9b19-470a-ad0e-dedb555836f8",
  slug: "corona-barrilito-botella",
  shop: {
    id: "98a35da7-4c3f-451b-a3eb-5e93a87f630a",
    slug: "corona-barrilito-botella",
    user: {
      id: "387d1ffa-1d77-4369-bc4c-8f92a3ed5410",
      email: "Elroy_Kuphal65@yahoo.com",
      phone: "623.810.9356",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/754.jpg",
      password: "ai2OLRvDniIGMkR",
      dateOfBirth: "2000-07-08T03:36:38.091Z",
      verified: true,
      name: {
        firstName: "Dean",
        lastName: "Schmidt"
      }
    },
    email: "Jorge_Klein@yahoo.com",
    name: "Coveted Clicks",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-3.png",
    profilePicture: "/assets/images/faces/propic(2).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Corona Barrilito Botella",
  brand: "Modelo",
  price: 75,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/corona/corona-barrilito.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/corona/corona-barrilito.webp", "https://corona-atizapan.com/img/categories/beer/corona/corona-barrilito.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 0,
  unit: null
}, {
  id: "1bb4b6c5-34ea-4019-9d83-3c3d2920672a",
  slug: "corona-barrilon-botella",
  shop: {
    id: "43196d35-a8d0-4f7d-9c05-b10dc4f145af",
    slug: "corona-barrilon-botella",
    user: {
      id: "bdfc07fa-3ec2-4b63-a221-21cf65d9968e",
      email: "Eileen.Langosh99@yahoo.com",
      phone: "980.409.1017 x6833",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/809.jpg",
      password: "shYo3rkQqRkg9VM",
      dateOfBirth: "1972-03-29T14:31:41.272Z",
      verified: true,
      name: {
        firstName: "Lucinda",
        lastName: "Smith"
      }
    },
    email: "Sonia69@yahoo.com",
    name: "Constant Shoppers",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-4.png",
    profilePicture: "/assets/images/faces/propic(3).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Corona Barrilon Botella",
  brand: "Modelo",
  price: 10,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/corona/corona-barrilon.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/corona/corona-barrilon.webp", "https://corona-atizapan.com/img/categories/beer/corona/corona-barrilon.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 3,
  unit: null
}, {
  id: "6bc04c09-1792-4f6d-90b0-e9a47c2f99b1",
  slug: "corona-extra-botella",
  shop: {
    id: "acfa0595-3e11-4afc-a3e4-c59ddafe5ea5",
    slug: "corona-extra-botella",
    user: {
      id: "15e04e05-4446-4a3f-954f-4995ee9cd706",
      email: "Valentine10@gmail.com",
      phone: "(644) 648-8515 x03713",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1203.jpg",
      password: "tCcmXOtawl_2QD3",
      dateOfBirth: "1976-08-28T14:44:08.160Z",
      verified: true,
      name: {
        firstName: "Emelie",
        lastName: "Rogahn"
      }
    },
    email: "Woodrow.Dietrich51@yahoo.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/cycle.png",
    profilePicture: "/assets/images/faces/propic.png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Corona Extra Botella",
  brand: "Modelo",
  price: 16,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/corona/corona-extra.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/corona/corona-extra.webp", "https://corona-atizapan.com/img/categories/beer/corona/corona-extra.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 1,
  unit: null
}, {
  id: "5ce9d7b1-f491-4f55-a078-2598cb33ef12",
  slug: "corona-extra-laton-355",
  shop: {
    id: "3d1727f9-f0fd-463b-87fc-81c4df47e992",
    slug: "corona-extra-laton-355",
    user: {
      id: "387d1ffa-1d77-4369-bc4c-8f92a3ed5410",
      email: "Elroy_Kuphal65@yahoo.com",
      phone: "623.810.9356",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/754.jpg",
      password: "ai2OLRvDniIGMkR",
      dateOfBirth: "2000-07-08T03:36:38.091Z",
      verified: true,
      name: {
        firstName: "Dean",
        lastName: "Schmidt"
      }
    },
    email: "Dax.Herzog@yahoo.com",
    name: "Scroll Through",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner.png",
    profilePicture: "/assets/images/faces/propic(1).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Corona Extra Laton 355",
  brand: "Modelo",
  price: 127,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/corona/corona-extra-355.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/corona/corona-extra-355.webp", "https://corona-atizapan.com/img/categories/beer/corona/corona-extra-355.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 2,
  unit: null
}, {
  id: "25fff176-7b4b-4f98-bc10-e0994f22ac44",
  slug: "corona-extra-laton-473",
  shop: {
    id: "6976c27f-e038-4df2-b8a4-1559a73f4b82",
    slug: "corona-extra-laton-473",
    user: {
      id: "422bd71d-b630-4590-92b4-8eee48da1082",
      email: "Josie61@hotmail.com",
      phone: "872-752-8024 x19584",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/928.jpg",
      password: "vtJw__7B6wKo2JF",
      dateOfBirth: "1943-03-03T06:16:37.790Z",
      verified: true,
      name: {
        firstName: "Heber",
        lastName: "White"
      }
    },
    email: "Seth22@yahoo.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-9.png",
    profilePicture: "/assets/images/faces/propic(8).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Corona Extra Laton 473",
  brand: "Modelo",
  price: 5,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/corona/corona-extra-473.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/corona/corona-extra-473.webp", "https://corona-atizapan.com/img/categories/beer/corona/corona-extra-473.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 4,
  unit: null
}, {
  id: "48bd6cf6-be7b-41ba-80d5-ed882694443f",
  slug: "corona-extra-ambar-botella",
  shop: {
    id: "641df648-9632-467c-af6f-913f974eb801",
    slug: "corona-extra-ambar-botella",
    user: {
      id: "bdfc07fa-3ec2-4b63-a221-21cf65d9968e",
      email: "Eileen.Langosh99@yahoo.com",
      phone: "980.409.1017 x6833",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/809.jpg",
      password: "shYo3rkQqRkg9VM",
      dateOfBirth: "1972-03-29T14:31:41.272Z",
      verified: true,
      name: {
        firstName: "Lucinda",
        lastName: "Smith"
      }
    },
    email: "Edna70@hotmail.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Corona Extra Ambar Botella",
  brand: "Modelo",
  price: 157,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/corona/corona-extra-ambar.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/corona/corona-extra-ambar.webp", "https://corona-atizapan.com/img/categories/beer/corona/corona-extra-ambar.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 1,
  unit: null
}, {
  id: "95e95f4c-956e-4fd4-8995-31cf9371745d",
  slug: "corona-familiar-botella",
  shop: {
    id: "acfa0595-3e11-4afc-a3e4-c59ddafe5ea5",
    slug: "corona-familiar-botella",
    user: {
      id: "15e04e05-4446-4a3f-954f-4995ee9cd706",
      email: "Valentine10@gmail.com",
      phone: "(644) 648-8515 x03713",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1203.jpg",
      password: "tCcmXOtawl_2QD3",
      dateOfBirth: "1976-08-28T14:44:08.160Z",
      verified: true,
      name: {
        firstName: "Emelie",
        lastName: "Rogahn"
      }
    },
    email: "Woodrow.Dietrich51@yahoo.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/cycle.png",
    profilePicture: "/assets/images/faces/propic.png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Corona Familiar Botella",
  brand: "Modelo",
  price: 242,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/corona/corona-familiar.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/corona/corona-familiar.webp", "https://corona-atizapan.com/img/categories/beer/corona/corona-familiar.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 3,
  unit: null
}, {
  id: "6295c8ea-8944-40c8-9d53-cc46ba6c158e",
  slug: "corona-mega-botella",
  shop: {
    id: "9eecf921-25ca-4d1b-a1c4-f232371a176c",
    slug: "corona-mega-botella",
    user: {
      id: "2cc76cbe-8927-48fe-bef4-81f21a4caac3",
      email: "Kristofer_Labadie@hotmail.com",
      phone: "278-749-4568",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/887.jpg",
      password: "w3_IqNOBqwEQdy2",
      dateOfBirth: "1981-06-19T12:09:17.219Z",
      verified: true,
      name: {
        firstName: "Kayli",
        lastName: "Kunde"
      }
    },
    email: "Margaretta_Gibson@hotmail.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Corona Mega Botella",
  brand: "Modelo",
  price: 139,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/corona/corona-mega.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/corona/corona-mega.webp", "https://corona-atizapan.com/img/categories/beer/corona/corona-mega.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 1,
  unit: null
}, {
  id: "9565c735-d17a-44ce-adf7-db6e23955269",
  slug: "corona-cero-laton-355",
  shop: {
    id: "641df648-9632-467c-af6f-913f974eb801",
    slug: "corona-cero-laton-355",
    user: {
      id: "bdfc07fa-3ec2-4b63-a221-21cf65d9968e",
      email: "Eileen.Langosh99@yahoo.com",
      phone: "980.409.1017 x6833",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/809.jpg",
      password: "shYo3rkQqRkg9VM",
      dateOfBirth: "1972-03-29T14:31:41.272Z",
      verified: true,
      name: {
        firstName: "Lucinda",
        lastName: "Smith"
      }
    },
    email: "Edna70@hotmail.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Corona Cero Laton 355",
  brand: "Modelo",
  price: 134,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/corona-cero/corona-cero.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/corona-cero/corona-cero.webp", "https://corona-atizapan.com/img/categories/beer/corona-cero/corona-cero.webp"],
  categories: ["cervezas-sin-alcohol"],
  status: null,
  reviews: [],
  rating: 3,
  unit: null
}, {
  id: "b2d6f44e-128e-4ad0-81c9-a4d78317b83b",
  slug: "corona-cero-laton-355-larga",
  shop: {
    id: "9b2e19f3-f3e0-46a3-9529-2aaa504a35b1",
    slug: "corona-cero-laton-355-larga",
    user: {
      id: "d015dc20-2c0b-4fc8-82f9-0d41aa782894",
      email: "Paris_Heller61@yahoo.com",
      phone: "1-664-586-1906 x2392",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/139.jpg",
      password: "RD9XWNQDkmvBnh_",
      dateOfBirth: "1961-04-27T14:58:24.959Z",
      verified: true,
      name: {
        firstName: "Jacey",
        lastName: "Mitchell"
      }
    },
    email: "Timmothy79@hotmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Corona Cero Laton 355 Larga",
  brand: "Modelo",
  price: 144,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/corona-cero/corona-cero1.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/corona-cero/corona-cero1.webp", "https://corona-atizapan.com/img/categories/beer/corona-cero/corona-cero1.webp"],
  categories: ["cervezas-sin-alcohol"],
  status: null,
  reviews: [],
  rating: 3,
  unit: null
}, {
  id: "d2cc8ad5-efe4-4a65-9a0e-5bb04fa9162f",
  slug: "corona-cero-laton-355-dark",
  shop: {
    id: "43196d35-a8d0-4f7d-9c05-b10dc4f145af",
    slug: "corona-cero-laton-355-dark",
    user: {
      id: "bdfc07fa-3ec2-4b63-a221-21cf65d9968e",
      email: "Eileen.Langosh99@yahoo.com",
      phone: "980.409.1017 x6833",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/809.jpg",
      password: "shYo3rkQqRkg9VM",
      dateOfBirth: "1972-03-29T14:31:41.272Z",
      verified: true,
      name: {
        firstName: "Lucinda",
        lastName: "Smith"
      }
    },
    email: "Sonia69@yahoo.com",
    name: "Constant Shoppers",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-4.png",
    profilePicture: "/assets/images/faces/propic(3).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Corona Cero Laton 355 dark",
  brand: "Modelo",
  price: 247,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/corona-cero/corona-cero2.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/corona-cero/corona-cero2.webp", "https://corona-atizapan.com/img/categories/beer/corona-cero/corona-cero2.webp"],
  categories: ["cervezas-sin-alcohol"],
  status: null,
  reviews: [],
  rating: 4,
  unit: null
}, {
  id: "81f36e0b-4a34-4c5b-9095-2a814e590d1a",
  slug: "corona-ligera-1.8-laton-355",
  shop: {
    id: "641df648-9632-467c-af6f-913f974eb801",
    slug: "corona-ligera-1.8-laton-355",
    user: {
      id: "bdfc07fa-3ec2-4b63-a221-21cf65d9968e",
      email: "Eileen.Langosh99@yahoo.com",
      phone: "980.409.1017 x6833",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/809.jpg",
      password: "shYo3rkQqRkg9VM",
      dateOfBirth: "1972-03-29T14:31:41.272Z",
      verified: true,
      name: {
        firstName: "Lucinda",
        lastName: "Smith"
      }
    },
    email: "Edna70@hotmail.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Corona Ligera 1.8 Laton 355",
  brand: "Modelo",
  price: 127,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/corona-ligera-1.8/corona-ligera.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/corona-ligera-1.8/corona-ligera.webp", "https://corona-atizapan.com/img/categories/beer/corona-ligera-1.8/corona-ligera.webp"],
  categories: ["cervezas-sin-alcohol"],
  status: null,
  reviews: [],
  rating: 4,
  unit: null
}, {
  id: "dfa46b3f-c514-4304-8d34-b8ed8728295b",
  slug: "corona-ligera-1.8-laton-355-larga",
  shop: {
    id: "9b2e19f3-f3e0-46a3-9529-2aaa504a35b1",
    slug: "corona-ligera-1.8-laton-355-larga",
    user: {
      id: "d015dc20-2c0b-4fc8-82f9-0d41aa782894",
      email: "Paris_Heller61@yahoo.com",
      phone: "1-664-586-1906 x2392",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/139.jpg",
      password: "RD9XWNQDkmvBnh_",
      dateOfBirth: "1961-04-27T14:58:24.959Z",
      verified: true,
      name: {
        firstName: "Jacey",
        lastName: "Mitchell"
      }
    },
    email: "Timmothy79@hotmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Corona Ligera 1.8 Laton 355 Larga",
  brand: "Modelo",
  price: 129,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/corona-ligera-1.8/corona-ligera1.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/corona-ligera-1.8/corona-ligera1.webp", "https://corona-atizapan.com/img/categories/beer/corona-ligera-1.8/corona-ligera1.webp"],
  categories: ["cervezas-sin-alcohol"],
  status: null,
  reviews: [],
  rating: 0,
  unit: null
}, {
  id: "ad8747d6-c99f-4978-95a0-6b4b614d3da7",
  slug: "corona-ligera-1.8-botella",
  shop: {
    id: "9b2e19f3-f3e0-46a3-9529-2aaa504a35b1",
    slug: "corona-ligera-1.8-botella",
    user: {
      id: "d015dc20-2c0b-4fc8-82f9-0d41aa782894",
      email: "Paris_Heller61@yahoo.com",
      phone: "1-664-586-1906 x2392",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/139.jpg",
      password: "RD9XWNQDkmvBnh_",
      dateOfBirth: "1961-04-27T14:58:24.959Z",
      verified: true,
      name: {
        firstName: "Jacey",
        lastName: "Mitchell"
      }
    },
    email: "Timmothy79@hotmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Corona Ligera 1.8 Botella",
  brand: "Modelo",
  price: 8,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/corona-ligera-1.8/corona-ligera-1.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/corona-ligera-1.8/corona-ligera-1.webp", "https://corona-atizapan.com/img/categories/beer/corona-ligera-1.8/corona-ligera-1.webp"],
  categories: ["cervezas-sin-alcohol"],
  status: null,
  reviews: [],
  rating: 3,
  unit: null
}, {
  id: "e9044fe8-4bc3-4d78-8bee-afe265d075b8",
  slug: "corona-light-botella-330",
  shop: {
    id: "43196d35-a8d0-4f7d-9c05-b10dc4f145af",
    slug: "corona-light-botella-330",
    user: {
      id: "bdfc07fa-3ec2-4b63-a221-21cf65d9968e",
      email: "Eileen.Langosh99@yahoo.com",
      phone: "980.409.1017 x6833",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/809.jpg",
      password: "shYo3rkQqRkg9VM",
      dateOfBirth: "1972-03-29T14:31:41.272Z",
      verified: true,
      name: {
        firstName: "Lucinda",
        lastName: "Smith"
      }
    },
    email: "Sonia69@yahoo.com",
    name: "Constant Shoppers",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-4.png",
    profilePicture: "/assets/images/faces/propic(3).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Corona Light Botella 330",
  brand: "Modelo",
  price: 175,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/corona/corona-light.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/corona/corona-light.webp", "https://corona-atizapan.com/img/categories/beer/corona/corona-light.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 3,
  unit: null
}, {
  id: "facde888-c9c6-4ed0-b6c2-c9b65fa4687b",
  slug: "corona-light-botella-355",
  shop: {
    id: "641df648-9632-467c-af6f-913f974eb801",
    slug: "corona-light-botella-355",
    user: {
      id: "bdfc07fa-3ec2-4b63-a221-21cf65d9968e",
      email: "Eileen.Langosh99@yahoo.com",
      phone: "980.409.1017 x6833",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/809.jpg",
      password: "shYo3rkQqRkg9VM",
      dateOfBirth: "1972-03-29T14:31:41.272Z",
      verified: true,
      name: {
        firstName: "Lucinda",
        lastName: "Smith"
      }
    },
    email: "Edna70@hotmail.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Corona Light Botella 355",
  brand: "Modelo",
  price: 114,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/corona-light/corona-light.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/corona-light/corona-light.webp", "https://corona-atizapan.com/img/categories/beer/corona-light/corona-light.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 1,
  unit: null
}, {
  id: "072403fc-5224-4b19-bf51-f6759a3bcf81",
  slug: "corona-light-laton-355",
  shop: {
    id: "641df648-9632-467c-af6f-913f974eb801",
    slug: "corona-light-laton-355",
    user: {
      id: "bdfc07fa-3ec2-4b63-a221-21cf65d9968e",
      email: "Eileen.Langosh99@yahoo.com",
      phone: "980.409.1017 x6833",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/809.jpg",
      password: "shYo3rkQqRkg9VM",
      dateOfBirth: "1972-03-29T14:31:41.272Z",
      verified: true,
      name: {
        firstName: "Lucinda",
        lastName: "Smith"
      }
    },
    email: "Edna70@hotmail.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Corona Light Laton 355",
  brand: "Modelo",
  price: 249,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/corona-light/corona-light-355.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/corona-light/corona-light-355.webp", "https://corona-atizapan.com/img/categories/beer/corona-light/corona-light-355.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 3,
  unit: null
}, {
  id: "0b1e2d03-0b3a-4971-99f2-c1422c33ff88",
  slug: "corona-light-laton-473",
  shop: {
    id: "3d1727f9-f0fd-463b-87fc-81c4df47e992",
    slug: "corona-light-laton-473",
    user: {
      id: "387d1ffa-1d77-4369-bc4c-8f92a3ed5410",
      email: "Elroy_Kuphal65@yahoo.com",
      phone: "623.810.9356",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/754.jpg",
      password: "ai2OLRvDniIGMkR",
      dateOfBirth: "2000-07-08T03:36:38.091Z",
      verified: true,
      name: {
        firstName: "Dean",
        lastName: "Schmidt"
      }
    },
    email: "Dax.Herzog@yahoo.com",
    name: "Scroll Through",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner.png",
    profilePicture: "/assets/images/faces/propic(1).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Corona Light Laton 473",
  brand: "Modelo",
  price: 139,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/corona-light/corona-light-473.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/corona-light/corona-light-473.webp", "https://corona-atizapan.com/img/categories/beer/corona-light/corona-light-473.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 3,
  unit: null
}, {
  id: "46e39bf2-c44b-4a0e-bdee-bc5bb58da195",
  slug: "corona-light-mega",
  shop: {
    id: "32040dd2-ea90-4bc0-94d5-2291b9b11208",
    slug: "corona-light-mega",
    user: {
      id: "9648e2d4-a7e4-4af7-9196-bd6aa788954b",
      email: "Jerald.Nicolas@hotmail.com",
      phone: "1-470-587-7278 x1051",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/870.jpg",
      password: "FhzPBHFvzhQEymY",
      dateOfBirth: "1997-07-23T21:35:22.816Z",
      verified: true,
      name: {
        firstName: "Kaia",
        lastName: "Wyman"
      }
    },
    email: "Brown_Mitchell@yahoo.com",
    name: "Keyboard Kiosk",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-5.png",
    profilePicture: "/assets/images/faces/propic(4).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Corona Light Mega",
  brand: "Modelo",
  price: 161,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/corona-light/corona-light-mega.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/corona-light/corona-light-mega.webp", "https://corona-atizapan.com/img/categories/beer/corona-light/corona-light-mega.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 1,
  unit: null
}, {
  id: "3cdb0de4-5d77-47ef-864e-9cbe2f21eecf",
  slug: "modelo-especial-laton-473",
  shop: {
    id: "6976c27f-e038-4df2-b8a4-1559a73f4b82",
    slug: "modelo-especial-laton-473",
    user: {
      id: "422bd71d-b630-4590-92b4-8eee48da1082",
      email: "Josie61@hotmail.com",
      phone: "872-752-8024 x19584",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/928.jpg",
      password: "vtJw__7B6wKo2JF",
      dateOfBirth: "1943-03-03T06:16:37.790Z",
      verified: true,
      name: {
        firstName: "Heber",
        lastName: "White"
      }
    },
    email: "Seth22@yahoo.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-9.png",
    profilePicture: "/assets/images/faces/propic(8).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Modelo Especial Laton 473",
  brand: "Modelo",
  price: 90,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/modelo/corona-especial-473.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/modelo/corona-especial-473.webp", "https://corona-atizapan.com/img/categories/beer/modelo/corona-especial-473.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 2,
  unit: null
}, {
  id: "9bf108e8-2020-4e84-a9a1-155f71443062",
  slug: "modelo-ambar",
  shop: {
    id: "32040dd2-ea90-4bc0-94d5-2291b9b11208",
    slug: "modelo-ambar",
    user: {
      id: "9648e2d4-a7e4-4af7-9196-bd6aa788954b",
      email: "Jerald.Nicolas@hotmail.com",
      phone: "1-470-587-7278 x1051",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/870.jpg",
      password: "FhzPBHFvzhQEymY",
      dateOfBirth: "1997-07-23T21:35:22.816Z",
      verified: true,
      name: {
        firstName: "Kaia",
        lastName: "Wyman"
      }
    },
    email: "Brown_Mitchell@yahoo.com",
    name: "Keyboard Kiosk",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-5.png",
    profilePicture: "/assets/images/faces/propic(4).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Modelo Ambar",
  brand: "Modelo",
  price: 131,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/modelo/modelo-ambar.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/modelo/modelo-ambar.webp", "https://corona-atizapan.com/img/categories/beer/modelo/modelo-ambar.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 1,
  unit: null
}, {
  id: "11a80f7f-bad2-44db-8d59-8079a6cad8fe",
  slug: "modelo-especial",
  shop: {
    id: "9b2e19f3-f3e0-46a3-9529-2aaa504a35b1",
    slug: "modelo-especial",
    user: {
      id: "d015dc20-2c0b-4fc8-82f9-0d41aa782894",
      email: "Paris_Heller61@yahoo.com",
      phone: "1-664-586-1906 x2392",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/139.jpg",
      password: "RD9XWNQDkmvBnh_",
      dateOfBirth: "1961-04-27T14:58:24.959Z",
      verified: true,
      name: {
        firstName: "Jacey",
        lastName: "Mitchell"
      }
    },
    email: "Timmothy79@hotmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Modelo Especial",
  brand: "Modelo",
  price: 229,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/modelo/modelo-especial.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/modelo/modelo-especial.webp", "https://corona-atizapan.com/img/categories/beer/modelo/modelo-especial.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 4,
  unit: null
}, {
  id: "a4279be5-bd65-435e-855c-bcaee53a1a17",
  slug: "modelo-especial-laton-355",
  shop: {
    id: "641df648-9632-467c-af6f-913f974eb801",
    slug: "modelo-especial-laton-355",
    user: {
      id: "bdfc07fa-3ec2-4b63-a221-21cf65d9968e",
      email: "Eileen.Langosh99@yahoo.com",
      phone: "980.409.1017 x6833",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/809.jpg",
      password: "shYo3rkQqRkg9VM",
      dateOfBirth: "1972-03-29T14:31:41.272Z",
      verified: true,
      name: {
        firstName: "Lucinda",
        lastName: "Smith"
      }
    },
    email: "Edna70@hotmail.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Modelo Especial Laton 355",
  brand: "Modelo",
  price: 206,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/modelo/modelo-especial-355.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/modelo/modelo-especial-355.webp", "https://corona-atizapan.com/img/categories/beer/modelo/modelo-especial-355.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 4,
  unit: null
}, {
  id: "35f7d8aa-79b3-40bf-9b9c-fae03376e1cc",
  slug: "modelo-especial-mega",
  shop: {
    id: "43196d35-a8d0-4f7d-9c05-b10dc4f145af",
    slug: "modelo-especial-mega",
    user: {
      id: "bdfc07fa-3ec2-4b63-a221-21cf65d9968e",
      email: "Eileen.Langosh99@yahoo.com",
      phone: "980.409.1017 x6833",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/809.jpg",
      password: "shYo3rkQqRkg9VM",
      dateOfBirth: "1972-03-29T14:31:41.272Z",
      verified: true,
      name: {
        firstName: "Lucinda",
        lastName: "Smith"
      }
    },
    email: "Sonia69@yahoo.com",
    name: "Constant Shoppers",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-4.png",
    profilePicture: "/assets/images/faces/propic(3).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Modelo Especial Mega",
  brand: "Modelo",
  price: 142,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/modelo/modelo-especial-mega.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/modelo/modelo-especial-mega.webp", "https://corona-atizapan.com/img/categories/beer/modelo/modelo-especial-mega.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 3,
  unit: null
}, {
  id: "bd205f3b-aca6-4d3b-92a8-30c94ebb6de0",
  slug: "modelo-especial-noche",
  shop: {
    id: "9b2e19f3-f3e0-46a3-9529-2aaa504a35b1",
    slug: "modelo-especial-noche",
    user: {
      id: "d015dc20-2c0b-4fc8-82f9-0d41aa782894",
      email: "Paris_Heller61@yahoo.com",
      phone: "1-664-586-1906 x2392",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/139.jpg",
      password: "RD9XWNQDkmvBnh_",
      dateOfBirth: "1961-04-27T14:58:24.959Z",
      verified: true,
      name: {
        firstName: "Jacey",
        lastName: "Mitchell"
      }
    },
    email: "Timmothy79@hotmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Modelo Especial Noche",
  brand: "Modelo",
  price: 192,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/modelo/modelo-especial-noche.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/modelo/modelo-especial-noche.webp", "https://corona-atizapan.com/img/categories/beer/modelo/modelo-especial-noche.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 0,
  unit: null
}, {
  id: "1ad70053-f5f8-4862-8891-4db2295706c5",
  slug: "modelo-negra",
  shop: {
    id: "43196d35-a8d0-4f7d-9c05-b10dc4f145af",
    slug: "modelo-negra",
    user: {
      id: "bdfc07fa-3ec2-4b63-a221-21cf65d9968e",
      email: "Eileen.Langosh99@yahoo.com",
      phone: "980.409.1017 x6833",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/809.jpg",
      password: "shYo3rkQqRkg9VM",
      dateOfBirth: "1972-03-29T14:31:41.272Z",
      verified: true,
      name: {
        firstName: "Lucinda",
        lastName: "Smith"
      }
    },
    email: "Sonia69@yahoo.com",
    name: "Constant Shoppers",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-4.png",
    profilePicture: "/assets/images/faces/propic(3).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Modelo Negra",
  brand: "Modelo",
  price: 35,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/modelo/modelo-negra.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/modelo/modelo-negra.webp", "https://corona-atizapan.com/img/categories/beer/modelo/modelo-negra.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 2,
  unit: null
}, {
  id: "0eb94376-6e01-4e59-8e85-c367c690b008",
  slug: "leon-mega",
  shop: {
    id: "9eecf921-25ca-4d1b-a1c4-f232371a176c",
    slug: "leon-mega",
    user: {
      id: "2cc76cbe-8927-48fe-bef4-81f21a4caac3",
      email: "Kristofer_Labadie@hotmail.com",
      phone: "278-749-4568",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/887.jpg",
      password: "w3_IqNOBqwEQdy2",
      dateOfBirth: "1981-06-19T12:09:17.219Z",
      verified: true,
      name: {
        firstName: "Kayli",
        lastName: "Kunde"
      }
    },
    email: "Margaretta_Gibson@hotmail.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Leon Mega",
  brand: "Modelo",
  price: 65,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/regionales/leon-mega.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/regionales/leon-mega.webp", "https://corona-atizapan.com/img/categories/beer/regionales/leon-mega.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 4,
  unit: null
}, {
  id: "41c3fa12-f03c-4bc6-b4d3-ba7a6a9df90e",
  slug: "pacifico-clara-botella",
  shop: {
    id: "641df648-9632-467c-af6f-913f974eb801",
    slug: "pacifico-clara-botella",
    user: {
      id: "bdfc07fa-3ec2-4b63-a221-21cf65d9968e",
      email: "Eileen.Langosh99@yahoo.com",
      phone: "980.409.1017 x6833",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/809.jpg",
      password: "shYo3rkQqRkg9VM",
      dateOfBirth: "1972-03-29T14:31:41.272Z",
      verified: true,
      name: {
        firstName: "Lucinda",
        lastName: "Smith"
      }
    },
    email: "Edna70@hotmail.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Pacifico Clara Botella",
  brand: "Modelo",
  price: 99,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/regionales/pacifico-clara.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/regionales/pacifico-clara.webp", "https://corona-atizapan.com/img/categories/beer/regionales/pacifico-clara.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 4,
  unit: null
}, {
  id: "32e69402-886a-44fa-a0b2-a4665f2da38d",
  slug: "pacifico-clara-laton-355",
  shop: {
    id: "98a35da7-4c3f-451b-a3eb-5e93a87f630a",
    slug: "pacifico-clara-laton-355",
    user: {
      id: "387d1ffa-1d77-4369-bc4c-8f92a3ed5410",
      email: "Elroy_Kuphal65@yahoo.com",
      phone: "623.810.9356",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/754.jpg",
      password: "ai2OLRvDniIGMkR",
      dateOfBirth: "2000-07-08T03:36:38.091Z",
      verified: true,
      name: {
        firstName: "Dean",
        lastName: "Schmidt"
      }
    },
    email: "Jorge_Klein@yahoo.com",
    name: "Coveted Clicks",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-3.png",
    profilePicture: "/assets/images/faces/propic(2).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Pacifico Clara Laton 355",
  brand: "Modelo",
  price: 71,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/regionales/pacifico-clara-355.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/regionales/pacifico-clara-355.webp", "https://corona-atizapan.com/img/categories/beer/regionales/pacifico-clara-355.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 4,
  unit: null
}, {
  id: "9b673089-b3af-41e2-8e7b-532d5287ea5b",
  slug: "pacifico-suave-botella",
  shop: {
    id: "641df648-9632-467c-af6f-913f974eb801",
    slug: "pacifico-suave-botella",
    user: {
      id: "bdfc07fa-3ec2-4b63-a221-21cf65d9968e",
      email: "Eileen.Langosh99@yahoo.com",
      phone: "980.409.1017 x6833",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/809.jpg",
      password: "shYo3rkQqRkg9VM",
      dateOfBirth: "1972-03-29T14:31:41.272Z",
      verified: true,
      name: {
        firstName: "Lucinda",
        lastName: "Smith"
      }
    },
    email: "Edna70@hotmail.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Pacifico suave Botella",
  brand: "Modelo",
  price: 16,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/regionales/pacifico-suave.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/regionales/pacifico-suave.webp", "https://corona-atizapan.com/img/categories/beer/regionales/pacifico-suave.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 1,
  unit: null
}, {
  id: "fbe39dbb-ba95-47e5-b12a-61f3023426bb",
  slug: "pacifico-suave-laton-355",
  shop: {
    id: "3d1727f9-f0fd-463b-87fc-81c4df47e992",
    slug: "pacifico-suave-laton-355",
    user: {
      id: "387d1ffa-1d77-4369-bc4c-8f92a3ed5410",
      email: "Elroy_Kuphal65@yahoo.com",
      phone: "623.810.9356",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/754.jpg",
      password: "ai2OLRvDniIGMkR",
      dateOfBirth: "2000-07-08T03:36:38.091Z",
      verified: true,
      name: {
        firstName: "Dean",
        lastName: "Schmidt"
      }
    },
    email: "Dax.Herzog@yahoo.com",
    name: "Scroll Through",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner.png",
    profilePicture: "/assets/images/faces/propic(1).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Pacifico suave Laton 355",
  brand: "Modelo",
  price: 132,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/regionales/pacifico-suave-355.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/regionales/pacifico-suave-355.webp", "https://corona-atizapan.com/img/categories/beer/regionales/pacifico-suave-355.webp"],
  categories: ["cervezas"],
  status: null,
  reviews: [],
  rating: 3,
  unit: null
}, {
  id: "15ebcf42-daf2-4422-8578-9ad2da7da6c9",
  slug: "cucapa",
  shop: {
    id: "9eecf921-25ca-4d1b-a1c4-f232371a176c",
    slug: "cucapa",
    user: {
      id: "2cc76cbe-8927-48fe-bef4-81f21a4caac3",
      email: "Kristofer_Labadie@hotmail.com",
      phone: "278-749-4568",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/887.jpg",
      password: "w3_IqNOBqwEQdy2",
      dateOfBirth: "1981-06-19T12:09:17.219Z",
      verified: true,
      name: {
        firstName: "Kayli",
        lastName: "Kunde"
      }
    },
    email: "Margaretta_Gibson@hotmail.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Cucapa",
  brand: "Modelo",
  price: 211,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/varios/cucapa.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/varios/cucapa.webp", "https://corona-atizapan.com/img/categories/beer/varios/cucapa.webp"],
  categories: ["especiales"],
  status: null,
  reviews: [],
  rating: 3,
  unit: null
}, {
  id: "77e4a55f-a8bd-408e-a0c4-2a4fe7779b51",
  slug: "cucapa-dorada",
  shop: {
    id: "3d1727f9-f0fd-463b-87fc-81c4df47e992",
    slug: "cucapa-dorada",
    user: {
      id: "387d1ffa-1d77-4369-bc4c-8f92a3ed5410",
      email: "Elroy_Kuphal65@yahoo.com",
      phone: "623.810.9356",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/754.jpg",
      password: "ai2OLRvDniIGMkR",
      dateOfBirth: "2000-07-08T03:36:38.091Z",
      verified: true,
      name: {
        firstName: "Dean",
        lastName: "Schmidt"
      }
    },
    email: "Dax.Herzog@yahoo.com",
    name: "Scroll Through",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner.png",
    profilePicture: "/assets/images/faces/propic(1).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Cucapa dorada",
  brand: "Modelo",
  price: 245,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/varios/cucapa-dorada.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/varios/cucapa-dorada.webp", "https://corona-atizapan.com/img/categories/beer/varios/cucapa-dorada.webp"],
  categories: ["especiales"],
  status: null,
  reviews: [],
  rating: 4,
  unit: null
}, {
  id: "9e5752e6-6a15-4f50-a5ba-d7151b69ef39",
  slug: "hoegaarden",
  shop: {
    id: "6976c27f-e038-4df2-b8a4-1559a73f4b82",
    slug: "hoegaarden",
    user: {
      id: "422bd71d-b630-4590-92b4-8eee48da1082",
      email: "Josie61@hotmail.com",
      phone: "872-752-8024 x19584",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/928.jpg",
      password: "vtJw__7B6wKo2JF",
      dateOfBirth: "1943-03-03T06:16:37.790Z",
      verified: true,
      name: {
        firstName: "Heber",
        lastName: "White"
      }
    },
    email: "Seth22@yahoo.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-9.png",
    profilePicture: "/assets/images/faces/propic(8).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Hoegaarden",
  brand: "Modelo",
  price: 191,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/varios/hoegaarden.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/varios/hoegaarden.webp", "https://corona-atizapan.com/img/categories/beer/varios/hoegaarden.webp"],
  categories: ["especiales"],
  status: null,
  reviews: [],
  rating: 3,
  unit: null
}, {
  id: "5956d68c-7e56-4c1d-b36d-3df5842491bb",
  slug: "michelob-ultra-blue",
  shop: {
    id: "acfa0595-3e11-4afc-a3e4-c59ddafe5ea5",
    slug: "michelob-ultra-blue",
    user: {
      id: "15e04e05-4446-4a3f-954f-4995ee9cd706",
      email: "Valentine10@gmail.com",
      phone: "(644) 648-8515 x03713",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1203.jpg",
      password: "tCcmXOtawl_2QD3",
      dateOfBirth: "1976-08-28T14:44:08.160Z",
      verified: true,
      name: {
        firstName: "Emelie",
        lastName: "Rogahn"
      }
    },
    email: "Woodrow.Dietrich51@yahoo.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/cycle.png",
    profilePicture: "/assets/images/faces/propic.png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Michelob Ultra Blue",
  brand: "Modelo",
  price: 223,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/varios/michelob-ultra.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/varios/michelob-ultra.webp", "https://corona-atizapan.com/img/categories/beer/varios/michelob-ultra.webp"],
  categories: ["especiales"],
  status: null,
  reviews: [],
  rating: 1,
  unit: null
}, {
  id: "b2428891-f03d-4772-9468-e71146d61b01",
  slug: "michelob-ultra",
  shop: {
    id: "98a35da7-4c3f-451b-a3eb-5e93a87f630a",
    slug: "michelob-ultra",
    user: {
      id: "387d1ffa-1d77-4369-bc4c-8f92a3ed5410",
      email: "Elroy_Kuphal65@yahoo.com",
      phone: "623.810.9356",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/754.jpg",
      password: "ai2OLRvDniIGMkR",
      dateOfBirth: "2000-07-08T03:36:38.091Z",
      verified: true,
      name: {
        firstName: "Dean",
        lastName: "Schmidt"
      }
    },
    email: "Jorge_Klein@yahoo.com",
    name: "Coveted Clicks",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-3.png",
    profilePicture: "/assets/images/faces/propic(2).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Michelob Ultra",
  brand: "Modelo",
  price: 218,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/varios/michelob-ultra1.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/varios/michelob-ultra1.webp", "https://corona-atizapan.com/img/categories/beer/varios/michelob-ultra1.webp"],
  categories: ["especiales"],
  status: null,
  reviews: [],
  rating: 0,
  unit: null
}, {
  id: "0df033b1-786a-467c-af12-8af02c5e2340",
  slug: "michelob-ultra-botella-355",
  shop: {
    id: "98a35da7-4c3f-451b-a3eb-5e93a87f630a",
    slug: "michelob-ultra-botella-355",
    user: {
      id: "387d1ffa-1d77-4369-bc4c-8f92a3ed5410",
      email: "Elroy_Kuphal65@yahoo.com",
      phone: "623.810.9356",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/754.jpg",
      password: "ai2OLRvDniIGMkR",
      dateOfBirth: "2000-07-08T03:36:38.091Z",
      verified: true,
      name: {
        firstName: "Dean",
        lastName: "Schmidt"
      }
    },
    email: "Jorge_Klein@yahoo.com",
    name: "Coveted Clicks",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-3.png",
    profilePicture: "/assets/images/faces/propic(2).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Michelob Ultra Botella 355",
  brand: "Modelo",
  price: 23,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/varios/michelob-ultra-355.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/varios/michelob-ultra-355.webp", "https://corona-atizapan.com/img/categories/beer/varios/michelob-ultra-355.webp"],
  categories: ["especiales"],
  status: null,
  reviews: [],
  rating: 0,
  unit: null
}, {
  id: "a5571910-ffe3-4da8-af59-c4aac83e6e95",
  slug: "stella-artois",
  shop: {
    id: "32040dd2-ea90-4bc0-94d5-2291b9b11208",
    slug: "stella-artois",
    user: {
      id: "9648e2d4-a7e4-4af7-9196-bd6aa788954b",
      email: "Jerald.Nicolas@hotmail.com",
      phone: "1-470-587-7278 x1051",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/870.jpg",
      password: "FhzPBHFvzhQEymY",
      dateOfBirth: "1997-07-23T21:35:22.816Z",
      verified: true,
      name: {
        firstName: "Kaia",
        lastName: "Wyman"
      }
    },
    email: "Brown_Mitchell@yahoo.com",
    name: "Keyboard Kiosk",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-5.png",
    profilePicture: "/assets/images/faces/propic(4).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Stella Artois",
  brand: "Modelo",
  price: 206,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/varios/stella-artois.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/varios/stella-artois.webp", "https://corona-atizapan.com/img/categories/beer/varios/stella-artois.webp"],
  categories: ["especiales"],
  status: null,
  reviews: [],
  rating: 3,
  unit: null
}, {
  id: "adf803f9-dd81-4e76-9345-bc7e8ac5fec2",
  slug: "stella-artois-laton-250",
  shop: {
    id: "641df648-9632-467c-af6f-913f974eb801",
    slug: "stella-artois-laton-250",
    user: {
      id: "bdfc07fa-3ec2-4b63-a221-21cf65d9968e",
      email: "Eileen.Langosh99@yahoo.com",
      phone: "980.409.1017 x6833",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/809.jpg",
      password: "shYo3rkQqRkg9VM",
      dateOfBirth: "1972-03-29T14:31:41.272Z",
      verified: true,
      name: {
        firstName: "Lucinda",
        lastName: "Smith"
      }
    },
    email: "Edna70@hotmail.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Stella Artois Laton 250",
  brand: "Modelo",
  price: 127,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/varios/stella-artois-250.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/varios/stella-artois-250.webp", "https://corona-atizapan.com/img/categories/beer/varios/stella-artois-250.webp"],
  categories: ["especiales"],
  status: null,
  reviews: [],
  rating: 1,
  unit: null
}, {
  id: "3ac0526e-8298-4212-adca-766fcacc6983",
  slug: "nestle",
  shop: {
    id: "9b2e19f3-f3e0-46a3-9529-2aaa504a35b1",
    slug: "nestle",
    user: {
      id: "d015dc20-2c0b-4fc8-82f9-0d41aa782894",
      email: "Paris_Heller61@yahoo.com",
      phone: "1-664-586-1906 x2392",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/139.jpg",
      password: "RD9XWNQDkmvBnh_",
      dateOfBirth: "1961-04-27T14:58:24.959Z",
      verified: true,
      name: {
        firstName: "Jacey",
        lastName: "Mitchell"
      }
    },
    email: "Timmothy79@hotmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Nestle",
  brand: "Modelo",
  price: 55,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/water/nestle/nestle-1.webp",
  images: ["https://corona-atizapan.com/img/categories/water/nestle/nestle-1.webp", "https://corona-atizapan.com/img/categories/water/nestle/nestle-1.webp"],
  categories: ["agua"],
  status: null,
  reviews: [],
  rating: 0,
  unit: null
}, {
  id: "8a17ea02-da75-4fa2-b0ce-5435498b3607",
  slug: "nestle-aquarel",
  shop: {
    id: "9b2e19f3-f3e0-46a3-9529-2aaa504a35b1",
    slug: "nestle-aquarel",
    user: {
      id: "d015dc20-2c0b-4fc8-82f9-0d41aa782894",
      email: "Paris_Heller61@yahoo.com",
      phone: "1-664-586-1906 x2392",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/139.jpg",
      password: "RD9XWNQDkmvBnh_",
      dateOfBirth: "1961-04-27T14:58:24.959Z",
      verified: true,
      name: {
        firstName: "Jacey",
        lastName: "Mitchell"
      }
    },
    email: "Timmothy79@hotmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Nestle Aquarel",
  brand: "Modelo",
  price: 114,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/water/nestle/nestle-aquarel.webp",
  images: ["https://corona-atizapan.com/img/categories/water/nestle/nestle-aquarel.webp", "https://corona-atizapan.com/img/categories/water/nestle/nestle-aquarel.webp"],
  categories: ["agua"],
  status: null,
  reviews: [],
  rating: 0,
  unit: null
}, {
  id: "96127a55-8c13-4a6c-b176-3d0ce4af5235",
  slug: "nestle-manzana",
  shop: {
    id: "9b2e19f3-f3e0-46a3-9529-2aaa504a35b1",
    slug: "nestle-manzana",
    user: {
      id: "d015dc20-2c0b-4fc8-82f9-0d41aa782894",
      email: "Paris_Heller61@yahoo.com",
      phone: "1-664-586-1906 x2392",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/139.jpg",
      password: "RD9XWNQDkmvBnh_",
      dateOfBirth: "1961-04-27T14:58:24.959Z",
      verified: true,
      name: {
        firstName: "Jacey",
        lastName: "Mitchell"
      }
    },
    email: "Timmothy79@hotmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Nestle Manzana",
  brand: "Modelo",
  price: 170,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/water/nestle/nestle-manzana.webp",
  images: ["https://corona-atizapan.com/img/categories/water/nestle/nestle-manzana.webp", "https://corona-atizapan.com/img/categories/water/nestle/nestle-manzana.webp"],
  categories: ["agua"],
  status: null,
  reviews: [],
  rating: 4,
  unit: null
}, {
  id: "70b160a8-760f-41b8-b697-584002593601",
  slug: "nestle-naranja",
  shop: {
    id: "43196d35-a8d0-4f7d-9c05-b10dc4f145af",
    slug: "nestle-naranja",
    user: {
      id: "bdfc07fa-3ec2-4b63-a221-21cf65d9968e",
      email: "Eileen.Langosh99@yahoo.com",
      phone: "980.409.1017 x6833",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/809.jpg",
      password: "shYo3rkQqRkg9VM",
      dateOfBirth: "1972-03-29T14:31:41.272Z",
      verified: true,
      name: {
        firstName: "Lucinda",
        lastName: "Smith"
      }
    },
    email: "Sonia69@yahoo.com",
    name: "Constant Shoppers",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-4.png",
    profilePicture: "/assets/images/faces/propic(3).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Nestle Naranja",
  brand: "Modelo",
  price: 179,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/water/nestle/nestle-naranja.webp",
  images: ["https://corona-atizapan.com/img/categories/water/nestle/nestle-naranja.webp", "https://corona-atizapan.com/img/categories/water/nestle/nestle-naranja.webp"],
  categories: ["agua"],
  status: null,
  reviews: [],
  rating: 0,
  unit: null
}, {
  id: "b1600bfc-453c-49c6-8508-5a7a43a4f6af",
  slug: "nestle-pureza",
  shop: {
    id: "9eecf921-25ca-4d1b-a1c4-f232371a176c",
    slug: "nestle-pureza",
    user: {
      id: "2cc76cbe-8927-48fe-bef4-81f21a4caac3",
      email: "Kristofer_Labadie@hotmail.com",
      phone: "278-749-4568",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/887.jpg",
      password: "w3_IqNOBqwEQdy2",
      dateOfBirth: "1981-06-19T12:09:17.219Z",
      verified: true,
      name: {
        firstName: "Kayli",
        lastName: "Kunde"
      }
    },
    email: "Margaretta_Gibson@hotmail.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Nestle pureza",
  brand: "Modelo",
  price: 88,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/water/nestle/nestle-pureza.webp",
  images: ["https://corona-atizapan.com/img/categories/water/nestle/nestle-pureza.webp", "https://corona-atizapan.com/img/categories/water/nestle/nestle-pureza.webp"],
  categories: ["agua"],
  status: null,
  reviews: [],
  rating: 1,
  unit: null
}, {
  id: "909a4de3-1322-4a35-b16a-5e83d87a6559",
  slug: "nestle-pureza-vital",
  shop: {
    id: "43196d35-a8d0-4f7d-9c05-b10dc4f145af",
    slug: "nestle-pureza-vital",
    user: {
      id: "bdfc07fa-3ec2-4b63-a221-21cf65d9968e",
      email: "Eileen.Langosh99@yahoo.com",
      phone: "980.409.1017 x6833",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/809.jpg",
      password: "shYo3rkQqRkg9VM",
      dateOfBirth: "1972-03-29T14:31:41.272Z",
      verified: true,
      name: {
        firstName: "Lucinda",
        lastName: "Smith"
      }
    },
    email: "Sonia69@yahoo.com",
    name: "Constant Shoppers",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-4.png",
    profilePicture: "/assets/images/faces/propic(3).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Nestle pureza Vital",
  brand: "Modelo",
  price: 64,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/water/nestle/nestle-pureza-vital.webp",
  images: ["https://corona-atizapan.com/img/categories/water/nestle/nestle-pureza-vital.webp", "https://corona-atizapan.com/img/categories/water/nestle/nestle-pureza-vital.webp"],
  categories: ["agua"],
  status: null,
  reviews: [],
  rating: 1,
  unit: null
}, {
  id: "1a536799-bf47-4bcc-8032-c93620655df8",
  slug: "nestle-pureza-vital-botella-1.5",
  shop: {
    id: "9eecf921-25ca-4d1b-a1c4-f232371a176c",
    slug: "nestle-pureza-vital-botella-1.5",
    user: {
      id: "2cc76cbe-8927-48fe-bef4-81f21a4caac3",
      email: "Kristofer_Labadie@hotmail.com",
      phone: "278-749-4568",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/887.jpg",
      password: "w3_IqNOBqwEQdy2",
      dateOfBirth: "1981-06-19T12:09:17.219Z",
      verified: true,
      name: {
        firstName: "Kayli",
        lastName: "Kunde"
      }
    },
    email: "Margaretta_Gibson@hotmail.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Nestle pureza Vital Botella 1.5",
  brand: "Modelo",
  price: 153,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/water/nestle/nestle-pureza-vital1.webp",
  images: ["https://corona-atizapan.com/img/categories/water/nestle/nestle-pureza-vital1.webp", "https://corona-atizapan.com/img/categories/water/nestle/nestle-pureza-vital1.webp"],
  categories: ["agua"],
  status: null,
  reviews: [],
  rating: 1,
  unit: null
}, {
  id: "17ad8982-8914-4205-a445-ed39a27b6cd7",
  slug: "nestle-uva",
  shop: {
    id: "9eecf921-25ca-4d1b-a1c4-f232371a176c",
    slug: "nestle-uva",
    user: {
      id: "2cc76cbe-8927-48fe-bef4-81f21a4caac3",
      email: "Kristofer_Labadie@hotmail.com",
      phone: "278-749-4568",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/887.jpg",
      password: "w3_IqNOBqwEQdy2",
      dateOfBirth: "1981-06-19T12:09:17.219Z",
      verified: true,
      name: {
        firstName: "Kayli",
        lastName: "Kunde"
      }
    },
    email: "Margaretta_Gibson@hotmail.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Nestle Uva",
  brand: "Modelo",
  price: 60,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/water/nestle/nestle-uva.webp",
  images: ["https://corona-atizapan.com/img/categories/water/nestle/nestle-uva.webp", "https://corona-atizapan.com/img/categories/water/nestle/nestle-uva.webp"],
  categories: ["agua"],
  status: null,
  reviews: [],
  rating: 0,
  unit: null
}, {
  id: "9779b088-4014-466b-9189-784958e0a3ae",
  slug: "perrier",
  shop: {
    id: "3d1727f9-f0fd-463b-87fc-81c4df47e992",
    slug: "perrier",
    user: {
      id: "387d1ffa-1d77-4369-bc4c-8f92a3ed5410",
      email: "Elroy_Kuphal65@yahoo.com",
      phone: "623.810.9356",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/754.jpg",
      password: "ai2OLRvDniIGMkR",
      dateOfBirth: "2000-07-08T03:36:38.091Z",
      verified: true,
      name: {
        firstName: "Dean",
        lastName: "Schmidt"
      }
    },
    email: "Dax.Herzog@yahoo.com",
    name: "Scroll Through",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner.png",
    profilePicture: "/assets/images/faces/propic(1).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Perrier",
  brand: "Modelo",
  price: 96,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/water/perrier/perrier.webp",
  images: ["https://corona-atizapan.com/img/categories/water/perrier/perrier.webp", "https://corona-atizapan.com/img/categories/water/perrier/perrier.webp"],
  categories: ["mineral"],
  status: null,
  reviews: [],
  rating: 3,
  unit: null
}, {
  id: "15323c3e-0dd5-4632-9a10-e41563dc4871",
  slug: "san-pellegrino-botella-250",
  shop: {
    id: "3d1727f9-f0fd-463b-87fc-81c4df47e992",
    slug: "san-pellegrino-botella-250",
    user: {
      id: "387d1ffa-1d77-4369-bc4c-8f92a3ed5410",
      email: "Elroy_Kuphal65@yahoo.com",
      phone: "623.810.9356",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/754.jpg",
      password: "ai2OLRvDniIGMkR",
      dateOfBirth: "2000-07-08T03:36:38.091Z",
      verified: true,
      name: {
        firstName: "Dean",
        lastName: "Schmidt"
      }
    },
    email: "Dax.Herzog@yahoo.com",
    name: "Scroll Through",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner.png",
    profilePicture: "/assets/images/faces/propic(1).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "San pellegrino Botella 250",
  brand: "Modelo",
  price: 164,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/water/san-pellegrino/san-pellegrino-250.webp",
  images: ["https://corona-atizapan.com/img/categories/water/san-pellegrino/san-pellegrino-250.webp", "https://corona-atizapan.com/img/categories/water/san-pellegrino/san-pellegrino-250.webp"],
  categories: ["mineral"],
  status: null,
  reviews: [],
  rating: 0,
  unit: null
}, {
  id: "bbefe459-fe73-43d8-bc6b-49a95d6ebdff",
  slug: "san-pellegrino-botella-505",
  shop: {
    id: "9b2e19f3-f3e0-46a3-9529-2aaa504a35b1",
    slug: "san-pellegrino-botella-505",
    user: {
      id: "d015dc20-2c0b-4fc8-82f9-0d41aa782894",
      email: "Paris_Heller61@yahoo.com",
      phone: "1-664-586-1906 x2392",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/139.jpg",
      password: "RD9XWNQDkmvBnh_",
      dateOfBirth: "1961-04-27T14:58:24.959Z",
      verified: true,
      name: {
        firstName: "Jacey",
        lastName: "Mitchell"
      }
    },
    email: "Timmothy79@hotmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "San pellegrino Botella 505",
  brand: "Modelo",
  price: 6,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/water/san-pellegrino/san-pellegrino-505.webp",
  images: ["https://corona-atizapan.com/img/categories/water/san-pellegrino/san-pellegrino-505.webp", "https://corona-atizapan.com/img/categories/water/san-pellegrino/san-pellegrino-505.webp"],
  categories: ["mineral"],
  status: null,
  reviews: [],
  rating: 2,
  unit: null
}, {
  id: "77fa0a6b-a605-4a64-ae64-bf9d45fa44e6",
  slug: "santa-maria-botella-4",
  shop: {
    id: "32040dd2-ea90-4bc0-94d5-2291b9b11208",
    slug: "santa-maria-botella-4",
    user: {
      id: "9648e2d4-a7e4-4af7-9196-bd6aa788954b",
      email: "Jerald.Nicolas@hotmail.com",
      phone: "1-470-587-7278 x1051",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/870.jpg",
      password: "FhzPBHFvzhQEymY",
      dateOfBirth: "1997-07-23T21:35:22.816Z",
      verified: true,
      name: {
        firstName: "Kaia",
        lastName: "Wyman"
      }
    },
    email: "Brown_Mitchell@yahoo.com",
    name: "Keyboard Kiosk",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-5.png",
    profilePicture: "/assets/images/faces/propic(4).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Santa Maria Botella 4",
  brand: "Modelo",
  price: 19,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria.webp",
  images: ["https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria.webp", "https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria.webp"],
  categories: ["agua"],
  status: null,
  reviews: [],
  rating: 4,
  unit: null
}, {
  id: "4d486f14-65e5-47c6-8796-8f615c6257c0",
  slug: "santa-maria-botella-1.5",
  shop: {
    id: "9b2e19f3-f3e0-46a3-9529-2aaa504a35b1",
    slug: "santa-maria-botella-1.5",
    user: {
      id: "d015dc20-2c0b-4fc8-82f9-0d41aa782894",
      email: "Paris_Heller61@yahoo.com",
      phone: "1-664-586-1906 x2392",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/139.jpg",
      password: "RD9XWNQDkmvBnh_",
      dateOfBirth: "1961-04-27T14:58:24.959Z",
      verified: true,
      name: {
        firstName: "Jacey",
        lastName: "Mitchell"
      }
    },
    email: "Timmothy79@hotmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Santa Maria Botella 1.5",
  brand: "Modelo",
  price: 207,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria-1.5.webp",
  images: ["https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria-1.5.webp", "https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria-1.5.webp"],
  categories: ["agua"],
  status: null,
  reviews: [],
  rating: 4,
  unit: null
}, {
  id: "ba35c55c-7cbc-4961-ac9e-1a9740cd6add",
  slug: "santa-maria-botella-1",
  shop: {
    id: "3d1727f9-f0fd-463b-87fc-81c4df47e992",
    slug: "santa-maria-botella-1",
    user: {
      id: "387d1ffa-1d77-4369-bc4c-8f92a3ed5410",
      email: "Elroy_Kuphal65@yahoo.com",
      phone: "623.810.9356",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/754.jpg",
      password: "ai2OLRvDniIGMkR",
      dateOfBirth: "2000-07-08T03:36:38.091Z",
      verified: true,
      name: {
        firstName: "Dean",
        lastName: "Schmidt"
      }
    },
    email: "Dax.Herzog@yahoo.com",
    name: "Scroll Through",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner.png",
    profilePicture: "/assets/images/faces/propic(1).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Santa Maria Botella 1",
  brand: "Modelo",
  price: 167,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria-1.webp",
  images: ["https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria-1.webp", "https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria-1.webp"],
  categories: ["agua"],
  status: null,
  reviews: [],
  rating: 3,
  unit: null
}, {
  id: "bb1a2b46-e550-4bb5-baaa-f96560ec134c",
  slug: "santa-maria-botella-330",
  shop: {
    id: "32040dd2-ea90-4bc0-94d5-2291b9b11208",
    slug: "santa-maria-botella-330",
    user: {
      id: "9648e2d4-a7e4-4af7-9196-bd6aa788954b",
      email: "Jerald.Nicolas@hotmail.com",
      phone: "1-470-587-7278 x1051",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/870.jpg",
      password: "FhzPBHFvzhQEymY",
      dateOfBirth: "1997-07-23T21:35:22.816Z",
      verified: true,
      name: {
        firstName: "Kaia",
        lastName: "Wyman"
      }
    },
    email: "Brown_Mitchell@yahoo.com",
    name: "Keyboard Kiosk",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-5.png",
    profilePicture: "/assets/images/faces/propic(4).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Santa Maria Botella 330",
  brand: "Modelo",
  price: 102,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria-330.webp",
  images: ["https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria-330.webp", "https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria-330.webp"],
  categories: ["agua"],
  status: null,
  reviews: [],
  rating: 4,
  unit: null
}, {
  id: "3b5e0633-e074-4583-bc6c-2cc587b677e5",
  slug: "santa-maria-botella-500",
  shop: {
    id: "9b2e19f3-f3e0-46a3-9529-2aaa504a35b1",
    slug: "santa-maria-botella-500",
    user: {
      id: "d015dc20-2c0b-4fc8-82f9-0d41aa782894",
      email: "Paris_Heller61@yahoo.com",
      phone: "1-664-586-1906 x2392",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/139.jpg",
      password: "RD9XWNQDkmvBnh_",
      dateOfBirth: "1961-04-27T14:58:24.959Z",
      verified: true,
      name: {
        firstName: "Jacey",
        lastName: "Mitchell"
      }
    },
    email: "Timmothy79@hotmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Santa Maria Botella 500",
  brand: "Modelo",
  price: 62,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria-500.webp",
  images: ["https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria-500.webp", "https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria-500.webp"],
  categories: ["agua"],
  status: null,
  reviews: [],
  rating: 3,
  unit: null
}, {
  id: "416e9736-f281-468e-af02-35f8957cdb95",
  slug: "santa-maria-fresa-menta",
  shop: {
    id: "98a35da7-4c3f-451b-a3eb-5e93a87f630a",
    slug: "santa-maria-fresa-menta",
    user: {
      id: "387d1ffa-1d77-4369-bc4c-8f92a3ed5410",
      email: "Elroy_Kuphal65@yahoo.com",
      phone: "623.810.9356",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/754.jpg",
      password: "ai2OLRvDniIGMkR",
      dateOfBirth: "2000-07-08T03:36:38.091Z",
      verified: true,
      name: {
        firstName: "Dean",
        lastName: "Schmidt"
      }
    },
    email: "Jorge_Klein@yahoo.com",
    name: "Coveted Clicks",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-3.png",
    profilePicture: "/assets/images/faces/propic(2).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Santa Maria Fresa Menta",
  brand: "Modelo",
  price: 141,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria-fresa-menta.webp",
  images: ["https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria-fresa-menta.webp", "https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria-fresa-menta.webp"],
  categories: ["agua"],
  status: null,
  reviews: [],
  rating: 4,
  unit: null
}, {
  id: "4a781ec0-c597-4d77-8e66-6ecf27860f1b",
  slug: "santa-maria-gourmet",
  shop: {
    id: "3d1727f9-f0fd-463b-87fc-81c4df47e992",
    slug: "santa-maria-gourmet",
    user: {
      id: "387d1ffa-1d77-4369-bc4c-8f92a3ed5410",
      email: "Elroy_Kuphal65@yahoo.com",
      phone: "623.810.9356",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/754.jpg",
      password: "ai2OLRvDniIGMkR",
      dateOfBirth: "2000-07-08T03:36:38.091Z",
      verified: true,
      name: {
        firstName: "Dean",
        lastName: "Schmidt"
      }
    },
    email: "Dax.Herzog@yahoo.com",
    name: "Scroll Through",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner.png",
    profilePicture: "/assets/images/faces/propic(1).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Santa Maria gourmet",
  brand: "Modelo",
  price: 118,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria-gourmet.webp",
  images: ["https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria-gourmet.webp", "https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria-gourmet.webp"],
  categories: ["agua"],
  status: null,
  reviews: [],
  rating: 2,
  unit: null
}, {
  id: "b3e8f04f-84b4-4d9f-99aa-cbcae443754e",
  slug: "santa-maria-limon-hierbabuena",
  shop: {
    id: "641df648-9632-467c-af6f-913f974eb801",
    slug: "santa-maria-limon-hierbabuena",
    user: {
      id: "bdfc07fa-3ec2-4b63-a221-21cf65d9968e",
      email: "Eileen.Langosh99@yahoo.com",
      phone: "980.409.1017 x6833",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/809.jpg",
      password: "shYo3rkQqRkg9VM",
      dateOfBirth: "1972-03-29T14:31:41.272Z",
      verified: true,
      name: {
        firstName: "Lucinda",
        lastName: "Smith"
      }
    },
    email: "Edna70@hotmail.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Santa Maria Limon hierbabuena",
  brand: "Modelo",
  price: 248,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria-limon-hierbabuena.webp",
  images: ["https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria-limon-hierbabuena.webp", "https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria-limon-hierbabuena.webp"],
  categories: ["agua"],
  status: null,
  reviews: [],
  rating: 3,
  unit: null
}, {
  id: "042db47f-8097-47fe-9752-a50b92e0651d",
  slug: "santa-maria-manzana-jengibre",
  shop: {
    id: "3d1727f9-f0fd-463b-87fc-81c4df47e992",
    slug: "santa-maria-manzana-jengibre",
    user: {
      id: "387d1ffa-1d77-4369-bc4c-8f92a3ed5410",
      email: "Elroy_Kuphal65@yahoo.com",
      phone: "623.810.9356",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/754.jpg",
      password: "ai2OLRvDniIGMkR",
      dateOfBirth: "2000-07-08T03:36:38.091Z",
      verified: true,
      name: {
        firstName: "Dean",
        lastName: "Schmidt"
      }
    },
    email: "Dax.Herzog@yahoo.com",
    name: "Scroll Through",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner.png",
    profilePicture: "/assets/images/faces/propic(1).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Santa Maria Manzana jengibre",
  brand: "Modelo",
  price: 197,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria-manzana-jengibre.webp",
  images: ["https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria-manzana-jengibre.webp", "https://corona-atizapan.com/img/categories/water/santa-maria/santa-maria-manzana-jengibre.webp"],
  categories: ["agua"],
  status: null,
  reviews: [],
  rating: 4,
  unit: null
}, {
  id: "8051f957-e5e9-4108-993d-173801a1441f",
  slug: "red-bull-laton-250",
  shop: {
    id: "641df648-9632-467c-af6f-913f974eb801",
    slug: "red-bull-laton-250",
    user: {
      id: "bdfc07fa-3ec2-4b63-a221-21cf65d9968e",
      email: "Eileen.Langosh99@yahoo.com",
      phone: "980.409.1017 x6833",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/809.jpg",
      password: "shYo3rkQqRkg9VM",
      dateOfBirth: "1972-03-29T14:31:41.272Z",
      verified: true,
      name: {
        firstName: "Lucinda",
        lastName: "Smith"
      }
    },
    email: "Edna70@hotmail.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Red Bull Laton 250",
  brand: "Modelo",
  price: 229,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/energy-drinks/red-bull/red-bull-250.webp",
  images: ["https://corona-atizapan.com/img/categories/energy-drinks/red-bull/red-bull-250.webp", "https://corona-atizapan.com/img/categories/energy-drinks/red-bull/red-bull-250.webp"],
  categories: ["redbull"],
  status: null,
  reviews: [],
  rating: 4,
  unit: null
}, {
  id: "56296e5d-dcad-41f3-8a10-47ceac048792",
  slug: "red-bull-laton-355",
  shop: {
    id: "9b2e19f3-f3e0-46a3-9529-2aaa504a35b1",
    slug: "red-bull-laton-355",
    user: {
      id: "d015dc20-2c0b-4fc8-82f9-0d41aa782894",
      email: "Paris_Heller61@yahoo.com",
      phone: "1-664-586-1906 x2392",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/139.jpg",
      password: "RD9XWNQDkmvBnh_",
      dateOfBirth: "1961-04-27T14:58:24.959Z",
      verified: true,
      name: {
        firstName: "Jacey",
        lastName: "Mitchell"
      }
    },
    email: "Timmothy79@hotmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Red Bull Laton 355",
  brand: "Modelo",
  price: 84,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/energy-drinks/red-bull/red-bull-355.webp",
  images: ["https://corona-atizapan.com/img/categories/energy-drinks/red-bull/red-bull-355.webp", "https://corona-atizapan.com/img/categories/energy-drinks/red-bull/red-bull-355.webp"],
  categories: ["redbull"],
  status: null,
  reviews: [],
  rating: 2,
  unit: null
}];