import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

/* ***Layouts**** */
const FullLayout = lazy(() => import('../layouts/full-layout/FullLayout'));
const BlankLayout = lazy(() => import('../layouts/blank-layout/BlankLayout'));
/* ***End Layouts**** */

const Error = lazy(() => import('../views/authentication/Error'));
const Register = lazy(() => import('../views/authentication/Register'));
const ResetPassword = lazy(() => import('../views/authentication/ResetPassword'));

/* ****Pages***** */
const Home = lazy(() => import('../views/home'));
const ProductList = lazy(() => import('../views/productList'));
const ProductDetail = lazy(() => import('../views/productDetail'));
const Cart = lazy(() => import('../views/cart'));
const Checkout = lazy(() => import('../views/checkout'));
// const Dashboard1 = lazy(() => import('../views/dashboards/Dashboard1'));
// const Detail = lazy(() => import('../views/shipments/detail'));
// const CreateShipment = lazy(() => import('../views/quote/createShipment'));
// const Quote = lazy(() => import('../views/quote/quote'));
// const Address = lazy(() => import('../views/quote/address'));
// const Confirmation = lazy(() => import('../views/quote/confirmation'));
// const Payments = lazy(() => import('../views/payments/payments'));
// const Clients = lazy(() => import('../views/clients/clients'));
// const ClientDetail = lazy(() => import('../views/clients/detail'));
// const DetailBalance = lazy(() => import('../views/clients/detailBalance'));
// const Folios = lazy(() => import('../views/courier/folios'));
// const PriceGroups = lazy(() => import('../views/courier/priceGroups'));
// const Shipments = lazy(() => import('../views/shipments/shipments'));
// const Profile = lazy(() => import('../views/profile/'));
// const Users = lazy(() => import('../views/users/'));
// const AddressList = lazy(() => import('../views/address/address'));
// const Partners = lazy(() => import('../views/partners'));

/* ****Routes***** */

const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      // { path: '/', element: <Navigate to={ "/" } /> },
      // { path: '/', element: <Navigate to={localStorage.session !== undefined ? "/home" : "/auth/404"} /> },
      { path: '/', exact: true, element: <Home /> },
      { path: '/search', exact: true, element: <ProductList /> },
      { path: '/search/:search', exact: true, element: <ProductList /> },
      { path: '/detail/:product', exact: true, element: <ProductDetail /> },
      { path: '/cart', exact: true, element: <Cart /> },
      { path: '/checkout', exact: true, element: <Checkout /> },

      { path: '/register', exact: true, element: <Register /> },
      { path: '/reset-password', exact: true, element: <ResetPassword /> },
      
      // { path: '/dashboard', exact: true, element: <Dashboard1 /> },
      // { path: '/quote', exact: true, element: <Quote /> },
      // { path: '/create', exact: true, element: <CreateShipment /> },
      // { path: '/quote/address', exact: true, element: <Address /> },
      // { path: '/clients', exact: true, element: <Clients /> },
      // { path: '/client/detail/:clientId', exact: true, element: <ClientDetail /> },
      // { path: '/client/detail/:clientId/balance', exact: true, element: <DetailBalance /> },
      // { path: '/courier/redpack', exact: true, element: <Folios /> },
      // { path: '/courier/price-groups', exact: true, element: <PriceGroups /> },
      // { path: '/shipments', exact: true, element: <Shipments /> },
      // { path: '/quote/:shipmentNumber', exact: true, element: <Confirmation /> },
      // { path: '/shipments/:shipmentStatus', exact: true, element: <Shipments /> },
      // { path: '/shipment/detail/:shipmentNumber', exact: true, element: <Detail /> },
      // { path: '/pickup', exact: true, element: <Dashboard1 /> },
      // { path: '/address/:clientId', exact: true, element: <AddressList /> },
      // { path: '/payments', exact: true, element: <Payments /> },
      // { path: '/packages', exact: true, element: <Dashboard1 /> },
      // { path: '/profile', exact: true, element: <Profile /> },
      // { path: '/store', exact: true, element: <Dashboard1 /> },
      // { path: '/users', exact: true, element: <Users /> },
      // { path: '/partners', exact: true, element: <Partners /> },

      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: 'auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
