// carousel data
export const mainCarouselData = [{
  id: 1,
  title: "",
  category: "",
  discount: 50,
  imgUrl: "/assets/images/banners/slide-banner-1.jpg",
  imgMiniUrl: "/assets/images/banners/slide-banner-mini-1.jpg",
  description: "",
  buttonText: "Ver más",
  buttonLink: "/search"
}, {
  id: 2,
  title: "",
  category: "",
  discount: 35,
  imgUrl: "/assets/images/banners/slide-banner-2.jpg",
  imgMiniUrl: "/assets/images/banners/slide-banner-mini-2.jpg",
  description: "",
  buttonText: "Ver más",
  buttonLink: "/search"
}, {
  id: 2,
  title: "",
  category: "",
  discount: 35,
  imgUrl: "/assets/images/banners/slide-banner-3.jpg",
  imgMiniUrl: "/assets/images/banners/slide-banner-mini-3.jpg",
  description: "",
  buttonText: "Ver más",
  buttonLink: "/search"
}];

// categories
export const categories = [{
  id: "51cc79e6-c0d8-40e6-b9a5-a3a0c5931242",
  name: "Cervezas",
  icon: null,
  href: '/search/cervezas',
  image: "/assets/images/categories/cervezas.jpg",
  slug: "cervezas",
  parent: [],
  description: null,
  for: {
    demo: "grupo-modelo",
    type: "top-categories"
  }
}, {
  id: "c1a775f6-7bc5-4722-9c2e-841cae78b93c",
  name: "Cero Alcohol",
  icon: null,
  href: '/search/cervezas-sin-alcohol',
  image: "/assets/images/categories/cero-alcohol.jpg",
  slug: "cervezas-sin-alcohol",
  parent: [],
  description: null,
  for: {
    demo: "grupo-modelo",
    type: "top-categories"
  }
}, {
  id: "dc829abb-9667-4d59-ad2b-5ef455202188",
  name: "Especiales",
  icon: null,
  href: '/search/especiales',
  image: "/assets/images/categories/especiales.jpg",
  slug: "especiales",
  parent: [],
  description: null,
  for: {
    demo: "grupo-modelo",
    type: "top-categories"
  }
}, {
  id: "9c35917b-c0c6-4583-9145-6285b50b49ad",
  name: "Agua Mineral",
  icon: null,
  href: '/search/mineral',
  image: "/assets/images/categories/mineral.jpg",
  slug: "agua-mineral",
  parent: [],
  description: null,
  for: {
    demo: "grupo-modelo",
    type: "top-categories"
  }
}, {
  id: "e13157eb-e9c5-4a24-a59b-98382b7eb3ea",
  name: "Agua",
  icon: null,
  href: '/search/agua',
  image: "/assets/images/categories/agua.jpg",
  slug: "agua",
  parent: [],
  description: null,
  for: {
    demo: "grupo-modelo",
    type: "top-categories"
  }
}, {
  id: "c71a65bd-5c01-444e-82fe-3ef09462aff6",
  name: "RedBull",
  icon: null,
  href: '/search/redbull',
  image: "/assets/images/categories/redbull.jpg",
  slug: "redbull",
  parent: [],
  description: null,
  for: {
    demo: "grupo-modelo",
    type: "top-categories"
  }
}];

// products
export const products = [
{
  id: "6e8f151b-277b-4465-97b6-547f6a72e5c9",
  slug: "bocanegra-botella",
  shop: {
    id: "d3a8d0c3-ea4d-4caa-858e-954fee09ff24",
    slug: "bocanegra-botella",
    user: {
      id: "ba29997c-ed76-4f59-ae31-111dfa532b75",
      email: "Clementina_Raynor@yahoo.com",
      phone: "821.333.9362 x9878",
      avatar: "https://corona-atizapan.com/img/categories/beer/bocanegra/bocanegra.webp",
      password: "673_HVvWUdYguFe",
      dateOfBirth: "2003-06-14T15:18:27.382Z",
      verified: true,
      name: {
        firstName: "Aracely",
        lastName: "Doyle"
      }
    },
    email: "Amira.McDermott83@yahoo.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/cycle.png",
    profilePicture: "/assets/images/faces/propic.png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Cerveza Bocanegra",
  brand: null,
  price: 210,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/bocanegra/bocanegra.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/bocanegra/bocanegra.webp","https://corona-atizapan.com/img/categories/beer/bocanegra/bocanegra.webp"],
  categories: ["Cervezas Especiales"],
  status: null,
  reviews: [],
  for: {
    demo: "grupo-modelo",
    type: "deals-of-the-day"
  }
}, {
  id: "76d14d65-21d0-4b41-8ee1-eef4c2232793",
  slug: "budlight-botella",
  shop: {
    id: "d3a8d0c3-ea4d-4caa-858e-954fee09ff24",
    slug: "budlight-botella",
    user: {
      id: "ba29997c-ed76-4f59-ae31-111dfa532b75",
      email: "Clementina_Raynor@yahoo.com",
      phone: "821.333.9362 x9878",
      avatar: "https://corona-atizapan.com/img/categories/beer/budlight/budlight.webp",
      password: "673_HVvWUdYguFe",
      dateOfBirth: "2003-06-14T15:18:27.382Z",
      verified: true,
      name: {
        firstName: "Aracely",
        lastName: "Doyle"
      }
    },
    email: "Amira.McDermott83@yahoo.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/cycle.png",
    profilePicture: "/assets/images/faces/propic.png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Cerveza Budlight",
  brand: null,
  price: 110,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/budlight/budlight.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/budlight/budlight.webp","https://corona-atizapan.com/img/categories/beer/budlight/budlight.webp"],
  categories: ["Cervezas Especiales"],
  status: null,
  reviews: [],
  for: {
    demo: "grupo-modelo",
    type: "deals-of-the-day"
  }
}, {
  id: "0fffb188-98d8-47f7-8189-254f06cad488",
  slug: "vicky-chamoy-botella",
  shop: {
    id: "d3a8d0c3-ea4d-4caa-858e-954fee09ff24",
    slug: "vicky-chamoy-botella",
    user: {
      id: "ba29997c-ed76-4f59-ae31-111dfa532b75",
      email: "Clementina_Raynor@yahoo.com",
      phone: "821.333.9362 x9878",
      avatar: "https://corona-atizapan.com/img/categories/beer/victoria/victoria.webp",
      password: "673_HVvWUdYguFe",
      dateOfBirth: "2003-06-14T15:18:27.382Z",
      verified: true,
      name: {
        firstName: "Aracely",
        lastName: "Doyle"
      }
    },
    email: "Amira.McDermott83@yahoo.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/cycle.png",
    profilePicture: "/assets/images/faces/propic.png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Cerveza victoria Chamoy",
  brand: null,
  price: 140,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/victoria/victoria.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/victoria/victoria.webp","https://corona-atizapan.com/img/categories/beer/victoria/victoria.webp"],
  categories: ["Cervezas"],
  status: null,
  reviews: [],
  for: {
    demo: "grupo-modelo",
    type: "deals-of-the-day"
  }
}, {
  id: "fb0a22ee-dbae-4ca0-87cf-a2fdde344dec",
  slug: "corona-barrilito-botella",
  shop: {
    id: "d3a8d0c3-ea4d-4caa-858e-954fee09ff24",
    slug: "corona-barrilito-botella",
    user: {
      id: "ba29997c-ed76-4f59-ae31-111dfa532b75",
      email: "Clementina_Raynor@yahoo.com",
      phone: "821.333.9362 x9878",
      avatar: "https://corona-atizapan.com/img/categories/beer/corona/corona-barrilito.webp",
      password: "673_HVvWUdYguFe",
      dateOfBirth: "2003-06-14T15:18:27.382Z",
      verified: true,
      name: {
        firstName: "Aracely",
        lastName: "Doyle"
      }
    },
    email: "Amira.McDermott83@yahoo.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/cycle.png",
    profilePicture: "/assets/images/faces/propic.png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Cerveza Barrilito",
  brand: null,
  price: 180,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/corona/corona-barrilito.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/corona/corona-barrilito.webp","https://corona-atizapan.com/img/categories/beer/corona/corona-barrilito.webp"],
  categories: ["Cervezas"],
  status: null,
  reviews: [],
  for: {
    demo: "grupo-modelo",
    type: "deals-of-the-day"
  }
}, {
  id: "94452fd4-89fb-426e-b90e-db955517d167",
  slug: "corona-ligera-1.8-botella",
  shop: {
    id: "14f0aa30-b962-47e4-aab9-b6b9b40f3d6c",
    slug: "corona-ligera-1.8-botella",
    user: {
      id: "dea7c262-0866-4fa7-a92a-0327e204d010",
      email: "Fausto.Schowalter@gmail.com",
      phone: "859-699-8461 x50403",
      avatar: "https://corona-atizapan.com/img/categories/beer/corona-ligera-1.8/corona-ligera-1.webp",
      password: "1qfhl8ZZ88W6ain",
      dateOfBirth: "1974-02-27T05:00:31.895Z",
      verified: true,
      name: {
        firstName: "Dan",
        lastName: "Purdy"
      }
    },
    email: "Jayce_Corkery32@gmail.com",
    name: "Scroll Through",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner.png",
    profilePicture: "/assets/images/faces/propic(1).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Cerveza light",
  brand: null,
  price: 110,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/corona-ligera-1.8/corona-ligera-1.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/corona-ligera-1.8/corona-ligera-1.webp","https://corona-atizapan.com/img/categories/beer/corona-ligera-1.8/corona-ligera-1.webp"],
  categories: ["Cervezas"],
  status: null,
  reviews: [],
  for: {
    demo: "grupo-modelo",
    type: "deals-of-the-day"
  }
}, {
  id: "c5aa31dc-56db-4328-b3de-ad9383510405",
  slug: "pacifico-clara-botella",
  shop: {
    id: "ab657fb2-89c6-4f1e-b034-6bebfc3d864c",
    slug: "pacifico-clara-botella",
    user: {
      id: "d1d88484-5ec0-4d53-b394-6b7cbc1f6440",
      email: "Marlin63@gmail.com",
      phone: "(700) 338-5159 x24398",
      avatar: "https://corona-atizapan.com/img/categories/beer/regionales/pacifico-clara.webp",
      password: "GwaFEehjyCfdvxR",
      dateOfBirth: "1967-07-25T08:37:16.955Z",
      verified: true,
      name: {
        firstName: "Sharon",
        lastName: "Lebsack"
      }
    },
    email: "Mortimer28@gmail.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Cerveza Pacifico",
  brand: null,
  price: 140,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/regionales/pacifico-clara.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/regionales/pacifico-clara.webp","https://corona-atizapan.com/img/categories/beer/regionales/pacifico-clara.webp"],
  categories: ["Cervezas"],
  status: null,
  reviews: [],
  for: {
    demo: "grupo-modelo",
    type: "deals-of-the-day"
  }
}, {
  id: "4b687a8d-2d14-4724-9f07-f550e582c3ca",
  slug: "cucapa-dorada",
  shop: {
    id: "ac69bc02-6218-4e73-a1d7-637bb80d8546",
    slug: "cucapa-dorada",
    user: {
      id: "ba29997c-ed76-4f59-ae31-111dfa532b75",
      email: "Clementina_Raynor@yahoo.com",
      phone: "821.333.9362 x9878",
      avatar: "https://corona-atizapan.com/img/categories/beer/varios/cucapa-dorada.webp",
      password: "673_HVvWUdYguFe",
      dateOfBirth: "2003-06-14T15:18:27.382Z",
      verified: true,
      name: {
        firstName: "Aracely",
        lastName: "Doyle"
      }
    },
    email: "Salvatore26@yahoo.com",
    name: "Constant Shoppers",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-4.png",
    profilePicture: "/assets/images/faces/propic(3).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Cerveza Cucapa",
  brand: null,
  price: 210,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/varios/cucapa-dorada.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/varios/cucapa-dorada.webp","https://corona-atizapan.com/img/categories/beer/varios/cucapa-dorada.webp"],
  categories: ["Cervezas Especiales"],
  status: null,
  reviews: [],
  for: {
    demo: "grupo-modelo",
    type: "deals-of-the-day"
  }
}, {
  id: "d0dd72e2-8624-4792-8248-8cc1d2d913c9",
  slug: "michelob-ultra-botella-355",
  shop: {
    id: "a02892b3-c442-49d4-8ac1-24fede12a300",
    slug: "michelob-ultra-botella-355",
    user: {
      id: "366ee083-9219-47fa-a4b6-dff04ec010cf",
      email: "Junius.Farrell@gmail.com",
      phone: "558.717.4898 x4537",
      avatar: "https://corona-atizapan.com/img/categories/beer/varios/michelob-ultra-355.webp",
      password: "IszXJOR5PSVUckz",
      dateOfBirth: "1989-09-12T14:49:00.812Z",
      verified: true,
      name: {
        firstName: "Dee",
        lastName: "Lynch"
      }
    },
    email: "Furman_Schneider@gmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Cerveza Michelobu Ultra",
  brand: null,
  price: 110,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "https://corona-atizapan.com/img/categories/beer/varios/michelob-ultra-355.webp",
  images: ["https://corona-atizapan.com/img/categories/beer/varios/michelob-ultra-355.webp","https://corona-atizapan.com/img/categories/beer/varios/michelob-ultra-355.webp"],
  categories: ["Cervezas Especiales"],
  status: null,
  reviews: [],
  for: {
    demo: "grupo-modelo",
    type: "deals-of-the-day"
  }
}];

// services
export const serviceList = [{
  id: "ee272e30-0309-4e8a-966c-f63410c58289",
  icon: "Truck",
  title: "Enviós gratis",
  description: "En pedidos de más de $500"
}, {
  id: "1f30c35b-5b76-42c0-822f-83ecbfb64869",
  icon: "MoneyGuarantee",
  title: "Cuida tu dinero",
  description: "Operaciones seguras"
}, {
  id: "a4c3968e-93ba-444b-a68f-2e6dd9a5eb1a",
  icon: "AlarmClock",
  title: "Ahorra tiempo",
  description: "Compra en linea"
}, {
  id: "87379ae9-5e25-4d53-8d7d-7cc3f971dd67",
  icon: "Payment",
  title: "Pagos",
  description: "Sistema de pagos seguro"
}];

// single category data
export const singleCategory = {
  title: "Electronic",
  children: ["Wireless Speaker", "Tablet", "Smartphone", "Laptop", "iMac", "Game Controller", "Drone", "Apple"]
};

// brands
export const brandList = [{
  id: "9aee1898-23e0-4965-8f0d-427dc963ae07",
  slug: "victoria",
  name: "victoria",
  image: "/assets/images/brands/victoria.jpg",
  type: "cervezas",
  for: {
    demo: "grupo-modelo",
    type: "top-brands"
  }
}, {
  id: "024ec2a2-7ca7-46c2-8990-f78ac4215dd1",
  slug: "leon",
  name: "leon",
  image: "/assets/images/brands/leon.jpg",
  type: "cervezas",
  for: {
    demo: "grupo-modelo",
    type: "top-brands"
  }
}, {
  id: "e370f16a-90e2-4f26-b9af-a38d6f645a4c",
  slug: "modelo",
  name: "modelo",
  image: "/assets/images/brands/modelo.jpg",
  type: "cervezas",
  for: {
    demo: "grupo-modelo",
    type: "top-brands"
  }
}, {
  id: "d8f4c60e-6bac-4fdc-9880-999fbddac97d",
  slug: "cucapa-2",
  name: "cucapa",
  image: "/assets/images/brands/cucapa.jpg",
  type: "cervezas",
  for: {
    demo: "grupo-modelo",
    type: "top-brands"
  }
}, {
  id: "0c74cb7c-6082-4640-9380-815928be7127",
  slug: "barrilito",
  name: "barrilito",
  image: "/assets/images/brands/barrilito.jpg",
  type: "cervezas",
  for: {
    demo: "grupo-modelo",
    type: "top-brands"
  }
}, {
  id: "0c74cb7c-6082-4640-9380-815928be7127",
  slug: "bocanegra",
  name: "bocanegra",
  image: "/assets/images/brands/bocanegra.jpg",
  type: "cervezas",
  for: {
    demo: "grupo-modelo",
    type: "top-brands"
  }
}, {
  id: "0c74cb7c-6082-4640-9380-815928be7127",
  slug: "budlight",
  name: "budlight",
  image: "/assets/images/brands/budlight.jpg",
  type: "cervezas",
  for: {
    demo: "grupo-modelo",
    type: "top-brands"
  }
}];