export const products = [{
  id: "eecbf3ce-7a23-4a09-a515-2cf2c50f4fdc",
  slug: "samsung-galaxy-m1",
  shop: {
    id: "0a045861-99f1-41d5-afe5-af95f6a9b2c0",
    slug: "keyboard-kiosk",
    user: {
      id: "c8f28042-ea46-4502-957d-c8f84b423009",
      email: "Clare7@yahoo.com",
      phone: "746-662-6200 x23927",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/506.jpg",
      password: "ZRRtZ5y8Y_PKDwc",
      dateOfBirth: "1952-08-29T19:34:20.601Z",
      verified: true,
      name: {
        firstName: "Raven",
        lastName: "Wisoky"
      }
    },
    email: "Clifton.Mohr@hotmail.com",
    name: "Keyboard Kiosk",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-5.png",
    profilePicture: "/assets/images/faces/propic(4).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Samsung Galaxy-M1",
  brand: "/assets/images/brands/samsung.png",
  price: 250,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "/assets/images/products/samsung.png",
  images: ["/assets/images/products/samsung.png", "/assets/images/products/samsung.png"],
  categories: ["Gadgets"],
  status: null,
  reviews: [],
  rating: 5,
  unit: null,
  published: true
}, {
  id: "1dd02b91-e6db-4cfd-a101-9841e8fe344f",
  slug: "tomatto",
  shop: {
    id: "1dcc7fb4-dd2f-4936-b4a1-984a8bb8311c",
    slug: "constant-shoppers",
    user: {
      id: "86586aa5-685a-45fa-b22f-7ebe34a08d4e",
      email: "Edmond_Abbott95@yahoo.com",
      phone: "1-362-805-7214 x3102",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/937.jpg",
      password: "xtlTCrvPcvRo7kB",
      dateOfBirth: "1946-02-20T18:43:29.196Z",
      verified: true,
      name: {
        firstName: "Pedro",
        lastName: "Simonis"
      }
    },
    email: "Nakia_Zemlak@hotmail.com",
    name: "Constant Shoppers",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-4.png",
    profilePicture: "/assets/images/faces/propic(3).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Tomatto",
  brand: "/assets/images/brands/brokshire.png",
  price: 10,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "/assets/images/products/tomato.png",
  images: ["/assets/images/products/tomato.png", "/assets/images/products/tomato.png"],
  categories: ["Grocery"],
  status: null,
  reviews: [],
  rating: 5,
  unit: null,
  published: true
}, {
  id: "efd10563-4215-4d25-ac35-ffbc3bf9b707",
  slug: "boston-round-cream-pack",
  shop: {
    id: "d4e8ebf5-1a67-4235-930c-8992bd6f1c3d",
    slug: "word-wide-wishes",
    user: {
      id: "67f2465a-076e-4cf9-81d8-db46d96381cf",
      email: "Earlene_Pollich61@yahoo.com",
      phone: "509.939.6905",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/531.jpg",
      password: "9ce68HDdnwnYFQn",
      dateOfBirth: "1996-09-06T14:15:05.755Z",
      verified: true,
      name: {
        firstName: "Daphne",
        lastName: "Reichert"
      }
    },
    email: "Damon2@gmail.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Boston Round Cream Pack",
  brand: "/assets/images/brands/levis.png",
  price: 24,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "/assets/images/products/beauty-cream.png",
  images: ["/assets/images/products/beauty-cream.png", "/assets/images/products/beauty-cream.png"],
  categories: ["Beauty"],
  status: null,
  reviews: [],
  rating: 5,
  unit: null,
  published: false
}, {
  id: "e80e40ad-840f-426d-bb79-89edead89a96",
  slug: "woman-party-dress",
  shop: {
    id: "e3700a58-9697-4eeb-92e3-ba6fc16992ed",
    slug: "scarlett-beauty",
    user: {
      id: "2f035122-413b-47b8-b857-b7abc706c461",
      email: "Sandrine_Okuneva@yahoo.com",
      phone: "975.919.6321 x31038",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/122.jpg",
      password: "oQAXKdNNwdAz3Ri",
      dateOfBirth: "1984-06-11T04:09:55.108Z",
      verified: true,
      name: {
        firstName: "Hope",
        lastName: "Ullrich"
      }
    },
    email: "Marlin71@gmail.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/cycle.png",
    profilePicture: "/assets/images/faces/propic.png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Woman Party Dress",
  brand: "/assets/images/brands/raymond.png",
  price: 35,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "/assets/images/products/red-dress.png",
  images: ["/assets/images/products/red-dress.png", "/assets/images/products/red-dress.png"],
  categories: ["Fashion"],
  status: null,
  reviews: [],
  rating: 5,
  unit: null,
  published: true
}, {
  id: "11881b89-b5b3-4663-8917-cdbc5b284b39",
  slug: "white-tops",
  shop: {
    id: "a83202ce-5001-45c1-a414-aa353861cfe1",
    slug: "anytime-buys",
    user: {
      id: "70865f2d-3e3f-4f53-b5c4-d64b303389e9",
      email: "Anastacio.Spinka31@yahoo.com",
      phone: "1-766-818-1553",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/576.jpg",
      password: "pc2HZQY3j0Ef5Rs",
      dateOfBirth: "1961-08-19T07:36:57.073Z",
      verified: true,
      name: {
        firstName: "Trudie",
        lastName: "Torp"
      }
    },
    email: "Hal_Hayes67@gmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "White Tops",
  brand: "/assets/images/brands/raymond.png",
  price: 16,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "/assets/images/products/white-tops.png",
  images: ["/assets/images/products/white-tops.png", "/assets/images/products/white-tops.png"],
  categories: ["Fashion"],
  status: null,
  reviews: [],
  rating: 5,
  unit: null,
  published: true
}, {
  id: "5dc1404f-fbef-4efc-a647-05faca24cc55",
  slug: "casual-shirt-for-man",
  shop: {
    id: "9d98e6de-071e-489f-916e-9bad70d2edaf",
    slug: "cybershop",
    user: {
      id: "2f035122-413b-47b8-b857-b7abc706c461",
      email: "Sandrine_Okuneva@yahoo.com",
      phone: "975.919.6321 x31038",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/122.jpg",
      password: "oQAXKdNNwdAz3Ri",
      dateOfBirth: "1984-06-11T04:09:55.108Z",
      verified: true,
      name: {
        firstName: "Hope",
        lastName: "Ullrich"
      }
    },
    email: "Dwight7@gmail.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Casual Shirt for Man",
  brand: "/assets/images/brands/raymond.png",
  price: 26,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "/assets/images/products/formal-shirt.png",
  images: ["/assets/images/products/formal-shirt.png", "/assets/images/products/formal-shirt.png"],
  categories: ["Fashion"],
  status: null,
  reviews: [],
  rating: 5,
  unit: null,
  published: false
}, {
  id: "738942f0-5cc1-478d-bb0d-34163d8dcb4f",
  slug: "blue-premium-t-shirt",
  shop: {
    id: "e3700a58-9697-4eeb-92e3-ba6fc16992ed",
    slug: "scarlett-beauty",
    user: {
      id: "2f035122-413b-47b8-b857-b7abc706c461",
      email: "Sandrine_Okuneva@yahoo.com",
      phone: "975.919.6321 x31038",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/122.jpg",
      password: "oQAXKdNNwdAz3Ri",
      dateOfBirth: "1984-06-11T04:09:55.108Z",
      verified: true,
      name: {
        firstName: "Hope",
        lastName: "Ullrich"
      }
    },
    email: "Marlin71@gmail.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/cycle.png",
    profilePicture: "/assets/images/faces/propic.png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Blue Premium T-shirt",
  brand: "/assets/images/brands/raymond.png",
  price: 21,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "/assets/images/products/blu-tshirt.png",
  images: ["/assets/images/products/blu-tshirt.png", "/assets/images/products/blu-tshirt.png"],
  categories: ["Fashion"],
  status: null,
  reviews: [],
  rating: 5,
  unit: null,
  published: true
}, {
  id: "b8711072-6d72-469e-853e-57b089a0b4cb",
  slug: "man-trowzer-pant",
  shop: {
    id: "9d98e6de-071e-489f-916e-9bad70d2edaf",
    slug: "cybershop",
    user: {
      id: "2f035122-413b-47b8-b857-b7abc706c461",
      email: "Sandrine_Okuneva@yahoo.com",
      phone: "975.919.6321 x31038",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/122.jpg",
      password: "oQAXKdNNwdAz3Ri",
      dateOfBirth: "1984-06-11T04:09:55.108Z",
      verified: true,
      name: {
        firstName: "Hope",
        lastName: "Ullrich"
      }
    },
    email: "Dwight7@gmail.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Man Trowzer Pant",
  brand: "/assets/images/brands/raymond.png",
  price: 12,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "/assets/images/products/pnat.png",
  images: ["/assets/images/products/pnat.png", "/assets/images/products/pnat.png"],
  categories: ["Fashion"],
  status: null,
  reviews: [],
  rating: 5,
  unit: null,
  published: false
}];