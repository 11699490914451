export const resources = {
  en: {
    translation: {
      HOT: "HOT",
      "Free Express Shipping": "Free Express Shipping"
    }
  },
  es: {
    translation: {
      HOT: "Todo el mes",
      "Free Express Shipping": "Envíos y Gratis"
    }
  }
};