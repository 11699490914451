import React from 'react';
import { useRoutes } from 'react-router-dom';
import { CssBaseline } from '@mui/material';

// import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings';
import ThemeProvider from "./theme/theme-provider"; // PRODUCT CART PROVIDER
import Router from './routes/Router';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { RTL } from "./components/rtl";
import { ProgressBar } from "./components/progress"; // IMPORT DUMMY SERVER

import "./__server__"; // IMPORT i18n SUPPORT FILE
import "./i18n";

function App() {
  const routing = useRoutes(Router);

  // const customizer = useSelector((state) => state.CustomizerReducer);
  return (
    <ThemeProvider>
      <CssBaseline />
      <ProgressBar />
      <RTL>{routing}</RTL>
    </ThemeProvider>
  );
}

export default App;
