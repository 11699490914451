export const customers = [{
  id: "df933ff2-2813-4a8a-9db5-8a7c97c1ea06",
  email: "ethan@gmail.com",
  phone: "+12345678910",
  avatar: "/assets/images/avatars/001-man.svg",
  balance: 10350.25,
  orders: "07",
  name: "Ethan Booth"
}, {
  id: "bddc6241-21fd-4bd8-a4e4-ea289ec609cd",
  email: "sofia@gmail.com",
  phone: "+12343458910",
  avatar: "/assets/images/avatars/002-girl.svg",
  balance: 12350.45,
  orders: "02",
  name: "Sofia Hall"
}, {
  id: "86f61ce9-2dd4-4377-b273-08a77bea5d5b",
  email: "dominic@gmail.com",
  phone: "+12345678910",
  avatar: "/assets/images/avatars/002-woman.svg",
  balance: 11345.25,
  orders: "03",
  name: "Dominic Moss"
}, {
  id: "fb318901-4f55-41dc-a9db-29a12a2c0169",
  email: "tilly@gmail.com",
  phone: "+12345678910",
  avatar: "/assets/images/avatars/003-boy.svg",
  balance: 9540.47,
  orders: "04",
  name: "Tilly Thomson"
}, {
  id: "1656278e-f508-4e1c-bbf1-59f167dfd5ed",
  email: "finley@gmail.com",
  phone: "+12345678910",
  avatar: "/assets/images/avatars/003-man-1.svg",
  balance: 7250.36,
  orders: "09",
  name: "Finley Henry"
}, {
  id: "bddb8779-10bc-4b59-b19e-e6e731caca21",
  email: "lora@gmail.com",
  phone: "+12345678910",
  avatar: "/assets/images/avatars/004-bald.svg",
  balance: 8356.34,
  orders: "09",
  name: "Lola Ryan"
}, {
  id: "4997f27a-936c-430e-bdea-7387a3c7f97d",
  email: "gabriel@gmail.com",
  phone: "+12345678910",
  avatar: "/assets/images/avatars/004-woman.svg",
  balance: 4370.55,
  orders: "12",
  name: "Gabriel McKenzie"
}, {
  id: "a32e32c3-f75d-45ed-bd52-b98232131854",
  email: "james@gmail.com",
  phone: "+12345678910",
  avatar: "/assets/images/avatars/005-man-2.svg",
  balance: 2458.15,
  orders: "11",
  name: "James Davey"
}];