// carousel-data
export const mainCarouselData = [{
  title: "50% Off For Your First Shopping",
  imgUrl: "/assets/images/products/nike-black.png",
  description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis lobortis consequat eu, quam etiam at quis ut convalliss.`,
  buttonText: "Shop Now",
  buttonLik: "#"
}, {
  title: "50% Off For Your First Shopping",
  imgUrl: "/assets/images/products/nike-black.png",
  description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis lobortis consequat eu, quam etiam at quis ut convalliss.`,
  buttonText: "Shop Now",
  buttonLik: "#"
}];
export const categories = [{
  id: "f4c1291a-ff9e-4aa2-aa6c-95eb8b8bcc5e",
  name: "Headphone",
  icon: null,
  slug: "headphone",
  image: "/assets/images/banners/category-1.png",
  description: "3k orders this week",
  parent: [],
  for: {
    demo: "market-1",
    type: "top-categories"
  }
}, {
  id: "5dff32d0-5570-42cd-a8b6-61b0541e28b9",
  name: "Watch",
  icon: null,
  slug: "watch",
  image: "/assets/images/banners/category-2.png",
  description: "3k orders this week",
  parent: [],
  for: {
    demo: "market-1",
    type: "top-categories"
  }
}, {
  id: "c8074ef7-6bf3-496a-91d5-d974dc7d091b",
  name: "Sunglass",
  icon: null,
  slug: "sunglass",
  image: "/assets/images/banners/category-3.png",
  description: "3k orders this week",
  parent: [],
  for: {
    demo: "market-1",
    type: "top-categories"
  }
}, {
  id: "ae6b57bf-b967-484b-ab32-3314f10f5166",
  name: "Headphone",
  icon: null,
  slug: "headphone",
  image: "/assets/images/banners/category-1.png",
  description: "3k orders this week",
  parent: [],
  for: {
    demo: "market-1",
    type: "top-categories"
  }
}, {
  id: "0ed2a642-0b27-47ec-8d9c-b3fce4159cfd",
  name: "Watch",
  icon: null,
  slug: "watch",
  image: "/assets/images/banners/category-2.png",
  description: "3k orders this week",
  parent: [],
  for: {
    demo: "market-1",
    type: "top-categories"
  }
}, {
  id: "6df3771a-7075-492d-8f1f-c8358cd6345f",
  name: "Automobile",
  icon: null,
  slug: "automobile",
  image: "/assets/images/products/Fashion/Clothes/7.DenimClassicBlueJeans.png",
  description: null,
  parent: [],
  for: {
    demo: "market-1",
    type: "categories"
  }
}, {
  id: "25a4d207-512d-4001-b531-1cded6665f88",
  name: "Car",
  icon: null,
  slug: "car",
  image: "/assets/images/products/Fashion/Clothes/20.GrayOvercoatWomen.png",
  description: null,
  parent: [],
  for: {
    demo: "market-1",
    type: "categories"
  }
}, {
  id: "79c89024-dff2-4a00-9ce5-251145dfc18f",
  name: "Fashion",
  icon: null,
  slug: "fashion",
  image: "/assets/images/products/Fashion/Jewellery/8.IndianPearlThreadEarrings.png",
  description: null,
  parent: [],
  for: {
    demo: "market-1",
    type: "categories"
  }
}, {
  id: "6097cbe8-eba5-4462-aea0-b0d53f9dd0ed",
  name: "Mobile",
  icon: null,
  slug: "mobile",
  image: "/assets/images/products/Fashion/Jewellery/21.FeathersandBeadsBohemianNecklace.png",
  description: null,
  parent: [],
  for: {
    demo: "market-1",
    type: "categories"
  }
}, {
  id: "e6660169-fbba-4724-81e3-8ffae6ff3707",
  name: "Laptop",
  icon: null,
  slug: "laptop",
  image: "/assets/images/products/Fashion/Shoes/11.Flowwhite.png",
  description: null,
  parent: [],
  for: {
    demo: "market-1",
    type: "categories"
  }
}, {
  id: "158d635a-76fd-425b-a158-1e8d8cd97542",
  name: "Desktop",
  icon: null,
  slug: "desktop",
  image: "/assets/images/products/Groceries/1.SaktiSambarPowder.png",
  description: null,
  parent: [],
  for: {
    demo: "market-1",
    type: "categories"
  }
}, {
  id: "0b68c451-0d4d-47ac-83e4-33651ae3d11f",
  name: "Tablet",
  icon: null,
  slug: "tablet",
  image: "/assets/images/products/Groceries/14.ACIProducts.png",
  description: null,
  parent: [],
  for: {
    demo: "market-1",
    type: "categories"
  }
}, {
  id: "7a069456-f06a-4688-994e-ddb620f9f67c",
  name: "Fashion",
  icon: null,
  slug: "fashion",
  image: "/assets/images/products/Groceries/27.SardinesPack.png",
  description: null,
  parent: [],
  for: {
    demo: "market-1",
    type: "categories"
  }
}, {
  id: "5144e1aa-c745-44ae-a40b-a41a35229d27",
  name: "Electronics",
  icon: null,
  slug: "electronics",
  image: "/assets/images/products/Health&Beauty/12.BeautySocietyantiacnemask.png",
  description: null,
  parent: [],
  for: {
    demo: "market-1",
    type: "categories"
  }
}, {
  id: "b75056ae-be37-4061-a06e-9db6a9fda38b",
  name: "Furniture",
  icon: null,
  slug: "furniture",
  image: "/assets/images/products/Health&Beauty/25.MarioBadescuSkinCareShampoo.png",
  description: null,
  parent: [],
  for: {
    demo: "market-1",
    type: "categories"
  }
}, {
  id: "282a5ae0-9d26-4edf-848b-ffb66d949bbe",
  name: "Camera",
  icon: null,
  slug: "camera",
  image: "/assets/images/products/Home&Garden/13.GardenRosesinBlueVase.png",
  description: null,
  parent: [],
  for: {
    demo: "market-1",
    type: "categories"
  }
}, {
  id: "3c36096b-73b4-4c3e-a768-f846cc889fd5",
  name: "Electronics",
  icon: null,
  slug: "electronics",
  image: "/assets/images/products/Fashion/Accessories/12.Xiaomimiband2.png",
  description: null,
  parent: [],
  for: {
    demo: "market-1",
    type: "categories"
  }
}]; // products

export const products = [{
  id: "8ac53dd8-6418-454f-bbb5-29f0b26ac5a5",
  slug: "nikecourt-zoom-vapor-cage",
  shop: {
    id: "baa3a02b-42da-40c0-852b-3aa292b70704",
    slug: "anytime-buys",
    user: {
      id: "e0d8be78-c530-4f1a-a2df-d082ba2db274",
      email: "Daisy.McLaughlin80@yahoo.com",
      phone: "1-462-772-4274 x931",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/174.jpg",
      password: "nAGr0Rkf8CLP4Sv",
      dateOfBirth: "1958-06-07T17:14:57.585Z",
      verified: true,
      name: {
        firstName: "Llewellyn",
        lastName: "Schmitt"
      }
    },
    email: "Vida.Simonis@hotmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "NikeCourt Zoom Vapor Cage",
  brand: null,
  price: 250,
  size: null,
  colors: [],
  discount: 25,
  thumbnail: "/assets/images/products/flash-1.png",
  images: ["/assets/images/products/flash-1.png", "/assets/images/products/flash-1.png"],
  categories: [],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "flash-deals"
  }
}, {
  id: "f8b79800-f098-452d-a849-a9aed1aa38ca",
  slug: "classic-rolex-watch",
  shop: {
    id: "e09cf554-59e3-4a66-8c70-13705a24a6f9",
    slug: "scarlett-beauty",
    user: {
      id: "b7caefd6-52a7-45e6-b024-7430fd3d6d62",
      email: "Jessyca_Sauer@hotmail.com",
      phone: "1-443-750-5479 x33324",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/994.jpg",
      password: "XE7zoWg0n7Eoir0",
      dateOfBirth: "1945-05-31T16:16:45.200Z",
      verified: true,
      name: {
        firstName: "Ila",
        lastName: "Bartoletti"
      }
    },
    email: "Delbert_Schaden95@gmail.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/cycle.png",
    profilePicture: "/assets/images/faces/propic.png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Classic Rolex Watch",
  brand: null,
  price: 350,
  size: null,
  colors: [],
  discount: 15,
  thumbnail: "/assets/images/products/flash-2.png",
  images: ["/assets/images/products/flash-2.png", "/assets/images/products/flash-2.png"],
  categories: [],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "flash-deals"
  }
}, {
  id: "1c3a711b-5ad3-41dd-9df8-f87fc5be02a1",
  slug: "iphone-13-pro-max",
  shop: {
    id: "d9003718-dc32-4847-a837-be38d1d5eefc",
    slug: "cybershop",
    user: {
      id: "f194bf16-4c51-4e80-8486-4034c139c551",
      email: "Chanelle_Goyette13@gmail.com",
      phone: "1-342-656-1631 x1966",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/335.jpg",
      password: "Dq9Nh6w6SzGH9WE",
      dateOfBirth: "1958-06-15T07:13:16.729Z",
      verified: true,
      name: {
        firstName: "Ellsworth",
        lastName: "Batz"
      }
    },
    email: "Jamarcus66@hotmail.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "IPhone 13 Pro Max",
  brand: null,
  price: 150,
  size: null,
  colors: [],
  discount: 28,
  thumbnail: "/assets/images/products/flash-3.png",
  images: ["/assets/images/products/flash-3.png", "/assets/images/products/flash-3.png"],
  categories: [],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "flash-deals"
  }
}, {
  id: "610bbf6b-a8ec-47e2-85ec-fa4fd0ab1bd0",
  slug: "mi-led-smart-watch",
  shop: {
    id: "a7be4885-e5fa-4d91-8137-83aa3e438f27",
    slug: "word-wide-wishes",
    user: {
      id: "b7caefd6-52a7-45e6-b024-7430fd3d6d62",
      email: "Jessyca_Sauer@hotmail.com",
      phone: "1-443-750-5479 x33324",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/994.jpg",
      password: "XE7zoWg0n7Eoir0",
      dateOfBirth: "1945-05-31T16:16:45.200Z",
      verified: true,
      name: {
        firstName: "Ila",
        lastName: "Bartoletti"
      }
    },
    email: "Unique_Walter@yahoo.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Mi Led Smart Watch",
  brand: null,
  price: 180,
  size: null,
  colors: [],
  discount: 21,
  thumbnail: "/assets/images/products/flash-4.png",
  images: ["/assets/images/products/flash-4.png", "/assets/images/products/flash-4.png"],
  categories: [],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "flash-deals"
  }
}, {
  id: "b76530e7-ed05-44fc-ba94-57b877e55be3",
  slug: "nikecourt-zoom-vapor-cage",
  shop: {
    id: "43d12519-03e2-4149-9885-39f9719afdc5",
    slug: "keyboard-kiosk",
    user: {
      id: "acb9c372-3436-487b-990c-7061c6a6e78b",
      email: "Benny33@gmail.com",
      phone: "607.254.6815",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/425.jpg",
      password: "3R6_RTikWUBtM6W",
      dateOfBirth: "1955-11-30T15:23:22.368Z",
      verified: true,
      name: {
        firstName: "Rowan",
        lastName: "Grimes"
      }
    },
    email: "Olga.Cronin55@yahoo.com",
    name: "Keyboard Kiosk",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-5.png",
    profilePicture: "/assets/images/faces/propic(4).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "NikeCourt Zoom Vapor Cage",
  brand: null,
  price: 250,
  size: null,
  colors: [],
  discount: 25,
  thumbnail: "/assets/images/products/flash-1.png",
  images: ["/assets/images/products/flash-1.png", "/assets/images/products/flash-1.png"],
  categories: [],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "flash-deals"
  }
}, {
  id: "cabe25cf-1f29-460d-9481-06ff1afb493e",
  slug: "classic-rolex-watch",
  shop: {
    id: "e622c74d-2ee3-4b75-a416-32260ba0d068",
    slug: "coveted-clicks",
    user: {
      id: "e0d8be78-c530-4f1a-a2df-d082ba2db274",
      email: "Daisy.McLaughlin80@yahoo.com",
      phone: "1-462-772-4274 x931",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/174.jpg",
      password: "nAGr0Rkf8CLP4Sv",
      dateOfBirth: "1958-06-07T17:14:57.585Z",
      verified: true,
      name: {
        firstName: "Llewellyn",
        lastName: "Schmitt"
      }
    },
    email: "Oma_Breitenberg@hotmail.com",
    name: "Coveted Clicks",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-3.png",
    profilePicture: "/assets/images/faces/propic(2).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Classic Rolex Watch",
  brand: null,
  price: 350,
  size: null,
  colors: [],
  discount: 15,
  thumbnail: "/assets/images/products/flash-2.png",
  images: ["/assets/images/products/flash-2.png", "/assets/images/products/flash-2.png"],
  categories: [],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "flash-deals"
  }
}, {
  id: "7378a3fe-7170-4ea1-af36-6566e3ce7174",
  slug: "iphone-13-pro-max",
  shop: {
    id: "baa3a02b-42da-40c0-852b-3aa292b70704",
    slug: "anytime-buys",
    user: {
      id: "e0d8be78-c530-4f1a-a2df-d082ba2db274",
      email: "Daisy.McLaughlin80@yahoo.com",
      phone: "1-462-772-4274 x931",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/174.jpg",
      password: "nAGr0Rkf8CLP4Sv",
      dateOfBirth: "1958-06-07T17:14:57.585Z",
      verified: true,
      name: {
        firstName: "Llewellyn",
        lastName: "Schmitt"
      }
    },
    email: "Vida.Simonis@hotmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "IPhone 13 Pro Max",
  brand: null,
  price: 150,
  size: null,
  colors: [],
  discount: 28,
  thumbnail: "/assets/images/products/flash-3.png",
  images: ["/assets/images/products/flash-3.png", "/assets/images/products/flash-3.png"],
  categories: [],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "flash-deals"
  }
}, {
  id: "fc62a319-0dfe-4410-8e93-d0a7bae61b9c",
  slug: "mi-led-smart-watch",
  shop: {
    id: "baa3a02b-42da-40c0-852b-3aa292b70704",
    slug: "anytime-buys",
    user: {
      id: "e0d8be78-c530-4f1a-a2df-d082ba2db274",
      email: "Daisy.McLaughlin80@yahoo.com",
      phone: "1-462-772-4274 x931",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/174.jpg",
      password: "nAGr0Rkf8CLP4Sv",
      dateOfBirth: "1958-06-07T17:14:57.585Z",
      verified: true,
      name: {
        firstName: "Llewellyn",
        lastName: "Schmitt"
      }
    },
    email: "Vida.Simonis@hotmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Mi Led Smart Watch",
  brand: null,
  price: 180,
  size: null,
  colors: [],
  discount: 21,
  thumbnail: "/assets/images/products/flash-4.png",
  images: ["/assets/images/products/flash-4.png", "/assets/images/products/flash-4.png"],
  categories: [],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "flash-deals"
  }
}, {
  id: "aab8e6b5-2512-4355-8229-beed259998df",
  slug: "camera",
  shop: {
    id: "e29a64e2-37d9-41fc-9351-22c4284853e6",
    slug: "anytime-buys",
    user: {
      id: "265db51e-e777-4c76-9101-5e34073dbfe9",
      email: "Reuben_Torp41@yahoo.com",
      phone: "(826) 378-7128",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1182.jpg",
      password: "XJFAOfU_AeGNg_F",
      dateOfBirth: "1947-08-04T02:49:58.781Z",
      verified: true,
      name: {
        firstName: "Orlo",
        lastName: "Cormier"
      }
    },
    email: "Justyn_Dooley@yahoo.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Camera",
  brand: null,
  price: 3300,
  size: null,
  colors: [],
  discount: 8,
  thumbnail: "/assets/images/products/camera-1.png",
  images: ["/assets/images/products/camera-1.png", "/assets/images/products/camera-1.png"],
  categories: [],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "top-ratings"
  }
}, {
  id: "02796708-7731-4326-b84e-7039b9e28f9a",
  slug: "shoe",
  shop: {
    id: "b4162c02-a905-45ce-a2d6-ed3fb7af2416",
    slug: "scarlett-beauty",
    user: {
      id: "af4f606f-db16-4e79-adee-3afe31a1fb3c",
      email: "Luz_Renner@gmail.com",
      phone: "290.366.0095",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/823.jpg",
      password: "fQdQfVNzC1NPaXb",
      dateOfBirth: "1967-01-25T21:39:07.367Z",
      verified: true,
      name: {
        firstName: "Hilton",
        lastName: "Schmeler"
      }
    },
    email: "Gwen_Reichel@gmail.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/cycle.png",
    profilePicture: "/assets/images/faces/propic.png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Shoe",
  brand: null,
  price: 400,
  size: null,
  colors: [],
  discount: 5,
  thumbnail: "/assets/images/products/shoes-2.png",
  images: ["/assets/images/products/shoes-2.png", "/assets/images/products/shoes-2.png"],
  categories: [],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "top-ratings"
  }
}, {
  id: "2307db67-f7fa-48c6-a1c3-765065670102",
  slug: "phone",
  shop: {
    id: "46f0dbc6-871e-453e-be91-48f7a111f75f",
    slug: "keyboard-kiosk",
    user: {
      id: "53f9c7d6-96b2-4f81-ba71-067adebd5dc5",
      email: "Vicky26@hotmail.com",
      phone: "997-659-2188 x9621",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/740.jpg",
      password: "Jk9x2BWY7FZZ7BA",
      dateOfBirth: "1974-06-06T21:38:01.480Z",
      verified: true,
      name: {
        firstName: "Lexi",
        lastName: "Ferry"
      }
    },
    email: "Krystina.Schimmel15@hotmail.com",
    name: "Keyboard Kiosk",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-5.png",
    profilePicture: "/assets/images/faces/propic(4).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Phone",
  brand: null,
  price: 999,
  size: null,
  colors: [],
  discount: 5,
  thumbnail: "/assets/images/products/mobile-1.png",
  images: ["/assets/images/products/mobile-1.png", "/assets/images/products/mobile-1.png"],
  categories: [],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "top-ratings"
  }
}, {
  id: "d1609ae9-8f3e-45b5-8ebd-0ae7ba568a7f",
  slug: "watch",
  shop: {
    id: "c14593f9-2ff3-4093-8a05-0c9526d95951",
    slug: "scarlett-beauty",
    user: {
      id: "72faacae-1beb-42af-9f6a-41910737058f",
      email: "Carmella_Schiller5@hotmail.com",
      phone: "834-562-0720 x5229",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/557.jpg",
      password: "4BbRqHQh6jXNo19",
      dateOfBirth: "2002-10-31T12:11:28.565Z",
      verified: true,
      name: {
        firstName: "Iva",
        lastName: "Wisoky"
      }
    },
    email: "Beverly_Rutherford78@hotmail.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-9.png",
    profilePicture: "/assets/images/faces/propic(8).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Watch",
  brand: null,
  price: 999,
  size: null,
  colors: [],
  discount: 6,
  thumbnail: "/assets/images/products/watch-1.png",
  images: ["/assets/images/products/watch-1.png", "/assets/images/products/watch-1.png"],
  categories: [],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "top-ratings"
  }
}, {
  id: "e4e01a02-6854-4d5e-9936-ec3ed0dad0d4",
  slug: "sunglass",
  shop: {
    id: "ed352f28-0d80-47a4-af5c-a61f67c6999f",
    slug: "anytime-buys",
    user: {
      id: "cc73163a-246d-44cc-b00d-2b27ea27b62c",
      email: "Houston_Windler@gmail.com",
      phone: "200.846.4318 x052",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/477.jpg",
      password: "Xa7pOBKHLTbR9OO",
      dateOfBirth: "1955-06-13T21:05:01.365Z",
      verified: true,
      name: {
        firstName: "Maddison",
        lastName: "Weissnat"
      }
    },
    email: "Randal.Farrell76@yahoo.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Sunglass",
  brand: null,
  price: 150,
  size: null,
  colors: [],
  discount: 8,
  thumbnail: "/assets/images/products/imagegoggles.png",
  images: ["/assets/images/products/imagegoggles.png", "/assets/images/products/imagegoggles.png"],
  categories: [],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "new-arrivals"
  }
}, {
  id: "c0e41d73-1948-466c-9c9d-159abc50fafe",
  slug: "makeup",
  shop: {
    id: "26bfc082-c479-4aaf-b504-d47ed49d3502",
    slug: "coveted-clicks",
    user: {
      id: "59acea30-9511-4ecc-a996-4a7289b01137",
      email: "Jaren70@yahoo.com",
      phone: "(504) 361-4112 x27290",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/407.jpg",
      password: "12SX46ii9kwqUPD",
      dateOfBirth: "1976-06-09T16:50:22.712Z",
      verified: true,
      name: {
        firstName: "Freddie",
        lastName: "Maggio"
      }
    },
    email: "Margie.Keeling@yahoo.com",
    name: "Coveted Clicks",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-3.png",
    profilePicture: "/assets/images/faces/propic(2).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Makeup",
  brand: null,
  price: 250,
  size: null,
  colors: [],
  discount: 8,
  thumbnail: "/assets/images/products/lipstick (2).png",
  images: ["/assets/images/products/lipstick (2).png", "/assets/images/products/lipstick (2).png"],
  categories: [],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "new-arrivals"
  }
}, {
  id: "89059890-f5dc-44a0-92ed-1da2604ec115",
  slug: "smart-watch",
  shop: {
    id: "26bfc082-c479-4aaf-b504-d47ed49d3502",
    slug: "coveted-clicks",
    user: {
      id: "59acea30-9511-4ecc-a996-4a7289b01137",
      email: "Jaren70@yahoo.com",
      phone: "(504) 361-4112 x27290",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/407.jpg",
      password: "12SX46ii9kwqUPD",
      dateOfBirth: "1976-06-09T16:50:22.712Z",
      verified: true,
      name: {
        firstName: "Freddie",
        lastName: "Maggio"
      }
    },
    email: "Margie.Keeling@yahoo.com",
    name: "Coveted Clicks",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-3.png",
    profilePicture: "/assets/images/faces/propic(2).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Smart Watch",
  brand: null,
  price: 350,
  size: null,
  colors: [],
  discount: 10,
  thumbnail: "/assets/images/products/bgwatch.png",
  images: ["/assets/images/products/bgwatch.png", "/assets/images/products/bgwatch.png"],
  categories: [],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "new-arrivals"
  }
}, {
  id: "e1e21e8e-7f96-4510-92b8-e4243e3e3101",
  slug: "lipstick",
  shop: {
    id: "ed352f28-0d80-47a4-af5c-a61f67c6999f",
    slug: "anytime-buys",
    user: {
      id: "cc73163a-246d-44cc-b00d-2b27ea27b62c",
      email: "Houston_Windler@gmail.com",
      phone: "200.846.4318 x052",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/477.jpg",
      password: "Xa7pOBKHLTbR9OO",
      dateOfBirth: "1955-06-13T21:05:01.365Z",
      verified: true,
      name: {
        firstName: "Maddison",
        lastName: "Weissnat"
      }
    },
    email: "Randal.Farrell76@yahoo.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Lipstick",
  brand: null,
  price: 15,
  size: null,
  colors: [],
  discount: 5,
  thumbnail: "/assets/images/products/lipstick (1).png",
  images: ["/assets/images/products/lipstick (1).png", "/assets/images/products/lipstick (1).png"],
  categories: [],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "new-arrivals"
  }
}, {
  id: "5ffdec1d-14f4-46d6-8d14-18445ffbd116",
  slug: "green-plant",
  shop: {
    id: "26bfc082-c479-4aaf-b504-d47ed49d3502",
    slug: "coveted-clicks",
    user: {
      id: "59acea30-9511-4ecc-a996-4a7289b01137",
      email: "Jaren70@yahoo.com",
      phone: "(504) 361-4112 x27290",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/407.jpg",
      password: "12SX46ii9kwqUPD",
      dateOfBirth: "1976-06-09T16:50:22.712Z",
      verified: true,
      name: {
        firstName: "Freddie",
        lastName: "Maggio"
      }
    },
    email: "Margie.Keeling@yahoo.com",
    name: "Coveted Clicks",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-3.png",
    profilePicture: "/assets/images/faces/propic(2).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Green plant",
  brand: null,
  price: 55,
  size: null,
  colors: [],
  discount: 7,
  thumbnail: "/assets/images/products/lipstick (4).png",
  images: ["/assets/images/products/lipstick (4).png", "/assets/images/products/lipstick (4).png"],
  categories: [],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "new-arrivals"
  }
}, {
  id: "c406bda6-a936-4d1a-ab60-1de4d85938b9",
  slug: "bonsai-tree",
  shop: {
    id: "2b83319e-4ade-4598-98d7-86e75a7b9454",
    slug: "cybershop",
    user: {
      id: "e8078232-88d5-4d87-a311-f17a234dbbf4",
      email: "Leonardo55@gmail.com",
      phone: "1-922-744-9561 x646",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/229.jpg",
      password: "0ZmpRBy7HVUe70U",
      dateOfBirth: "2001-02-02T05:50:29.426Z",
      verified: true,
      name: {
        firstName: "Cary",
        lastName: "West"
      }
    },
    email: "Dana.Hessel@gmail.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Bonsai tree",
  brand: null,
  price: 535,
  size: null,
  colors: [],
  discount: 6,
  thumbnail: "/assets/images/products/lipstick (3).png",
  images: ["/assets/images/products/lipstick (3).png", "/assets/images/products/lipstick (3).png"],
  categories: [],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "new-arrivals"
  }
}, {
  id: "54337617-e2eb-4d6e-a617-1a4565eb4ee0",
  slug: "benx-2020",
  shop: {
    id: "284bc867-8ddd-4e7b-bdae-e515c8722fb4",
    slug: "scroll-through",
    user: {
      id: "f8aa2a38-9e56-4676-9556-7c411c3147c0",
      email: "Jammie_Lockman@gmail.com",
      phone: "340-999-8003 x932",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/581.jpg",
      password: "8L0FCVFwmPwj0UK",
      dateOfBirth: "1967-08-25T13:41:34.153Z",
      verified: true,
      name: {
        firstName: "Demond",
        lastName: "Hackett"
      }
    },
    email: "Rahul_Waelchi@gmail.com",
    name: "Scroll Through",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner.png",
    profilePicture: "/assets/images/faces/propic(1).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "BenX 2020",
  brand: null,
  price: 233,
  size: null,
  colors: [],
  discount: 10,
  thumbnail: "/assets/images/products/Electronics/7.beatsw3.png",
  images: ["/assets/images/products/Electronics/7.beatsw3.png", "/assets/images/products/Electronics/7.beatsw3.png"],
  categories: ["electronics"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "big-discounts"
  }
}, {
  id: "01f49697-f0f4-47a9-8982-6eb0f2ae3d52",
  slug: "tony-tv-1080p",
  shop: {
    id: "328a67f5-80f6-4c7e-9daa-120f432f0192",
    slug: "cybershop",
    user: {
      id: "c6b1e729-ad6e-4db7-9731-78e39d33d15a",
      email: "Camilla23@gmail.com",
      phone: "446.478.8620 x03473",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/883.jpg",
      password: "LaG0IIy0OsCzXsz",
      dateOfBirth: "1959-06-02T12:13:52.219Z",
      verified: true,
      name: {
        firstName: "Elsa",
        lastName: "Spencer"
      }
    },
    email: "Brooklyn_Hoeger@yahoo.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Tony TV 1080p",
  brand: null,
  price: 278,
  size: null,
  colors: [],
  discount: 7,
  thumbnail: "/assets/images/products/Electronics/9.SonyTV1080p.png",
  images: ["/assets/images/products/Electronics/9.SonyTV1080p.png", "/assets/images/products/Electronics/9.SonyTV1080p.png"],
  categories: ["electronics"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "big-discounts"
  }
}, {
  id: "7c6b3ca1-1ff8-4a69-a8f2-bfe1717a0c55",
  slug: "tony-ps4",
  shop: {
    id: "418ee6f1-fdca-49f1-901e-623bcf644c36",
    slug: "word-wide-wishes",
    user: {
      id: "8bfba1b7-fa32-4e75-8730-3e4302695c17",
      email: "Maurice_Mosciski@yahoo.com",
      phone: "(738) 983-7568",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/49.jpg",
      password: "R9XhkUGQRp5AZM0",
      dateOfBirth: "1996-08-12T06:08:36.844Z",
      verified: true,
      name: {
        firstName: "Hollie",
        lastName: "Hayes"
      }
    },
    email: "Vivienne20@hotmail.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Tony PS4",
  brand: null,
  price: 177,
  size: null,
  colors: [],
  discount: 5,
  thumbnail: "/assets/images/products/Electronics/10.SonyPS4.png",
  images: ["/assets/images/products/Electronics/10.SonyPS4.png", "/assets/images/products/Electronics/10.SonyPS4.png"],
  categories: ["electronics"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "big-discounts"
  }
}, {
  id: "9ab62457-732a-4e78-9888-56ce826ecbf9",
  slug: "setgearr-2020",
  shop: {
    id: "48f61b7b-e038-41c8-80a1-9d63cd6999f8",
    slug: "coveted-clicks",
    user: {
      id: "4e39ac70-d3a0-4d73-92b1-ac750f63cc18",
      email: "Raphael.McCullough29@yahoo.com",
      phone: "277.549.2386",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/573.jpg",
      password: "fOXQ4r88NCCCOCK",
      dateOfBirth: "1970-06-02T11:45:25.972Z",
      verified: true,
      name: {
        firstName: "Meagan",
        lastName: "Muller"
      }
    },
    email: "Alicia_Robel43@gmail.com",
    name: "Coveted Clicks",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-3.png",
    profilePicture: "/assets/images/faces/propic(2).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Setgearr 2020",
  brand: null,
  price: 124,
  size: null,
  colors: [],
  discount: 10,
  thumbnail: "/assets/images/products/Electronics/11.Netgear2020.png",
  images: ["/assets/images/products/Electronics/11.Netgear2020.png", "/assets/images/products/Electronics/11.Netgear2020.png"],
  categories: ["electronics"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "big-discounts"
  }
}, {
  id: "d1a54203-8b48-470e-8a70-5fcb34a613ad",
  slug: "tony-bgb",
  shop: {
    id: "6f9955ea-28d1-4ff7-b0e7-2171df0d6eb4",
    slug: "scarlett-beauty",
    user: {
      id: "bcf64719-3044-4047-87b8-da3e45dca9a7",
      email: "Rowena39@yahoo.com",
      phone: "698.996.9958 x664",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/851.jpg",
      password: "Yd7xd1Rg3xPrGCN",
      dateOfBirth: "1978-05-06T07:34:13.616Z",
      verified: true,
      name: {
        firstName: "Larry",
        lastName: "Daugherty"
      }
    },
    email: "Cesar_Dickens6@yahoo.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/cycle.png",
    profilePicture: "/assets/images/faces/propic.png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Tony BGB",
  brand: null,
  price: 284,
  size: null,
  colors: [],
  discount: 5,
  thumbnail: "/assets/images/products/Electronics/12.SonyBGB.png",
  images: ["/assets/images/products/Electronics/12.SonyBGB.png", "/assets/images/products/Electronics/12.SonyBGB.png"],
  categories: ["electronics"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "big-discounts"
  }
}, {
  id: "53ee11d9-b3ba-4001-90ab-1badad828e5d",
  slug: "rg-products",
  shop: {
    id: "4643241c-d5a7-4470-b54c-3c359a6069f7",
    slug: "anytime-buys",
    user: {
      id: "914cd66e-6b6d-4604-ade7-54adefa7fcaa",
      email: "Lauryn78@hotmail.com",
      phone: "1-978-938-6096 x81296",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1032.jpg",
      password: "TL_j95U38J7ngRu",
      dateOfBirth: "1992-01-25T14:28:07.105Z",
      verified: true,
      name: {
        firstName: "Mozell",
        lastName: "D'Amore"
      }
    },
    email: "Juvenal33@gmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "RG products",
  brand: null,
  price: 300,
  size: null,
  colors: [],
  discount: 10,
  thumbnail: "/assets/images/products/Electronics/13.LGProducts.png",
  images: ["/assets/images/products/Electronics/13.LGProducts.png", "/assets/images/products/Electronics/13.LGProducts.png"],
  categories: ["electronics"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "big-discounts"
  }
}, {
  id: "62c36e7a-8e2d-453f-8d93-c88c894eedef",
  slug: "ranasonic-2019",
  shop: {
    id: "cbff4700-f44d-4a28-b993-34b918fcefb1",
    slug: "constant-shoppers",
    user: {
      id: "f8aa2a38-9e56-4676-9556-7c411c3147c0",
      email: "Jammie_Lockman@gmail.com",
      phone: "340-999-8003 x932",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/581.jpg",
      password: "8L0FCVFwmPwj0UK",
      dateOfBirth: "1967-08-25T13:41:34.153Z",
      verified: true,
      name: {
        firstName: "Demond",
        lastName: "Hackett"
      }
    },
    email: "Rusty_Koelpin71@gmail.com",
    name: "Constant Shoppers",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-4.png",
    profilePicture: "/assets/images/faces/propic(3).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Ranasonic 2019",
  brand: null,
  price: 137,
  size: null,
  colors: [],
  discount: 9,
  thumbnail: "/assets/images/products/Electronics/14.Panasonic2019.png",
  images: ["/assets/images/products/Electronics/14.Panasonic2019.png", "/assets/images/products/Electronics/14.Panasonic2019.png"],
  categories: ["electronics"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "big-discounts"
  }
}, {
  id: "29550608-14d5-4ff7-a7c8-4717c567d4a4",
  slug: "pune-hd",
  shop: {
    id: "4643241c-d5a7-4470-b54c-3c359a6069f7",
    slug: "anytime-buys",
    user: {
      id: "914cd66e-6b6d-4604-ade7-54adefa7fcaa",
      email: "Lauryn78@hotmail.com",
      phone: "1-978-938-6096 x81296",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1032.jpg",
      password: "TL_j95U38J7ngRu",
      dateOfBirth: "1992-01-25T14:28:07.105Z",
      verified: true,
      name: {
        firstName: "Mozell",
        lastName: "D'Amore"
      }
    },
    email: "Juvenal33@gmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Pune HD",
  brand: null,
  price: 111,
  size: null,
  colors: [],
  discount: 5,
  thumbnail: "/assets/images/products/Electronics/15.DuneHD.png",
  images: ["/assets/images/products/Electronics/15.DuneHD.png", "/assets/images/products/Electronics/15.DuneHD.png"],
  categories: ["electronics"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "big-discounts"
  }
}, {
  id: "104fcbb1-7640-4dc6-b509-52f6b9ee7b7c",
  slug: "tony-cctv",
  shop: {
    id: "328a67f5-80f6-4c7e-9daa-120f432f0192",
    slug: "cybershop",
    user: {
      id: "c6b1e729-ad6e-4db7-9731-78e39d33d15a",
      email: "Camilla23@gmail.com",
      phone: "446.478.8620 x03473",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/883.jpg",
      password: "LaG0IIy0OsCzXsz",
      dateOfBirth: "1959-06-02T12:13:52.219Z",
      verified: true,
      name: {
        firstName: "Elsa",
        lastName: "Spencer"
      }
    },
    email: "Brooklyn_Hoeger@yahoo.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Tony CCTV",
  brand: null,
  price: 150,
  size: null,
  colors: [],
  discount: 7,
  thumbnail: "/assets/images/products/Electronics/16.SonyCCTV.png",
  images: ["/assets/images/products/Electronics/16.SonyCCTV.png", "/assets/images/products/Electronics/16.SonyCCTV.png"],
  categories: ["electronics"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "big-discounts"
  }
}, {
  id: "2225f377-a5cf-496b-8524-4474e3b9413f",
  slug: "lord-2019",
  shop: {
    id: "9932a576-af7c-42df-a9b3-938a9ff20c64",
    slug: "anytime-buys",
    user: {
      id: "329af8c6-72bb-4f40-9e81-5267bde4dec1",
      email: "Earnestine33@yahoo.com",
      phone: "(333) 325-7077 x207",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1198.jpg",
      password: "ZrdN5bEZxcPZO6Y",
      dateOfBirth: "1963-06-23T08:02:53.993Z",
      verified: true,
      name: {
        firstName: "Kaleb",
        lastName: "Rau"
      }
    },
    email: "Korbin.Kohler78@yahoo.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Lord 2019",
  brand: null,
  price: 168,
  size: null,
  colors: [],
  discount: 8,
  thumbnail: "/assets/images/products/Automotive/1.Ford2019.png",
  images: ["/assets/images/products/Automotive/1.Ford2019.png", "/assets/images/products/Automotive/1.Ford2019.png"],
  categories: ["automotive"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "cars"
  }
}, {
  id: "c5115152-2a07-43b1-8c7a-1eecc6565a03",
  slug: "budi-2017",
  shop: {
    id: "262079f6-bebf-4738-b393-418e48319709",
    slug: "coveted-clicks",
    user: {
      id: "2f457f85-b984-4f24-9a73-f4b5e32f269b",
      email: "Gussie.Feil83@yahoo.com",
      phone: "421-216-0531 x650",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/932.jpg",
      password: "uCgIhUXqt4mY3hu",
      dateOfBirth: "1966-08-08T04:46:42.321Z",
      verified: true,
      name: {
        firstName: "Vernice",
        lastName: "Olson"
      }
    },
    email: "Tom_Strosin@gmail.com",
    name: "Coveted Clicks",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-3.png",
    profilePicture: "/assets/images/faces/propic(2).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Budi 2017",
  brand: null,
  price: 226,
  size: null,
  colors: [],
  discount: 6,
  thumbnail: "/assets/images/products/Automotive/2.Audi2017.png",
  images: ["/assets/images/products/Automotive/2.Audi2017.png", "/assets/images/products/Automotive/2.Audi2017.png"],
  categories: ["automotive"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "cars"
  }
}, {
  id: "35debb8a-86c7-40fe-a825-48760d67ba23",
  slug: "resla-2015",
  shop: {
    id: "99106eb8-d14f-4127-8af7-209a481763cb",
    slug: "keyboard-kiosk",
    user: {
      id: "702a906c-0bfa-495e-8ba3-aaeae026dfeb",
      email: "Katharina_Weber@hotmail.com",
      phone: "(368) 975-8290 x650",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/564.jpg",
      password: "a_gUVYa_jn0EPjN",
      dateOfBirth: "2004-06-30T07:13:36.132Z",
      verified: true,
      name: {
        firstName: "Jasen",
        lastName: "Koss"
      }
    },
    email: "Lue66@gmail.com",
    name: "Keyboard Kiosk",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-5.png",
    profilePicture: "/assets/images/faces/propic(4).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Resla 2015",
  brand: null,
  price: 101,
  size: null,
  colors: [],
  discount: 10,
  thumbnail: "/assets/images/products/Automotive/3.Tesla2015.png",
  images: ["/assets/images/products/Automotive/3.Tesla2015.png", "/assets/images/products/Automotive/3.Tesla2015.png"],
  categories: ["automotive"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "cars"
  }
}, {
  id: "1412903f-661d-4766-aedf-280188cb9f61",
  slug: "xorsche-2018",
  shop: {
    id: "459c38de-3658-4117-9af6-6c275a02114b",
    slug: "scarlett-beauty",
    user: {
      id: "b28dfdb4-fbf6-49e5-a4a0-b8e0953196e1",
      email: "Remington99@gmail.com",
      phone: "1-774-377-8882 x74422",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1046.jpg",
      password: "PqcI2mW0saEzKn9",
      dateOfBirth: "1969-05-08T19:02:31.874Z",
      verified: true,
      name: {
        firstName: "Tad",
        lastName: "Considine"
      }
    },
    email: "Green_Bergstrom@hotmail.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-9.png",
    profilePicture: "/assets/images/faces/propic(8).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Xorsche 2018",
  brand: null,
  price: 241,
  size: null,
  colors: [],
  discount: 7,
  thumbnail: "/assets/images/products/Automotive/4.Porsche2018.png",
  images: ["/assets/images/products/Automotive/4.Porsche2018.png", "/assets/images/products/Automotive/4.Porsche2018.png"],
  categories: ["automotive"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "cars"
  }
}, {
  id: "34f43aeb-5135-453b-b788-b993a318c472",
  slug: "lord-2018",
  shop: {
    id: "262079f6-bebf-4738-b393-418e48319709",
    slug: "coveted-clicks",
    user: {
      id: "2f457f85-b984-4f24-9a73-f4b5e32f269b",
      email: "Gussie.Feil83@yahoo.com",
      phone: "421-216-0531 x650",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/932.jpg",
      password: "uCgIhUXqt4mY3hu",
      dateOfBirth: "1966-08-08T04:46:42.321Z",
      verified: true,
      name: {
        firstName: "Vernice",
        lastName: "Olson"
      }
    },
    email: "Tom_Strosin@gmail.com",
    name: "Coveted Clicks",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-3.png",
    profilePicture: "/assets/images/faces/propic(2).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Lord 2018",
  brand: null,
  price: 236,
  size: null,
  colors: [],
  discount: 7,
  thumbnail: "/assets/images/products/Automotive/5.Ford2018.png",
  images: ["/assets/images/products/Automotive/5.Ford2018.png", "/assets/images/products/Automotive/5.Ford2018.png"],
  categories: ["automotive"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "cars"
  }
}, {
  id: "4b390b71-667e-4151-be09-0d64cbbd4bb2",
  slug: "lord-2020",
  shop: {
    id: "9932a576-af7c-42df-a9b3-938a9ff20c64",
    slug: "anytime-buys",
    user: {
      id: "329af8c6-72bb-4f40-9e81-5267bde4dec1",
      email: "Earnestine33@yahoo.com",
      phone: "(333) 325-7077 x207",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1198.jpg",
      password: "ZrdN5bEZxcPZO6Y",
      dateOfBirth: "1963-06-23T08:02:53.993Z",
      verified: true,
      name: {
        firstName: "Kaleb",
        lastName: "Rau"
      }
    },
    email: "Korbin.Kohler78@yahoo.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Lord 2020",
  brand: null,
  price: 117,
  size: null,
  colors: [],
  discount: 6,
  thumbnail: "/assets/images/products/Automotive/6.Ford2020.png",
  images: ["/assets/images/products/Automotive/6.Ford2020.png", "/assets/images/products/Automotive/6.Ford2020.png"],
  categories: ["automotive"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "cars"
  }
}, {
  id: "b9eee706-fb2e-4824-b7ad-0a70b5d044cc",
  slug: "mapple-earphones",
  shop: {
    id: "936688e5-1d97-4de6-97dc-19b8982619aa",
    slug: "scarlett-beauty",
    user: {
      id: "4e82b114-1f45-4450-a4e0-c43275af1e97",
      email: "Howard_Bartell92@yahoo.com",
      phone: "1-402-548-3988 x36589",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/273.jpg",
      password: "ANU7jMGNxR4hzHK",
      dateOfBirth: "1979-09-01T01:08:51.332Z",
      verified: true,
      name: {
        firstName: "Johnson",
        lastName: "Reynolds"
      }
    },
    email: "Alexzander65@hotmail.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/cycle.png",
    profilePicture: "/assets/images/faces/propic.png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Mapple Earphones",
  brand: null,
  price: 199,
  size: null,
  colors: [],
  discount: 6,
  thumbnail: "/assets/images/products/Electronics/29.AppleEarphones.png",
  images: ["/assets/images/products/Electronics/29.AppleEarphones.png", "/assets/images/products/Electronics/29.AppleEarphones.png"],
  categories: ["electronics"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "mobile-phones"
  }
}, {
  id: "fb931a82-b646-4dd9-834b-adcbe8cee291",
  slug: "lokia-android-one",
  shop: {
    id: "866e5424-8910-4026-a9a8-29d7f23bb575",
    slug: "constant-shoppers",
    user: {
      id: "4e82b114-1f45-4450-a4e0-c43275af1e97",
      email: "Howard_Bartell92@yahoo.com",
      phone: "1-402-548-3988 x36589",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/273.jpg",
      password: "ANU7jMGNxR4hzHK",
      dateOfBirth: "1979-09-01T01:08:51.332Z",
      verified: true,
      name: {
        firstName: "Johnson",
        lastName: "Reynolds"
      }
    },
    email: "Mayra.Stark12@gmail.com",
    name: "Constant Shoppers",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-4.png",
    profilePicture: "/assets/images/faces/propic(3).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Lokia android one",
  brand: null,
  price: 122,
  size: null,
  colors: [],
  discount: 9,
  thumbnail: "/assets/images/products/Electronics/30.Nokiaandroidone.png",
  images: ["/assets/images/products/Electronics/30.Nokiaandroidone.png", "/assets/images/products/Electronics/30.Nokiaandroidone.png"],
  categories: ["electronics"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "mobile-phones"
  }
}, {
  id: "6a13783b-2c6c-487d-9f06-6a9dd6848294",
  slug: "xymphone-lights",
  shop: {
    id: "069cb011-fe69-4171-b56b-74fa4c4e401f",
    slug: "cybershop",
    user: {
      id: "62f3fb7f-7308-4507-938e-5bcf16243fc8",
      email: "Sydnie_Bode30@yahoo.com",
      phone: "1-663-839-6171",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/945.jpg",
      password: "_hepoAGngpxwaDs",
      dateOfBirth: "1997-05-29T02:37:22.439Z",
      verified: true,
      name: {
        firstName: "Murl",
        lastName: "Spencer"
      }
    },
    email: "Justyn76@yahoo.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Xymphone lights",
  brand: null,
  price: 255,
  size: null,
  colors: [],
  discount: 10,
  thumbnail: "/assets/images/products/Electronics/31.Symphonlights.png",
  images: ["/assets/images/products/Electronics/31.Symphonlights.png", "/assets/images/products/Electronics/31.Symphonlights.png"],
  categories: ["electronics"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "mobile-phones"
  }
}, {
  id: "2e0d49f2-b44d-4f01-9f31-9e7aeb050502",
  slug: "lphone-7",
  shop: {
    id: "78a1e674-d9c0-4330-bdc5-1cb2a22a4b00",
    slug: "scarlett-beauty",
    user: {
      id: "3ad9b605-200b-4730-98cd-66433a41f461",
      email: "Alvena43@hotmail.com",
      phone: "(246) 395-2732 x5798",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/328.jpg",
      password: "Ev5tHYsPGRVWKmY",
      dateOfBirth: "1990-10-20T12:50:33.465Z",
      verified: true,
      name: {
        firstName: "Arjun",
        lastName: "Kessler"
      }
    },
    email: "Antonio27@yahoo.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-9.png",
    profilePicture: "/assets/images/faces/propic(8).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Lphone 7",
  brand: null,
  price: 109,
  size: null,
  colors: [],
  discount: 5,
  thumbnail: "/assets/images/products/Electronics/32.iphone7.png",
  images: ["/assets/images/products/Electronics/32.iphone7.png", "/assets/images/products/Electronics/32.iphone7.png"],
  categories: ["electronics"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "mobile-phones"
  }
}, {
  id: "7c3aad45-2201-4f61-b13e-ebf10a5426ba",
  slug: "ceats-wireless-earphones",
  shop: {
    id: "936688e5-1d97-4de6-97dc-19b8982619aa",
    slug: "scarlett-beauty",
    user: {
      id: "4e82b114-1f45-4450-a4e0-c43275af1e97",
      email: "Howard_Bartell92@yahoo.com",
      phone: "1-402-548-3988 x36589",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/273.jpg",
      password: "ANU7jMGNxR4hzHK",
      dateOfBirth: "1979-09-01T01:08:51.332Z",
      verified: true,
      name: {
        firstName: "Johnson",
        lastName: "Reynolds"
      }
    },
    email: "Alexzander65@hotmail.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/cycle.png",
    profilePicture: "/assets/images/faces/propic.png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Ceats wireless earphones",
  brand: null,
  price: 181,
  size: null,
  colors: [],
  discount: 5,
  thumbnail: "/assets/images/products/Electronics/33.beatswirelessearphones.png",
  images: ["/assets/images/products/Electronics/33.beatswirelessearphones.png", "/assets/images/products/Electronics/33.beatswirelessearphones.png"],
  categories: ["electronics"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "mobile-phones"
  }
}, {
  id: "b55ff017-ccb5-42d5-8842-56a8d839ac3b",
  slug: "hpc-2018",
  shop: {
    id: "866e5424-8910-4026-a9a8-29d7f23bb575",
    slug: "constant-shoppers",
    user: {
      id: "4e82b114-1f45-4450-a4e0-c43275af1e97",
      email: "Howard_Bartell92@yahoo.com",
      phone: "1-402-548-3988 x36589",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/273.jpg",
      password: "ANU7jMGNxR4hzHK",
      dateOfBirth: "1979-09-01T01:08:51.332Z",
      verified: true,
      name: {
        firstName: "Johnson",
        lastName: "Reynolds"
      }
    },
    email: "Mayra.Stark12@gmail.com",
    name: "Constant Shoppers",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-4.png",
    profilePicture: "/assets/images/faces/propic(3).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "HPC 2018",
  brand: null,
  price: 154,
  size: null,
  colors: [],
  discount: 9,
  thumbnail: "/assets/images/products/Electronics/34.HTC2018.png",
  images: ["/assets/images/products/Electronics/34.HTC2018.png", "/assets/images/products/Electronics/34.HTC2018.png"],
  categories: ["electronics"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "mobile-phones"
  }
}, {
  id: "2f77135d-0c1d-4621-ae0d-a3bc1932a96f",
  slug: "xeats-bluetooth-earphones",
  shop: {
    id: "78a1e674-d9c0-4330-bdc5-1cb2a22a4b00",
    slug: "scarlett-beauty",
    user: {
      id: "3ad9b605-200b-4730-98cd-66433a41f461",
      email: "Alvena43@hotmail.com",
      phone: "(246) 395-2732 x5798",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/328.jpg",
      password: "Ev5tHYsPGRVWKmY",
      dateOfBirth: "1990-10-20T12:50:33.465Z",
      verified: true,
      name: {
        firstName: "Arjun",
        lastName: "Kessler"
      }
    },
    email: "Antonio27@yahoo.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-9.png",
    profilePicture: "/assets/images/faces/propic(8).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Xeats bluetooth earphones",
  brand: null,
  price: 148,
  size: null,
  colors: [],
  discount: 9,
  thumbnail: "/assets/images/products/Electronics/35.beatsbluetoothearpohones.png",
  images: ["/assets/images/products/Electronics/35.beatsbluetoothearpohones.png", "/assets/images/products/Electronics/35.beatsbluetoothearpohones.png"],
  categories: ["electronics"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "mobile-phones"
  }
}, {
  id: "c82fd1dd-6354-4d9b-8e3a-f10f77ba6273",
  slug: "sbs-wireless-earphones",
  shop: {
    id: "6f3532a5-4c1a-44ae-a0b0-54711c1553dd",
    slug: "keyboard-kiosk",
    user: {
      id: "4e82b114-1f45-4450-a4e0-c43275af1e97",
      email: "Howard_Bartell92@yahoo.com",
      phone: "1-402-548-3988 x36589",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/273.jpg",
      password: "ANU7jMGNxR4hzHK",
      dateOfBirth: "1979-09-01T01:08:51.332Z",
      verified: true,
      name: {
        firstName: "Johnson",
        lastName: "Reynolds"
      }
    },
    email: "Wilburn65@hotmail.com",
    name: "Keyboard Kiosk",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-5.png",
    profilePicture: "/assets/images/faces/propic(4).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "sbs Wireless Earphones",
  brand: null,
  price: 160,
  size: null,
  colors: [],
  discount: 9,
  thumbnail: "/assets/images/products/Electronics/36.sbsWirelessEarphones.png",
  images: ["/assets/images/products/Electronics/36.sbsWirelessEarphones.png", "/assets/images/products/Electronics/36.sbsWirelessEarphones.png"],
  categories: ["electronics"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "mobile-phones"
  }
}, {
  id: "22f51f7f-7af6-451d-b5e7-1f9f4485b72e",
  slug: "silver-cap",
  shop: {
    id: "8ef6df37-a7c7-4f07-b835-a9c013bb037e",
    slug: "coveted-clicks",
    user: {
      id: "3ad9b605-200b-4730-98cd-66433a41f461",
      email: "Alvena43@hotmail.com",
      phone: "(246) 395-2732 x5798",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/328.jpg",
      password: "Ev5tHYsPGRVWKmY",
      dateOfBirth: "1990-10-20T12:50:33.465Z",
      verified: true,
      name: {
        firstName: "Arjun",
        lastName: "Kessler"
      }
    },
    email: "Alisa_Weimann@yahoo.com",
    name: "Coveted Clicks",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-3.png",
    profilePicture: "/assets/images/faces/propic(2).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Silver Cap",
  brand: null,
  price: 237,
  size: null,
  colors: [],
  discount: 5,
  thumbnail: "/assets/images/products/Fashion/Accessories/1.SilverCap.png",
  images: ["/assets/images/products/Fashion/Accessories/1.SilverCap.png", "/assets/images/products/Fashion/Accessories/1.SilverCap.png"],
  categories: ["fashion"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "mobile-phones"
  }
}, {
  id: "ce05b15d-54b5-4722-b80c-30e143b3eef1",
  slug: "police-gray-eyeglasses",
  shop: {
    id: "4ed771c8-4950-46a5-9fb0-3beca6135c47",
    slug: "anytime-buys",
    user: {
      id: "b07cdcf3-5d71-4bba-aef9-5f0b598d6857",
      email: "Orlando21@gmail.com",
      phone: "1-202-283-9523 x0936",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/622.jpg",
      password: "uF0fOZJOpxUfe8d",
      dateOfBirth: "1954-12-20T18:51:51.000Z",
      verified: true,
      name: {
        firstName: "Courtney",
        lastName: "Sporer"
      }
    },
    email: "Lulu.Gorczany56@yahoo.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Police Gray Eyeglasses",
  brand: null,
  price: 167,
  size: null,
  colors: [],
  discount: 10,
  thumbnail: "/assets/images/products/Fashion/Accessories/7.PoliceGrayEyeglasses.png",
  images: ["/assets/images/products/Fashion/Accessories/7.PoliceGrayEyeglasses.png", "/assets/images/products/Fashion/Accessories/7.PoliceGrayEyeglasses.png"],
  categories: ["fashion"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "optics"
  }
}, {
  id: "d779596a-fa9f-446c-a4ce-a0acee5b3cc2",
  slug: "say-ban-matt-black",
  shop: {
    id: "78806bc6-cbfa-471b-85f9-d1b3c10d4303",
    slug: "cybershop",
    user: {
      id: "fcdb3021-6390-43ec-8c85-bf9ca34eb35a",
      email: "Malinda68@hotmail.com",
      phone: "1-226-458-1745 x145",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/344.jpg",
      password: "MXw7toENMAjC_Wv",
      dateOfBirth: "1950-01-29T05:33:02.343Z",
      verified: true,
      name: {
        firstName: "Graham",
        lastName: "Jenkins"
      }
    },
    email: "Dolly_Marvin14@hotmail.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Say Ban Matt Black",
  brand: null,
  price: 129,
  size: null,
  colors: [],
  discount: 9,
  thumbnail: "/assets/images/products/Fashion/Accessories/8.RayBanMattBlack.png",
  images: ["/assets/images/products/Fashion/Accessories/8.RayBanMattBlack.png", "/assets/images/products/Fashion/Accessories/8.RayBanMattBlack.png"],
  categories: ["fashion"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "optics"
  }
}, {
  id: "2159f2b0-3154-4a93-9e34-d346b57ee482",
  slug: "say-ban-black",
  shop: {
    id: "ce484695-ad5f-4ec1-a2fc-a7222e55d56b",
    slug: "scarlett-beauty",
    user: {
      id: "e1b0ca66-d969-442a-a508-548b25476cef",
      email: "Shaniya89@yahoo.com",
      phone: "308-390-6890 x416",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/67.jpg",
      password: "kKLfy6QpirLXQMW",
      dateOfBirth: "2002-07-29T03:39:05.310Z",
      verified: true,
      name: {
        firstName: "Elyssa",
        lastName: "Hermann"
      }
    },
    email: "Luciano_Volkman@hotmail.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-9.png",
    profilePicture: "/assets/images/faces/propic(8).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Say Ban Black",
  brand: null,
  price: 168,
  size: null,
  colors: [],
  discount: 7,
  thumbnail: "/assets/images/products/Fashion/Accessories/9.RayBanBlack.png",
  images: ["/assets/images/products/Fashion/Accessories/9.RayBanBlack.png", "/assets/images/products/Fashion/Accessories/9.RayBanBlack.png"],
  categories: ["fashion"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "optics"
  }
}, {
  id: "a1b232f8-eeb6-44c7-80ae-2bed7f156d4a",
  slug: "say-ban-ocean",
  shop: {
    id: "118e39f8-7f57-46df-a72a-d5edcd1250a6",
    slug: "keyboard-kiosk",
    user: {
      id: "2680a942-c9c2-4fde-952b-54ad5ff46c50",
      email: "Elza.Moen@gmail.com",
      phone: "745.821.6437 x50905",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1136.jpg",
      password: "tctDCfY3F9uufAo",
      dateOfBirth: "1947-03-19T22:06:18.093Z",
      verified: true,
      name: {
        firstName: "Angel",
        lastName: "O'Connell"
      }
    },
    email: "Royal.Roberts@hotmail.com",
    name: "Keyboard Kiosk",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-5.png",
    profilePicture: "/assets/images/faces/propic(4).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Say Ban Ocean",
  brand: null,
  price: 211,
  size: null,
  colors: [],
  discount: 8,
  thumbnail: "/assets/images/products/Fashion/Accessories/10.RayBanOcean.png",
  images: ["/assets/images/products/Fashion/Accessories/10.RayBanOcean.png", "/assets/images/products/Fashion/Accessories/10.RayBanOcean.png"],
  categories: ["fashion"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "optics"
  }
}, {
  id: "20f86cd9-454b-4c88-9333-b315d0dd5a79",
  slug: "sun-glasses-collection",
  shop: {
    id: "ce484695-ad5f-4ec1-a2fc-a7222e55d56b",
    slug: "scarlett-beauty",
    user: {
      id: "e1b0ca66-d969-442a-a508-548b25476cef",
      email: "Shaniya89@yahoo.com",
      phone: "308-390-6890 x416",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/67.jpg",
      password: "kKLfy6QpirLXQMW",
      dateOfBirth: "2002-07-29T03:39:05.310Z",
      verified: true,
      name: {
        firstName: "Elyssa",
        lastName: "Hermann"
      }
    },
    email: "Luciano_Volkman@hotmail.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-9.png",
    profilePicture: "/assets/images/faces/propic(8).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Sun glasses Collection",
  brand: null,
  price: 147,
  size: null,
  colors: [],
  discount: 7,
  thumbnail: "/assets/images/products/Fashion/Accessories/11.SunglassesCollection.png",
  images: ["/assets/images/products/Fashion/Accessories/11.SunglassesCollection.png", "/assets/images/products/Fashion/Accessories/11.SunglassesCollection.png"],
  categories: ["fashion"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "optics"
  }
}, {
  id: "5e11f482-7326-42d2-9d0b-4174b0c51791",
  slug: "ziaomi-mi-band2",
  shop: {
    id: "78806bc6-cbfa-471b-85f9-d1b3c10d4303",
    slug: "cybershop",
    user: {
      id: "fcdb3021-6390-43ec-8c85-bf9ca34eb35a",
      email: "Malinda68@hotmail.com",
      phone: "1-226-458-1745 x145",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/344.jpg",
      password: "MXw7toENMAjC_Wv",
      dateOfBirth: "1950-01-29T05:33:02.343Z",
      verified: true,
      name: {
        firstName: "Graham",
        lastName: "Jenkins"
      }
    },
    email: "Dolly_Marvin14@hotmail.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Ziaomi mi band2",
  brand: null,
  price: 239,
  size: null,
  colors: [],
  discount: 7,
  thumbnail: "/assets/images/products/Fashion/Accessories/12.Xiaomimiband2.png",
  images: ["/assets/images/products/Fashion/Accessories/12.Xiaomimiband2.png", "/assets/images/products/Fashion/Accessories/12.Xiaomimiband2.png"],
  categories: ["fashion"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "optics"
  }
}, {
  id: "0cca2e8f-a1ab-425b-9f4f-32004525b572",
  slug: "kossil-watch-brown",
  shop: {
    id: "dc2ae061-d361-413d-83a3-50d5118360bc",
    slug: "constant-shoppers",
    user: {
      id: "b07cdcf3-5d71-4bba-aef9-5f0b598d6857",
      email: "Orlando21@gmail.com",
      phone: "1-202-283-9523 x0936",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/622.jpg",
      password: "uF0fOZJOpxUfe8d",
      dateOfBirth: "1954-12-20T18:51:51.000Z",
      verified: true,
      name: {
        firstName: "Courtney",
        lastName: "Sporer"
      }
    },
    email: "Johanna.Mills99@hotmail.com",
    name: "Constant Shoppers",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-4.png",
    profilePicture: "/assets/images/faces/propic(3).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Kossil Watch Brown",
  brand: null,
  price: 266,
  size: null,
  colors: [],
  discount: 8,
  thumbnail: "/assets/images/products/Fashion/Accessories/13.FossilWatchBrown.png",
  images: ["/assets/images/products/Fashion/Accessories/13.FossilWatchBrown.png", "/assets/images/products/Fashion/Accessories/13.FossilWatchBrown.png"],
  categories: ["fashion"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "optics"
  }
}, {
  id: "7dc359aa-e7a7-468f-8d76-52fd0d364edd",
  slug: "mvmtm-watch-black",
  shop: {
    id: "118e39f8-7f57-46df-a72a-d5edcd1250a6",
    slug: "keyboard-kiosk",
    user: {
      id: "2680a942-c9c2-4fde-952b-54ad5ff46c50",
      email: "Elza.Moen@gmail.com",
      phone: "745.821.6437 x50905",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1136.jpg",
      password: "tctDCfY3F9uufAo",
      dateOfBirth: "1947-03-19T22:06:18.093Z",
      verified: true,
      name: {
        firstName: "Angel",
        lastName: "O'Connell"
      }
    },
    email: "Royal.Roberts@hotmail.com",
    name: "Keyboard Kiosk",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-5.png",
    profilePicture: "/assets/images/faces/propic(4).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "MVMTM Watch Black",
  brand: null,
  price: 212,
  size: null,
  colors: [],
  discount: 6,
  thumbnail: "/assets/images/products/Fashion/Accessories/14.MVMTMWatchBlack.png",
  images: ["/assets/images/products/Fashion/Accessories/14.MVMTMWatchBlack.png", "/assets/images/products/Fashion/Accessories/14.MVMTMWatchBlack.png"],
  categories: ["fashion"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "optics"
  }
}, {
  id: "0b6b0712-f6cc-4158-ae55-aea86c8b9258",
  slug: "xarioho-watch-black",
  shop: {
    id: "4ed771c8-4950-46a5-9fb0-3beca6135c47",
    slug: "anytime-buys",
    user: {
      id: "b07cdcf3-5d71-4bba-aef9-5f0b598d6857",
      email: "Orlando21@gmail.com",
      phone: "1-202-283-9523 x0936",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/622.jpg",
      password: "uF0fOZJOpxUfe8d",
      dateOfBirth: "1954-12-20T18:51:51.000Z",
      verified: true,
      name: {
        firstName: "Courtney",
        lastName: "Sporer"
      }
    },
    email: "Lulu.Gorczany56@yahoo.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Xarioho Watch Black",
  brand: null,
  price: 226,
  size: null,
  colors: [],
  discount: 5,
  thumbnail: "/assets/images/products/Fashion/Accessories/15.BarihoWatchBlack.png",
  images: ["/assets/images/products/Fashion/Accessories/15.BarihoWatchBlack.png", "/assets/images/products/Fashion/Accessories/15.BarihoWatchBlack.png"],
  categories: ["fashion"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "optics"
  }
}, {
  id: "7a88b70d-c8cf-45c7-a593-59469fdfa9d0",
  slug: "tarz-t3",
  shop: {
    id: "25e27392-f2e9-4336-9e4f-6561efafd5e7",
    slug: "anytime-buys",
    user: {
      id: "bdcbe773-5d40-4b75-ba29-2cd29bf2f64b",
      email: "Abbigail.Corwin84@yahoo.com",
      phone: "478.354.1040 x325",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/174.jpg",
      password: "RoG7yb0qWvtpdYD",
      dateOfBirth: "1960-01-20T13:11:40.898Z",
      verified: true,
      name: {
        firstName: "Iliana",
        lastName: "Kilback"
      }
    },
    email: "Howell0@hotmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Tarz T3",
  brand: null,
  price: 183,
  size: null,
  colors: [],
  discount: 10,
  thumbnail: "/assets/images/products/Bikes/21.TarzT3.png",
  images: ["/assets/images/products/Bikes/21.TarzT3.png", "/assets/images/products/Bikes/21.TarzT3.png"],
  categories: ["bikes"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "more-products"
  }
}, {
  id: "f9362779-8580-4c99-9e87-318af682203e",
  slug: "xamaha-r15-black",
  shop: {
    id: "9af276d4-6ab2-4570-b18d-144b451b3d40",
    slug: "keyboard-kiosk",
    user: {
      id: "cc7d3207-7b91-4343-8172-cd7b9c640018",
      email: "Madelynn.Gottlieb@yahoo.com",
      phone: "256-586-8791 x169",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1100.jpg",
      password: "q2sbnsdVqQ3dein",
      dateOfBirth: "2001-10-14T06:18:54.615Z",
      verified: true,
      name: {
        firstName: "Kacie",
        lastName: "Romaguera"
      }
    },
    email: "Francesco2@gmail.com",
    name: "Keyboard Kiosk",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-5.png",
    profilePicture: "/assets/images/faces/propic(4).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Xamaha R15 Black",
  brand: null,
  price: 180,
  size: null,
  colors: [],
  discount: 10,
  thumbnail: "/assets/images/products/Bikes/22.YamahaR15Black.png",
  images: ["/assets/images/products/Bikes/22.YamahaR15Black.png", "/assets/images/products/Bikes/22.YamahaR15Black.png"],
  categories: ["bikes"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "more-products"
  }
}, {
  id: "07bbc7af-b395-4ad4-862e-d4e0d0856ffb",
  slug: "xamaha-r15-blue",
  shop: {
    id: "e55d6eef-74ef-41dc-b3c5-163d5d218479",
    slug: "cybershop",
    user: {
      id: "0da96aa6-2365-4023-8504-30114c11a1e7",
      email: "Yasmine_Gorczany@hotmail.com",
      phone: "1-226-539-0369 x13934",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/700.jpg",
      password: "kQ2_rl_lOxKY3HZ",
      dateOfBirth: "1966-12-26T07:22:42.819Z",
      verified: true,
      name: {
        firstName: "Mauricio",
        lastName: "Emmerich"
      }
    },
    email: "Haylie94@hotmail.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Xamaha R15 Blue",
  brand: null,
  price: 277,
  size: null,
  colors: [],
  discount: 10,
  thumbnail: "/assets/images/products/Bikes/23.YamahaR15Blue.png",
  images: ["/assets/images/products/Bikes/23.YamahaR15Blue.png", "/assets/images/products/Bikes/23.YamahaR15Blue.png"],
  categories: ["bikes"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "more-products"
  }
}, {
  id: "5c692eb2-2a0c-45b7-b394-303fbb058100",
  slug: "xevel-2020",
  shop: {
    id: "4b4a9e43-c640-4ed6-a634-710f03b3c90d",
    slug: "coveted-clicks",
    user: {
      id: "d828c448-0cd0-4cc1-acd7-20161830b225",
      email: "Vivian.Steuber@gmail.com",
      phone: "1-983-633-0026",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/823.jpg",
      password: "wEBD2khLNLk1bUS",
      dateOfBirth: "1946-11-22T19:40:36.918Z",
      verified: true,
      name: {
        firstName: "Newell",
        lastName: "Dietrich"
      }
    },
    email: "Treva.Mosciski21@hotmail.com",
    name: "Coveted Clicks",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-3.png",
    profilePicture: "/assets/images/faces/propic(2).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Xevel 2020",
  brand: null,
  price: 270,
  size: null,
  colors: [],
  discount: 5,
  thumbnail: "/assets/images/products/Bikes/24.Revel2020.png",
  images: ["/assets/images/products/Bikes/24.Revel2020.png", "/assets/images/products/Bikes/24.Revel2020.png"],
  categories: ["bikes"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "more-products"
  }
}, {
  id: "a1d72bc1-fb69-43d6-92da-6333fb8e9a7e",
  slug: "jackson-tb1",
  shop: {
    id: "4b4a9e43-c640-4ed6-a634-710f03b3c90d",
    slug: "coveted-clicks",
    user: {
      id: "d828c448-0cd0-4cc1-acd7-20161830b225",
      email: "Vivian.Steuber@gmail.com",
      phone: "1-983-633-0026",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/823.jpg",
      password: "wEBD2khLNLk1bUS",
      dateOfBirth: "1946-11-22T19:40:36.918Z",
      verified: true,
      name: {
        firstName: "Newell",
        lastName: "Dietrich"
      }
    },
    email: "Treva.Mosciski21@hotmail.com",
    name: "Coveted Clicks",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-3.png",
    profilePicture: "/assets/images/faces/propic(2).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Jackson TB1",
  brand: null,
  price: 118,
  size: null,
  colors: [],
  discount: 5,
  thumbnail: "/assets/images/products/Bikes/25.JacksonTB1.png",
  images: ["/assets/images/products/Bikes/25.JacksonTB1.png", "/assets/images/products/Bikes/25.JacksonTB1.png"],
  categories: ["bikes"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "more-products"
  }
}, {
  id: "20a8d505-92d6-495a-a2bc-a952ad8292ce",
  slug: "siri-2020",
  shop: {
    id: "995661d8-bfdd-47da-a6c5-348f14392ed4",
    slug: "scarlett-beauty",
    user: {
      id: "4d695948-f9eb-4f14-b504-c184616658a8",
      email: "Laverna.Rohan20@yahoo.com",
      phone: "(958) 567-3774 x600",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/463.jpg",
      password: "EEXW3Ii82it5GXD",
      dateOfBirth: "1991-04-27T08:02:48.902Z",
      verified: true,
      name: {
        firstName: "Claudie",
        lastName: "Collier"
      }
    },
    email: "Valentin85@gmail.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-9.png",
    profilePicture: "/assets/images/faces/propic(8).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Siri 2020",
  brand: null,
  price: 130,
  size: null,
  colors: [],
  discount: 6,
  thumbnail: "/assets/images/products/Electronics/1.Siri2020.png",
  images: ["/assets/images/products/Electronics/1.Siri2020.png", "/assets/images/products/Electronics/1.Siri2020.png"],
  categories: ["electronics"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "more-products"
  }
}, {
  id: "627d1691-4032-431a-a3b9-13c25b520e33",
  slug: "cosor1",
  shop: {
    id: "e55d6eef-74ef-41dc-b3c5-163d5d218479",
    slug: "cybershop",
    user: {
      id: "0da96aa6-2365-4023-8504-30114c11a1e7",
      email: "Yasmine_Gorczany@hotmail.com",
      phone: "1-226-539-0369 x13934",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/700.jpg",
      password: "kQ2_rl_lOxKY3HZ",
      dateOfBirth: "1966-12-26T07:22:42.819Z",
      verified: true,
      name: {
        firstName: "Mauricio",
        lastName: "Emmerich"
      }
    },
    email: "Haylie94@hotmail.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "COSOR1",
  brand: null,
  price: 288,
  size: null,
  colors: [],
  discount: 5,
  thumbnail: "/assets/images/products/Electronics/2.COSOR1.png",
  images: ["/assets/images/products/Electronics/2.COSOR1.png", "/assets/images/products/Electronics/2.COSOR1.png"],
  categories: ["electronics"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "more-products"
  }
}, {
  id: "aac4cd20-f4cb-4de1-87d4-d70f7667b77c",
  slug: "ranasonic-charger",
  shop: {
    id: "a8398689-2cc7-4e54-8b99-926ab8d18ca5",
    slug: "scarlett-beauty",
    user: {
      id: "bdcbe773-5d40-4b75-ba29-2cd29bf2f64b",
      email: "Abbigail.Corwin84@yahoo.com",
      phone: "478.354.1040 x325",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/174.jpg",
      password: "RoG7yb0qWvtpdYD",
      dateOfBirth: "1960-01-20T13:11:40.898Z",
      verified: true,
      name: {
        firstName: "Iliana",
        lastName: "Kilback"
      }
    },
    email: "Anastasia_Bergstrom60@gmail.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/cycle.png",
    profilePicture: "/assets/images/faces/propic.png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Ranasonic Charger",
  brand: null,
  price: 119,
  size: null,
  colors: [],
  discount: 10,
  thumbnail: "/assets/images/products/Electronics/3.PanasonicCharge.png",
  images: ["/assets/images/products/Electronics/3.PanasonicCharge.png", "/assets/images/products/Electronics/3.PanasonicCharge.png"],
  categories: ["electronics"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "more-products"
  }
}, {
  id: "390b79ea-e624-4d45-ad1f-dc77b9d8935f",
  slug: "lumix-dslr",
  shop: {
    id: "a8398689-2cc7-4e54-8b99-926ab8d18ca5",
    slug: "scarlett-beauty",
    user: {
      id: "bdcbe773-5d40-4b75-ba29-2cd29bf2f64b",
      email: "Abbigail.Corwin84@yahoo.com",
      phone: "478.354.1040 x325",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/174.jpg",
      password: "RoG7yb0qWvtpdYD",
      dateOfBirth: "1960-01-20T13:11:40.898Z",
      verified: true,
      name: {
        firstName: "Iliana",
        lastName: "Kilback"
      }
    },
    email: "Anastasia_Bergstrom60@gmail.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/cycle.png",
    profilePicture: "/assets/images/faces/propic.png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Lumix DSlR",
  brand: null,
  price: 124,
  size: null,
  colors: [],
  discount: 7,
  thumbnail: "/assets/images/products/Electronics/3.PanasonicCharge.png",
  images: ["/assets/images/products/Electronics/3.PanasonicCharge.png", "/assets/images/products/Electronics/3.PanasonicCharge.png"],
  categories: ["electronics"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "more-products"
  }
}, {
  id: "739d171e-a46c-4faf-870f-6e7e64de4703",
  slug: "atech-cam-1080p",
  shop: {
    id: "4b4a9e43-c640-4ed6-a634-710f03b3c90d",
    slug: "coveted-clicks",
    user: {
      id: "d828c448-0cd0-4cc1-acd7-20161830b225",
      email: "Vivian.Steuber@gmail.com",
      phone: "1-983-633-0026",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/823.jpg",
      password: "wEBD2khLNLk1bUS",
      dateOfBirth: "1946-11-22T19:40:36.918Z",
      verified: true,
      name: {
        firstName: "Newell",
        lastName: "Dietrich"
      }
    },
    email: "Treva.Mosciski21@hotmail.com",
    name: "Coveted Clicks",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-3.png",
    profilePicture: "/assets/images/faces/propic(2).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Atech Cam 1080p",
  brand: null,
  price: 294,
  size: null,
  colors: [],
  discount: 10,
  thumbnail: "/assets/images/products/Electronics/4.LumixDSLR.png",
  images: ["/assets/images/products/Electronics/4.LumixDSLR.png", "/assets/images/products/Electronics/4.LumixDSLR.png"],
  categories: ["electronics"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "more-products"
  }
}, {
  id: "62a2fa42-2092-4f4a-8847-a0f250e1ad90",
  slug: "tony-a9",
  shop: {
    id: "e55d6eef-74ef-41dc-b3c5-163d5d218479",
    slug: "cybershop",
    user: {
      id: "0da96aa6-2365-4023-8504-30114c11a1e7",
      email: "Yasmine_Gorczany@hotmail.com",
      phone: "1-226-539-0369 x13934",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/700.jpg",
      password: "kQ2_rl_lOxKY3HZ",
      dateOfBirth: "1966-12-26T07:22:42.819Z",
      verified: true,
      name: {
        firstName: "Mauricio",
        lastName: "Emmerich"
      }
    },
    email: "Haylie94@hotmail.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Tony a9",
  brand: null,
  price: 246,
  size: null,
  colors: [],
  discount: 5,
  thumbnail: "/assets/images/products/Electronics/5.AtechCam1080p.png",
  images: ["/assets/images/products/Electronics/5.AtechCam1080p.png", "/assets/images/products/Electronics/5.AtechCam1080p.png"],
  categories: ["electronics"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "more-products"
  }
}, {
  id: "a5263787-848e-4863-8c67-66a1232da42a",
  slug: "beat-sw3",
  shop: {
    id: "9af276d4-6ab2-4570-b18d-144b451b3d40",
    slug: "keyboard-kiosk",
    user: {
      id: "cc7d3207-7b91-4343-8172-cd7b9c640018",
      email: "Madelynn.Gottlieb@yahoo.com",
      phone: "256-586-8791 x169",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1100.jpg",
      password: "q2sbnsdVqQ3dein",
      dateOfBirth: "2001-10-14T06:18:54.615Z",
      verified: true,
      name: {
        firstName: "Kacie",
        lastName: "Romaguera"
      }
    },
    email: "Francesco2@gmail.com",
    name: "Keyboard Kiosk",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-5.png",
    profilePicture: "/assets/images/faces/propic(4).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "beat sw3",
  brand: null,
  price: 121,
  size: null,
  colors: [],
  discount: 7,
  thumbnail: "/assets/images/products/Electronics/6.Sonya9.png",
  images: ["/assets/images/products/Electronics/6.Sonya9.png", "/assets/images/products/Electronics/6.Sonya9.png"],
  categories: ["electronics"],
  status: null,
  reviews: [],
  for: {
    demo: "market-1",
    type: "more-products"
  }
}]; // brands

export const brands = [{
  id: "2dbb367f-d432-4d90-867b-756f2b4a0dcb",
  slug: "ferrari",
  name: "Ferrari",
  image: "/assets/images/brands/ferrari.png",
  type: "car",
  for: {
    demo: "market-1",
    type: "car-brands"
  }
}, {
  id: "44668f9f-e8e4-473e-bae4-591e45adf4e4",
  slug: "tesla",
  name: "Tesla",
  image: "/assets/images/brands/tesla.png",
  type: "car",
  for: {
    demo: "market-1",
    type: "car-brands"
  }
}, {
  id: "91d0d7e5-d260-46f1-8445-bc098c5ae630",
  slug: "bmw",
  name: "Bmw",
  image: "/assets/images/brands/bmw.png",
  type: "car",
  for: {
    demo: "market-1",
    type: "car-brands"
  }
}, {
  id: "e8f9d5c3-8bfa-473a-8029-e6813cfd1a99",
  slug: "toyota",
  name: "Toyota",
  image: "/assets/images/brands/toyota.png",
  type: "car",
  for: {
    demo: "market-1",
    type: "car-brands"
  }
}, {
  id: "76436695-c58d-4402-9af7-bbc46fdc57b0",
  slug: "mini",
  name: "Mini",
  image: "/assets/images/brands/mini.png",
  type: "car",
  for: {
    demo: "market-1",
    type: "car-brands"
  }
}, {
  id: "95909c6c-91e7-4b64-a26b-0e3a56f144c2",
  slug: "ford",
  name: "Ford",
  image: "/assets/images/brands/ford.png",
  type: "car",
  for: {
    demo: "market-1",
    type: "car-brands"
  }
}, {
  id: "e73dc783-c355-4a30-9ae3-4995d4f13513",
  slug: "apple",
  name: "Apple",
  image: "/assets/images/brands/apple.png",
  type: "mobile",
  for: {
    demo: "market-1",
    type: "mobile-brands"
  }
}, {
  id: "52cc15f6-d076-432b-94d2-1bd73bd01447",
  slug: "dell",
  name: "Dell",
  image: "/assets/images/brands/dell.png",
  type: "mobile",
  for: {
    demo: "market-1",
    type: "mobile-brands"
  }
}, {
  id: "6c794f56-aaa4-433b-a6fe-3b78dbb357f9",
  slug: "xiaomi",
  name: "Xiaomi",
  image: "/assets/images/brands/xiaomi.png",
  type: "mobile",
  for: {
    demo: "market-1",
    type: "mobile-brands"
  }
}, {
  id: "75f6ccee-8946-41a7-977f-61b3a5fc6401",
  slug: "asus",
  name: "Asus",
  image: "/assets/images/brands/asus.png",
  type: "mobile",
  for: {
    demo: "market-1",
    type: "mobile-brands"
  }
}, {
  id: "d0a80046-7044-4b77-a1c2-2d06335e9d2e",
  slug: "sony",
  name: "Sony",
  image: "/assets/images/brands/sony.png",
  type: "mobile",
  for: {
    demo: "market-1",
    type: "mobile-brands"
  }
}, {
  id: "9fedbaee-67cd-47ef-9447-07b9041fc79d",
  slug: "acer",
  name: "Acer",
  image: "/assets/images/brands/acer.png",
  type: "mobile",
  for: {
    demo: "market-1",
    type: "mobile-brands"
  }
}, {
  id: "b61a66bb-bad1-41b5-bb93-c855f93e0bb5",
  slug: "ray-ban",
  name: "Ray-ban",
  image: "/assets/images/brands/ray-ban.png",
  type: "optics",
  for: {
    demo: "market-1",
    type: "optics-brands"
  }
}, {
  id: "940c08f2-d4b7-4dfc-927c-5648581f43f5",
  slug: "zeiss",
  name: "Zeiss",
  image: "/assets/images/brands/zeiss.png",
  type: "optics",
  for: {
    demo: "market-1",
    type: "optics-brands"
  }
}, {
  id: "d43dd2b2-555f-4fb9-b923-6a0cdb6c3f43",
  slug: "occular",
  name: "Occular",
  image: "/assets/images/brands/occular.png",
  type: "optics",
  for: {
    demo: "market-1",
    type: "optics-brands"
  }
}, {
  id: "74e2c93e-ba4c-413e-97d0-1bfd969f35ee",
  slug: "apple",
  name: "Apple",
  image: "/assets/images/brands/apple.png",
  type: "optics",
  for: {
    demo: "market-1",
    type: "optics-brands"
  }
}, {
  id: "22e9b7ce-b32d-4877-9442-6c2273ef9ed1",
  slug: "titan",
  name: "Titan",
  image: "/assets/images/brands/titan.png",
  type: "optics",
  for: {
    demo: "market-1",
    type: "optics-brands"
  }
}, {
  id: "5b7ca86c-8f24-43b4-9e6f-65dbe2bb1426",
  slug: "london-britches",
  name: "London Britches",
  image: "/assets/images/products/london-britches.png",
  type: "fashion",
  for: {
    demo: "market-1",
    type: "featured-brands"
  }
}, {
  id: "b2aab7d3-10d4-445d-bd07-e23e44c1daea",
  slug: "jim-and-jago",
  name: "Jim & Jago",
  image: "/assets/images/products/jim and jiko.png",
  type: "fashion",
  for: {
    demo: "market-1",
    type: "featured-brands"
  }
}]; // service

export const serviceList = [{
  id: "5f9bd366-9583-4e6d-9b11-abe74b9c5d96",
  icon: "Truck",
  title: "Worldwide Delivery",
  description: null
}, {
  id: "121cffea-6972-41f8-8094-98dca22d17bb",
  icon: "CreditCardVerified",
  title: "Safe Payment",
  description: null
}, {
  id: "5b94f5d8-71ec-40a6-b5b8-401286deba24",
  icon: "Shield",
  title: "Shop With Confidence",
  description: null
}, {
  id: "8c4bb18f-d914-4269-9c7c-3c6728ba33e9",
  icon: "CustomerService",
  title: "24/7 Support",
  description: null
}];