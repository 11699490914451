export const cardList = [{
  id: 1,
  title: "Order",
  amount1: "32,350",
  amount2: 9350,
  color: "info.main",
  percentage: "25.25%"
}, {
  id: 2,
  title: "Sold Items",
  amount1: "2,360",
  amount2: 1350,
  color: "error.main",
  percentage: "2.65%",
  status: "down"
}, {
  id: 3,
  title: "Gross Sale",
  amount1: "$12,460.25",
  amount2: 11350,
  color: "success.main",
  percentage: "10.25%"
}, {
  id: 4,
  title: "Total Shipping Cost",
  amount1: "$6,240",
  amount2: 4350,
  color: "error.main",
  percentage: "13.15%",
  status: "down"
}];
export const recentPurchase = [{
  id: "#6d3wedo5",
  amount: 152.25,
  payment: "Success",
  product: "Aavic Headphone"
}, {
  id: "#6d3wedo6",
  amount: 125.25,
  payment: "Pending",
  product: "Nike Shoes"
}, {
  id: "#6d3wedo7",
  amount: 115.25,
  payment: "Success",
  product: "Premium Shirt For Men"
}, {
  id: "#6d3wedo8",
  amount: 97.25,
  payment: "Pending",
  product: "Polo T-shirt"
}, {
  id: "#6d3wedo9",
  amount: 255.25,
  payment: "Success",
  product: "Jeans Pant"
}];
export const stockOutProducts = [{
  amount: 152.25,
  stock: "00",
  product: "Samsung Glaxy-M1"
}, {
  amount: 125.25,
  stock: "00",
  product: "Nike Shoes"
}, {
  amount: 115.25,
  stock: "00",
  product: "Premium Shirt For Men"
}, {
  amount: 97.25,
  stock: "00",
  product: "Polo T-shirt"
}, {
  amount: 255.25,
  stock: "00",
  product: "Jeans Pant"
}];