const shops = [{
  id: "e3700a58-9697-4eeb-92e3-ba6fc16992ed",
  slug: "scarlett-beauty",
  user: {
    id: "2f035122-413b-47b8-b857-b7abc706c461",
    email: "Sandrine_Okuneva@yahoo.com",
    phone: "975.919.6321 x31038",
    avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/122.jpg",
    password: "oQAXKdNNwdAz3Ri",
    dateOfBirth: "1984-06-11T04:09:55.108Z",
    verified: true,
    name: {
      firstName: "Hope",
      lastName: "Ullrich"
    }
  },
  email: "Marlin71@gmail.com",
  name: "Scarlett Beauty",
  phone: "(613) 343-9004",
  address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
  verified: false,
  coverPicture: "/assets/images/banners/cycle.png",
  profilePicture: "/assets/images/faces/propic.png",
  socialLinks: {
    facebook: undefined,
    youtube: undefined,
    twitter: undefined,
    instagram: undefined
  }
}, {
  id: "7a1c8ff1-c05f-4ef7-a410-452a1e8dac6d",
  slug: "scroll-through",
  user: {
    id: "97dedcfd-6768-4535-a959-e3d24a3f5382",
    email: "Destinee.Schulist@gmail.com",
    phone: "598.857.4722 x327",
    avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/192.jpg",
    password: "DmYfId643rCiE_I",
    dateOfBirth: "1983-08-14T08:53:12.617Z",
    verified: true,
    name: {
      firstName: "Filiberto",
      lastName: "Effertz"
    }
  },
  email: "Hertha_Haley@yahoo.com",
  name: "Scroll Through",
  phone: "(613) 343-9004",
  address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
  verified: false,
  coverPicture: "/assets/images/banners/banner.png",
  profilePicture: "/assets/images/faces/propic(1).png",
  socialLinks: {
    facebook: undefined,
    youtube: undefined,
    twitter: undefined,
    instagram: undefined
  }
}, {
  id: "5801f226-ea4c-4c9c-bb7f-15465d67190d",
  slug: "coveted-clicks",
  user: {
    id: "c8f28042-ea46-4502-957d-c8f84b423009",
    email: "Clare7@yahoo.com",
    phone: "746-662-6200 x23927",
    avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/506.jpg",
    password: "ZRRtZ5y8Y_PKDwc",
    dateOfBirth: "1952-08-29T19:34:20.601Z",
    verified: true,
    name: {
      firstName: "Raven",
      lastName: "Wisoky"
    }
  },
  email: "Thalia.Reynolds@yahoo.com",
  name: "Coveted Clicks",
  phone: "(613) 343-9004",
  address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
  verified: false,
  coverPicture: "/assets/images/banners/banner-3.png",
  profilePicture: "/assets/images/faces/propic(2).png",
  socialLinks: {
    facebook: undefined,
    youtube: undefined,
    twitter: undefined,
    instagram: undefined
  }
}, {
  id: "1dcc7fb4-dd2f-4936-b4a1-984a8bb8311c",
  slug: "constant-shoppers",
  user: {
    id: "86586aa5-685a-45fa-b22f-7ebe34a08d4e",
    email: "Edmond_Abbott95@yahoo.com",
    phone: "1-362-805-7214 x3102",
    avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/937.jpg",
    password: "xtlTCrvPcvRo7kB",
    dateOfBirth: "1946-02-20T18:43:29.196Z",
    verified: true,
    name: {
      firstName: "Pedro",
      lastName: "Simonis"
    }
  },
  email: "Nakia_Zemlak@hotmail.com",
  name: "Constant Shoppers",
  phone: "(613) 343-9004",
  address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
  verified: false,
  coverPicture: "/assets/images/banners/banner-4.png",
  profilePicture: "/assets/images/faces/propic(3).png",
  socialLinks: {
    facebook: undefined,
    youtube: undefined,
    twitter: undefined,
    instagram: undefined
  }
}, {
  id: "0a045861-99f1-41d5-afe5-af95f6a9b2c0",
  slug: "keyboard-kiosk",
  user: {
    id: "c8f28042-ea46-4502-957d-c8f84b423009",
    email: "Clare7@yahoo.com",
    phone: "746-662-6200 x23927",
    avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/506.jpg",
    password: "ZRRtZ5y8Y_PKDwc",
    dateOfBirth: "1952-08-29T19:34:20.601Z",
    verified: true,
    name: {
      firstName: "Raven",
      lastName: "Wisoky"
    }
  },
  email: "Clifton.Mohr@hotmail.com",
  name: "Keyboard Kiosk",
  phone: "(613) 343-9004",
  address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
  verified: false,
  coverPicture: "/assets/images/banners/banner-5.png",
  profilePicture: "/assets/images/faces/propic(4).png",
  socialLinks: {
    facebook: undefined,
    youtube: undefined,
    twitter: undefined,
    instagram: undefined
  }
}, {
  id: "a83202ce-5001-45c1-a414-aa353861cfe1",
  slug: "anytime-buys",
  user: {
    id: "70865f2d-3e3f-4f53-b5c4-d64b303389e9",
    email: "Anastacio.Spinka31@yahoo.com",
    phone: "1-766-818-1553",
    avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/576.jpg",
    password: "pc2HZQY3j0Ef5Rs",
    dateOfBirth: "1961-08-19T07:36:57.073Z",
    verified: true,
    name: {
      firstName: "Trudie",
      lastName: "Torp"
    }
  },
  email: "Hal_Hayes67@gmail.com",
  name: "Anytime Buys",
  phone: "(613) 343-9004",
  address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
  verified: false,
  coverPicture: "/assets/images/banners/banner-6.png",
  profilePicture: "/assets/images/faces/propic(5).png",
  socialLinks: {
    facebook: undefined,
    youtube: undefined,
    twitter: undefined,
    instagram: undefined
  }
}, {
  id: "d4e8ebf5-1a67-4235-930c-8992bd6f1c3d",
  slug: "word-wide-wishes",
  user: {
    id: "67f2465a-076e-4cf9-81d8-db46d96381cf",
    email: "Earlene_Pollich61@yahoo.com",
    phone: "509.939.6905",
    avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/531.jpg",
    password: "9ce68HDdnwnYFQn",
    dateOfBirth: "1996-09-06T14:15:05.755Z",
    verified: true,
    name: {
      firstName: "Daphne",
      lastName: "Reichert"
    }
  },
  email: "Damon2@gmail.com",
  name: "Word Wide Wishes",
  phone: "(613) 343-9004",
  address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
  verified: false,
  coverPicture: "/assets/images/banners/banner-7.png",
  profilePicture: "/assets/images/faces/propic(6).png",
  socialLinks: {
    facebook: undefined,
    youtube: undefined,
    twitter: undefined,
    instagram: undefined
  }
}, {
  id: "9d98e6de-071e-489f-916e-9bad70d2edaf",
  slug: "cybershop",
  user: {
    id: "2f035122-413b-47b8-b857-b7abc706c461",
    email: "Sandrine_Okuneva@yahoo.com",
    phone: "975.919.6321 x31038",
    avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/122.jpg",
    password: "oQAXKdNNwdAz3Ri",
    dateOfBirth: "1984-06-11T04:09:55.108Z",
    verified: true,
    name: {
      firstName: "Hope",
      lastName: "Ullrich"
    }
  },
  email: "Dwight7@gmail.com",
  name: "Cybershop",
  phone: "(613) 343-9004",
  address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
  verified: false,
  coverPicture: "/assets/images/banners/banner-8.png",
  profilePicture: "/assets/images/faces/propic(7).png",
  socialLinks: {
    facebook: undefined,
    youtube: undefined,
    twitter: undefined,
    instagram: undefined
  }
}, {
  id: "b5d45a74-257c-4d3d-bb81-7e7045f3be2a",
  slug: "scarlett-beauty",
  user: {
    id: "97dedcfd-6768-4535-a959-e3d24a3f5382",
    email: "Destinee.Schulist@gmail.com",
    phone: "598.857.4722 x327",
    avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/192.jpg",
    password: "DmYfId643rCiE_I",
    dateOfBirth: "1983-08-14T08:53:12.617Z",
    verified: true,
    name: {
      firstName: "Filiberto",
      lastName: "Effertz"
    }
  },
  email: "Madyson57@yahoo.com",
  name: "Scarlett Beauty",
  phone: "(613) 343-9004",
  address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
  verified: false,
  coverPicture: "/assets/images/banners/banner-9.png",
  profilePicture: "/assets/images/faces/propic(8).png",
  socialLinks: {
    facebook: undefined,
    youtube: undefined,
    twitter: undefined,
    instagram: undefined
  }
}];
export default shops;