import Mock from "./mock";
import "./__db__/market-1";
import "./__db__/market-2";
import "./__db__/grupo-modelo";
import "./__db__/furniture";
import "./__db__/grocery-1";
import "./__db__/grocery-2";
import "./__db__/grocery-3";
import "./__db__/gift";
import "./__db__/gadget";
import "./__db__/health-beauty";
import "./__db__/related-products";
import "./__db__/fashion-1";
import "./__db__/fashion-2";
import "./__db__/fashion-3";
import "./__db__/shop";
import "./__db__/sales";
import "./__db__/users";
import "./__db__/ticket";
import "./__db__/vendor";
import "./__db__/orders";
import "./__db__/address";
import "./__db__/products";
import "./__db__/dashboard";
Mock.onAny().passThrough();