export const frequentlyBoughtData = [{
  id: "30d4aee0-d4a4-4d75-984e-be2f498a9c1a",
  slug: "premium-grocery-collection",
  shop: {
    id: "50488020-7b79-44ea-acdd-063cd2647113",
    slug: "keyboard-kiosk",
    user: {
      id: "f7e24d15-4941-4d35-96bd-9e935ef567b4",
      email: "Berneice17@hotmail.com",
      phone: "692-632-8732",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/63.jpg",
      password: "__SXJEYlCakJxKp",
      dateOfBirth: "1984-04-09T22:24:03.258Z",
      verified: true,
      name: {
        firstName: "Alta",
        lastName: "Trantow"
      }
    },
    email: "Thad20@yahoo.com",
    name: "Keyboard Kiosk",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-5.png",
    profilePicture: "/assets/images/faces/propic(4).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Premium Grocery Collection",
  brand: null,
  price: 250,
  size: null,
  colors: [],
  discount: 11,
  thumbnail: "/assets/images/products/Groceries/2.PremiumGroceryCollection.png",
  images: ["/assets/images/products/Groceries/2.PremiumGroceryCollection.png", "/assets/images/products/Groceries/2.PremiumGroceryCollection.png", "/assets/images/products/Groceries/2.PremiumGroceryCollection.png"],
  categories: ["groceries"],
  status: null,
  reviews: [],
  rating: 5,
  unit: null
}, {
  id: "9e70a7a2-d77c-4440-9777-4ff1634202e4",
  slug: "premium-grocery-pack",
  shop: {
    id: "73a99b14-8fc7-4c8a-b606-fa2709ad95ff",
    slug: "cybershop",
    user: {
      id: "1c692554-413a-4b56-b11d-0e8ff1d303ba",
      email: "Lucienne_Feeney@hotmail.com",
      phone: "292.681.4903 x09650",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/655.jpg",
      password: "3FVy_4IAzifRDd4",
      dateOfBirth: "1992-10-30T06:47:13.545Z",
      verified: true,
      name: {
        firstName: "Jaiden",
        lastName: "Ebert"
      }
    },
    email: "Blake42@yahoo.com",
    name: "Cybershop",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-8.png",
    profilePicture: "/assets/images/faces/propic(7).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Premium Grocery Pack",
  brand: null,
  price: 250,
  size: null,
  colors: [],
  discount: 14,
  thumbnail: "/assets/images/products/Groceries/3.PremiumGroceryPack.png",
  images: ["/assets/images/products/Groceries/3.PremiumGroceryPack.png", "/assets/images/products/Groceries/3.PremiumGroceryPack.png", "/assets/images/products/Groceries/3.PremiumGroceryPack.png"],
  categories: ["groceries"],
  status: null,
  reviews: [],
  rating: 5,
  unit: null
}, {
  id: "a3963585-61b2-4ca5-9d89-39b8ac816288",
  slug: "freshandreal-chole-masala",
  shop: {
    id: "8a54991b-3443-49bc-a345-8386cbb40176",
    slug: "coveted-clicks",
    user: {
      id: "f7e24d15-4941-4d35-96bd-9e935ef567b4",
      email: "Berneice17@hotmail.com",
      phone: "692-632-8732",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/63.jpg",
      password: "__SXJEYlCakJxKp",
      dateOfBirth: "1984-04-09T22:24:03.258Z",
      verified: true,
      name: {
        firstName: "Alta",
        lastName: "Trantow"
      }
    },
    email: "Myron71@hotmail.com",
    name: "Coveted Clicks",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-3.png",
    profilePicture: "/assets/images/faces/propic(2).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Fresh&Real CHole Masala",
  brand: null,
  price: 250,
  size: null,
  colors: [],
  discount: 13,
  thumbnail: "/assets/images/products/Groceries/4.FreashRealCholeMasala.png",
  images: ["/assets/images/products/Groceries/4.FreashRealCholeMasala.png", "/assets/images/products/Groceries/4.FreashRealCholeMasala.png", "/assets/images/products/Groceries/4.FreashRealCholeMasala.png"],
  categories: ["groceries"],
  status: null,
  reviews: [],
  rating: 5,
  unit: null
}, {
  id: "07ea2c9f-b331-47a3-95ff-42ee647f969e",
  slug: "gum-pack",
  shop: {
    id: "544bdce5-9fbc-47c1-911b-efa6d3a664e3",
    slug: "scarlett-beauty",
    user: {
      id: "d61eb0b7-c51e-45c8-9534-2b394a840285",
      email: "Sterling.Franey85@yahoo.com",
      phone: "(219) 679-0048",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/60.jpg",
      password: "c3k87hfZhMs0GJT",
      dateOfBirth: "2000-05-15T16:21:33.067Z",
      verified: true,
      name: {
        firstName: "Evert",
        lastName: "Deckow"
      }
    },
    email: "Dalton60@hotmail.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-9.png",
    profilePicture: "/assets/images/faces/propic(8).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Gum Pack",
  brand: null,
  price: 250,
  size: null,
  colors: [],
  discount: 13,
  thumbnail: "/assets/images/products/Groceries/5.GumPack.png",
  images: ["/assets/images/products/Groceries/5.GumPack.png", "/assets/images/products/Groceries/5.GumPack.png", "/assets/images/products/Groceries/5.GumPack.png"],
  categories: ["groceries"],
  status: null,
  reviews: [],
  rating: 5,
  unit: null
}];
// export const relatedProducts = [{
//   id: "0c428396-0a97-4fb9-a02d-1d031306d1cf",
//   slug: "colgate-advance-protection-toothpaste",
//   shop: {
//     id: "dad05d2a-5638-4d5b-a533-716aa26e6e3d",
//     slug: "word-wide-wishes",
//     user: {
//       id: "8f6ea744-77ff-4d34-bd27-ca9752badd4c",
//       email: "Lavinia29@gmail.com",
//       phone: "859.934.0456 x697",
//       avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/997.jpg",
//       password: "yibYfmN2NZZDhJS",
//       dateOfBirth: "1985-09-11T16:44:28.951Z",
//       verified: true,
//       name: {
//         firstName: "Dario",
//         lastName: "Auer"
//       }
//     },
//     email: "Roman_Harris51@hotmail.com",
//     name: "Word Wide Wishes",
//     phone: "(613) 343-9004",
//     address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
//     verified: false,
//     coverPicture: "/assets/images/banners/banner-7.png",
//     profilePicture: "/assets/images/faces/propic(6).png",
//     socialLinks: {
//       facebook: null,
//       youtube: null,
//       twitter: null,
//       instagram: null
//     }
//   },
//   title: "Colgate Advance Protection Toothpaste",
//   brand: null,
//   price: 250,
//   size: null,
//   colors: [],
//   discount: 12,
//   thumbnail: "/assets/images/products/Groceries/8.ColgateAdvanceProtectionToothpaste.png",
//   images: ["/assets/images/products/Groceries/8.ColgateAdvanceProtectionToothpaste.png", "/assets/images/products/Groceries/8.ColgateAdvanceProtectionToothpaste.png", "/assets/images/products/Groceries/8.ColgateAdvanceProtectionToothpaste.png"],
//   categories: ["groceries"],
//   status: null,
//   reviews: [],
//   rating: 5,
//   unit: null
// }, {
//   id: "56764bb9-a804-4527-8bc3-bc4368714f3f",
//   slug: "catch-sprinklers-chat-masala",
//   shop: {
//     id: "b554fc79-10ed-41b7-9cec-2ea55f4a0abf",
//     slug: "constant-shoppers",
//     user: {
//       id: "4362dccc-d220-40b4-8f86-1bd165635f62",
//       email: "Judah.Lynch@gmail.com",
//       phone: "617-495-7707 x5671",
//       avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/477.jpg",
//       password: "azMGCzMCEt1ukqX",
//       dateOfBirth: "1943-01-24T15:22:32.468Z",
//       verified: true,
//       name: {
//         firstName: "Gardner",
//         lastName: "Huels"
//       }
//     },
//     email: "Bret_Collins47@gmail.com",
//     name: "Constant Shoppers",
//     phone: "(613) 343-9004",
//     address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
//     verified: false,
//     coverPicture: "/assets/images/banners/banner-4.png",
//     profilePicture: "/assets/images/faces/propic(3).png",
//     socialLinks: {
//       facebook: null,
//       youtube: null,
//       twitter: null,
//       instagram: null
//     }
//   },
//   title: "Catch Sprinklers Chat Masala",
//   brand: null,
//   price: 250,
//   size: null,
//   colors: [],
//   discount: 19,
//   thumbnail: "/assets/images/products/Groceries/9.CatchSprinklersChatMasala.png",
//   images: ["/assets/images/products/Groceries/9.CatchSprinklersChatMasala.png", "/assets/images/products/Groceries/9.CatchSprinklersChatMasala.png", "/assets/images/products/Groceries/9.CatchSprinklersChatMasala.png"],
//   categories: ["groceries"],
//   status: null,
//   reviews: [],
//   rating: 5,
//   unit: null
// }, {
//   id: "3a337f0b-c98d-446d-b95f-b1f45602a191",
//   slug: "catch-italian-seasoning-grinder",
//   shop: {
//     id: "176d1b0a-aa1a-4fdd-9e0c-c0f6a28b0d69",
//     slug: "coveted-clicks",
//     user: {
//       id: "e131eece-4bab-45fb-b935-a811af5128a3",
//       email: "Camylle_Schultz8@gmail.com",
//       phone: "1-276-630-0555 x8429",
//       avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1145.jpg",
//       password: "QQAVxVj1feZzrAh",
//       dateOfBirth: "1986-03-10T23:36:29.714Z",
//       verified: true,
//       name: {
//         firstName: "Lillian",
//         lastName: "Fadel"
//       }
//     },
//     email: "Lauriane9@gmail.com",
//     name: "Coveted Clicks",
//     phone: "(613) 343-9004",
//     address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
//     verified: false,
//     coverPicture: "/assets/images/banners/banner-3.png",
//     profilePicture: "/assets/images/faces/propic(2).png",
//     socialLinks: {
//       facebook: null,
//       youtube: null,
//       twitter: null,
//       instagram: null
//     }
//   },
//   title: "Catch Italian Seasoning Grinder",
//   brand: null,
//   price: 250,
//   size: null,
//   colors: [],
//   discount: 10,
//   thumbnail: "/assets/images/products/Groceries/10.CatchItalianSeasoningGrinder.png",
//   images: ["/assets/images/products/Groceries/10.CatchItalianSeasoningGrinder.png", "/assets/images/products/Groceries/10.CatchItalianSeasoningGrinder.png", "/assets/images/products/Groceries/10.CatchItalianSeasoningGrinder.png"],
//   categories: ["groceries"],
//   status: null,
//   reviews: [],
//   rating: 5,
//   unit: null
// }, {
//   id: "82442bb3-6a2d-4dfc-acf8-d0ee17812d4d",
//   slug: "tadka-garam-masala",
//   shop: {
//     id: "63ea73d8-281d-403c-a086-f3403d089738",
//     slug: "cybershop",
//     user: {
//       id: "d3489908-db76-4378-ad49-0806b9f89ca6",
//       email: "Allison.Morar@hotmail.com",
//       phone: "1-614-357-8047 x5815",
//       avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/326.jpg",
//       password: "qhkkSJYeJCKi4Pu",
//       dateOfBirth: "1959-11-07T08:03:07.976Z",
//       verified: true,
//       name: {
//         firstName: "Aurelie",
//         lastName: "Armstrong"
//       }
//     },
//     email: "Van12@gmail.com",
//     name: "Cybershop",
//     phone: "(613) 343-9004",
//     address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
//     verified: false,
//     coverPicture: "/assets/images/banners/banner-8.png",
//     profilePicture: "/assets/images/faces/propic(7).png",
//     socialLinks: {
//       facebook: null,
//       youtube: null,
//       twitter: null,
//       instagram: null
//     }
//   },
//   title: "Tadka Garam Masala",
//   brand: null,
//   price: 250,
//   size: null,
//   colors: [],
//   discount: 16,
//   thumbnail: "/assets/images/products/Groceries/11.TadkaGaramMasala.png",
//   images: ["/assets/images/products/Groceries/11.TadkaGaramMasala.png", "/assets/images/products/Groceries/11.TadkaGaramMasala.png", "/assets/images/products/Groceries/11.TadkaGaramMasala.png"],
//   categories: ["groceries"],
//   status: null,
//   reviews: [],
//   rating: 5,
//   unit: null
// }];
export const relatedProducts = [{
  id: "1b017e60-3ca4-4186-858e-5e9b1b892aa6",
  slug: "say-ban-matt-black",
  shop: {
    id: "9eecf921-25ca-4d1b-a1c4-f232371a176c",
    slug: "word-wide-wishes",
    user: {
      id: "2cc76cbe-8927-48fe-bef4-81f21a4caac3",
      email: "Kristofer_Labadie@hotmail.com",
      phone: "278-749-4568",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/887.jpg",
      password: "w3_IqNOBqwEQdy2",
      dateOfBirth: "1981-06-19T12:09:17.219Z",
      verified: true,
      name: {
        firstName: "Kayli",
        lastName: "Kunde"
      }
    },
    email: "Margaretta_Gibson@hotmail.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Say Ban Matt Black",
  brand: null,
  price: 217,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "/assets/images/products/Fashion/Accessories/8.RayBanMattBlack.png",
  images: ["/assets/images/products/Fashion/Accessories/8.RayBanMattBlack.png", "/assets/images/products/Fashion/Accessories/8.RayBanMattBlack.png"],
  categories: ["fashion"],
  status: null,
  reviews: [],
  rating: 1,
  unit: null
}, {
  id: "d2cca96f-a9b2-48b7-8c9a-87a881c9aea1",
  slug: "say-ban-black",
  shop: {
    id: "6976c27f-e038-4df2-b8a4-1559a73f4b82",
    slug: "scarlett-beauty",
    user: {
      id: "422bd71d-b630-4590-92b4-8eee48da1082",
      email: "Josie61@hotmail.com",
      phone: "872-752-8024 x19584",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/928.jpg",
      password: "vtJw__7B6wKo2JF",
      dateOfBirth: "1943-03-03T06:16:37.790Z",
      verified: true,
      name: {
        firstName: "Heber",
        lastName: "White"
      }
    },
    email: "Seth22@yahoo.com",
    name: "Scarlett Beauty",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-9.png",
    profilePicture: "/assets/images/faces/propic(8).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Say Ban Black",
  brand: null,
  price: 180,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "/assets/images/products/Fashion/Accessories/9.RayBanBlack.png",
  images: ["/assets/images/products/Fashion/Accessories/9.RayBanBlack.png", "/assets/images/products/Fashion/Accessories/9.RayBanBlack.png"],
  categories: ["fashion"],
  status: null,
  reviews: [],
  rating: 3,
  unit: null
}, {
  id: "059ead4a-2ce7-4af1-a7e8-5314fd2dd42a",
  slug: "say-ban-ocean",
  shop: {
    id: "9b2e19f3-f3e0-46a3-9529-2aaa504a35b1",
    slug: "anytime-buys",
    user: {
      id: "d015dc20-2c0b-4fc8-82f9-0d41aa782894",
      email: "Paris_Heller61@yahoo.com",
      phone: "1-664-586-1906 x2392",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/139.jpg",
      password: "RD9XWNQDkmvBnh_",
      dateOfBirth: "1961-04-27T14:58:24.959Z",
      verified: true,
      name: {
        firstName: "Jacey",
        lastName: "Mitchell"
      }
    },
    email: "Timmothy79@hotmail.com",
    name: "Anytime Buys",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-6.png",
    profilePicture: "/assets/images/faces/propic(5).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Say Ban Ocean",
  brand: null,
  price: 143,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "/assets/images/products/Fashion/Accessories/10.RayBanOcean.png",
  images: ["/assets/images/products/Fashion/Accessories/10.RayBanOcean.png", "/assets/images/products/Fashion/Accessories/10.RayBanOcean.png"],
  categories: ["fashion"],
  status: null,
  reviews: [],
  rating: 0,
  unit: null
}, {
  id: "a6288d39-f2fd-48d8-a8ac-6f11acd8569a",
  slug: "sun-glasses-collection",
  shop: {
    id: "9eecf921-25ca-4d1b-a1c4-f232371a176c",
    slug: "word-wide-wishes",
    user: {
      id: "2cc76cbe-8927-48fe-bef4-81f21a4caac3",
      email: "Kristofer_Labadie@hotmail.com",
      phone: "278-749-4568",
      avatar: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/887.jpg",
      password: "w3_IqNOBqwEQdy2",
      dateOfBirth: "1981-06-19T12:09:17.219Z",
      verified: true,
      name: {
        firstName: "Kayli",
        lastName: "Kunde"
      }
    },
    email: "Margaretta_Gibson@hotmail.com",
    name: "Word Wide Wishes",
    phone: "(613) 343-9004",
    address: "845 N. Stonybrook Ave. Tonawanda, NY 14210, Denmark",
    verified: false,
    coverPicture: "/assets/images/banners/banner-7.png",
    profilePicture: "/assets/images/faces/propic(6).png",
    socialLinks: {
      facebook: null,
      youtube: null,
      twitter: null,
      instagram: null
    }
  },
  title: "Sun glasses Collection",
  brand: null,
  price: 70,
  size: null,
  colors: [],
  discount: 0,
  thumbnail: "/assets/images/products/Fashion/Accessories/11.SunglassesCollection.png",
  images: ["/assets/images/products/Fashion/Accessories/11.SunglassesCollection.png", "/assets/images/products/Fashion/Accessories/11.SunglassesCollection.png"],
  categories: ["fashion"],
  status: null,
  reviews: [],
  rating: 3,
  unit: null
}];