// FOLLOWING CODES ARE MOCK SERVER IMPLEMENTATION
// YOU NEED TO BUILD YOUR OWN SERVER
// IF YOU NEED HELP ABOUT SERVER SIDE IMPLEMENTATION
// CONTACT US AT support@ui-lib.com
import Mock from "../../mock";
import { users } from "./data";
Mock.onGet("/api/user-list").reply(() => {
  try {
    return [200, users];
  } catch (err) {
    console.error(err);
    return [500, {
      message: "Internal server error"
    }];
  }
});
Mock.onGet("/api/user-list/1").reply(() => {
  try {
    return [200, users[0]];
  } catch (err) {
    console.error(err);
    return [500, {
      message: "Internal server error"
    }];
  }
});
Mock.onGet("/api/user-list/id-list").reply(() => {
  try {
    const idList = users.map(item => ({
      params: {
        id: item.id
      }
    }));
    return [200, idList];
  } catch (err) {
    console.error(err);
    return [500, {
      message: "Internal server error"
    }];
  }
});